import { all, call, put, takeLeading } from 'redux-saga/effects'
import { uploadNotAnnotatedFile } from '../../services/s3'
import { TOGGLE_IS_WORKING } from '../library/libraryActions'
import { CLEAR_PHOTOS, PUSH_PHOTO_TO_CLOUD } from './uploadActions'

//upload selected photo and clear after success
function* uploadFilesToS3({ payload: { files } }: any) {
  try {
    for (const { file, library } of files) {
      yield put({ type: TOGGLE_IS_WORKING })
      yield call(uploadNotAnnotatedFile, file, library)
    }
    yield put({ type: CLEAR_PHOTOS })
  } catch (e) {
    console.log('error', e)
  } finally {
    yield put({ type: TOGGLE_IS_WORKING })
  }
}

export function* uploadSagas() {
  yield all([takeLeading(String(PUSH_PHOTO_TO_CLOUD), uploadFilesToS3)])
}
