import { createAction } from 'redux-actions'

const ns = 'analysis'

export const SET_HOMOGRAPHY = createAction(`${ns}/setHomography`)
export const SET_POLYGON_BBOX = createAction(`${ns}/setPolygonBbox`)
export const SET_RESULTS = createAction(`${ns}/setResults`)
export const SET_DEPTHS = createAction(`${ns}/setDepths`)
export const START_ANALYSIS = createAction(`${ns}/startAnalysis`)
export const SELECT_PANEL = createAction(`${ns}/selectPanel`)
export const SET_ACTIVETAB = createAction(`${ns}/setActiveTab`)
export const RESET_ANALYSIS = createAction(`${ns}/resetAnalysis`)
