import { Auth } from 'aws-amplify'
import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js'

export const login = (username: string, password: string) =>
  Auth.signIn({
    username,
    password,
  })

export const logout = () => Auth.signOut()

export const forgotPassword = (username: string) =>
  Auth.forgotPassword(username)

export const changePassword = (
  username: string,
  code: string,
  newPassword: string
) => Auth.forgotPasswordSubmit(username, code, newPassword)

export const getUserAttributes = (user: CognitoUser) =>
  new Promise<Array<CognitoUserAttribute> | undefined>((resolve, reject) => {
    user.getUserAttributes((err, result) => {
      if (err) {
        reject(err)
      }
      resolve(result)
    })
  })
