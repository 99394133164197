import { createAction } from 'redux-actions'

const ns = 'library'

export const SAVE_TO_LIBRARY = createAction(`${ns}/saveLibrary`)
export const SAVE_SITE_DETAIL = createAction(`${ns}/saveSiteDetail`)
export const SAVE_SITE_DETAIL_WITH_ID = createAction(
  `${ns}/saveSiteDetailWithId`
)
export const UPDATE_LIBRARY = createAction(`${ns}/updateLibrary`)
export const PUSH_LIBRARY_TO_CLOUD = createAction(`${ns}/pushToCloud`)
export const TOGGLE_IS_WORKING = createAction(`${ns}/isWorking`)
export const CLEAR_LIBRARY = createAction(`${ns}/clearLibrary`)

export const SAVE_FETCHED_AUDIT_DATA = createAction(
  `${ns}/saveFetchedAuditData`
)
