import axios, { AxiosError, AxiosResponse } from 'axios'
import { Auth } from 'aws-amplify'

const ApiClient = axios.create({ baseURL: process.env.REACT_APP_API_URL })

ApiClient.interceptors.request.use(async (request) => {
  const credentials = await Auth.currentSession()
  request.headers = {
    ...request.headers,
    Authorization: credentials.getIdToken().getJwtToken(),
  }
  return request
})

ApiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data
  },
  async (error: AxiosError) => {
    if (error.response && [401, 403].includes(error.response.status)) {
      await Auth.signOut()
      return
    }
    return Promise.reject(error)
  }
)

export { ApiClient }
