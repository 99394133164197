import { all, call, put, takeLeading } from 'redux-saga/effects'
import {
  DELETE_SELECTED_LIBRARY,
  FETCH_REVIEW_LIBRARY,
  FETCH_REVIEW_LIBRARY_ERROR,
  FETCH_REVIEW_LIBRARY_IMAGES,
  FETCH_REVIEW_LIBRARY_IMAGE_SUCCESS,
  FETCH_REVIEW_LIBRARY_SUCCESS,
  PUSH_REVIEWED_LIBRARY,
  PUSH_REVIEWED_LIBRARY_SUCCESS,
  PUSH_REVIEWED_LIBRARY_TEMP_CLOUD,
  TOGGLE_IS_FETCHING,
} from './reviewLibraryActions'
import { ReviewLibraryState } from '../types'
import {
  getData,
  uploadLibraryToS3,
  deleteLibrary as deleteLibraryS3,
  downloadImages,
} from '../../services/s3'
import { TOGGLE_IS_WORKING } from '../library/libraryActions'

export function* fetchReviewLibrary() {
  try {
    yield put({ type: TOGGLE_IS_FETCHING })
    yield put({ type: TOGGLE_IS_WORKING })
    const reviewLibraryState: ReviewLibraryState = yield call(getData)
    yield put({
      type: FETCH_REVIEW_LIBRARY_SUCCESS,
      payload: { data: reviewLibraryState },
    })
  } catch (e) {
    yield put({ type: FETCH_REVIEW_LIBRARY_ERROR, payload: { error: e } })
  } finally {
    yield put({ type: TOGGLE_IS_FETCHING })
    yield put({ type: TOGGLE_IS_WORKING })
  }
}

export function* fetchReviewLibraryImage({
  payload: { imageKeys, type },
}: any) {
  try {
    console.log('imageKeys', imageKeys)
    const images: Record<string, string> = yield call(downloadImages, imageKeys)
    yield put({
      type: FETCH_REVIEW_LIBRARY_IMAGE_SUCCESS,
      payload: {
        imageUpdate: {
          images,
          reviewType: type,
        },
      },
    })
  } catch (e) {
    yield put({ type: FETCH_REVIEW_LIBRARY_ERROR, payload: { error: e } })
  }
}

function* putReviewImage({ payload: { libraries, reviewType } }: any) {
  try {
    yield put({ type: TOGGLE_IS_FETCHING })
    yield put({ type: TOGGLE_IS_WORKING })
    for (const library of libraries) {
      const { labelS3Key, imageS3Key } = library
      delete library.labelS3Key
      delete library.imageS3Key
      const readyForReview = library.readyForReview
      delete library.readyForReview

      console.log('ready', readyForReview)
      yield call(
        uploadLibraryToS3,
        library,
        readyForReview,
        undefined,
        true,
        labelS3Key,
        imageS3Key,
        undefined,
        reviewType
      )
    }
    yield put({ type: FETCH_REVIEW_LIBRARY })
  } catch (e) {
    yield put({ type: FETCH_REVIEW_LIBRARY_ERROR, payload: { error: e } })
  } finally {
    yield put({ type: TOGGLE_IS_FETCHING })
    yield put({ type: TOGGLE_IS_WORKING })
  }
}

function* deleteLibrary({
  payload: { library, reviewType, s3ImageKey, labelS3Key, thumbnailS3Key },
}: any) {
  try {
    yield put({ type: TOGGLE_IS_FETCHING })
    yield put({ type: TOGGLE_IS_WORKING })
    yield put({
      type: String(PUSH_REVIEWED_LIBRARY_SUCCESS),
      payload: { update: { library, reviewType } },
    })
    yield call(deleteLibraryS3, library, s3ImageKey, labelS3Key, thumbnailS3Key)
  } catch (e) {
    yield put({ type: FETCH_REVIEW_LIBRARY_ERROR, payload: { error: e } })
  } finally {
    yield put({ type: TOGGLE_IS_FETCHING })
    yield put({ type: TOGGLE_IS_WORKING })
  }
}

function* pushTempLibraryToCloud({
  payload: {
    library,
    s3ImageKey,
    labelS3Key,
    results,
    readyForReview,
    reviewType,
  },
}: any) {
  try {
    yield put({ type: TOGGLE_IS_FETCHING })
    yield put({ type: TOGGLE_IS_WORKING })
    yield call(
      uploadLibraryToS3,
      library,
      readyForReview,
      results,
      false,
      labelS3Key,
      s3ImageKey,
      true,
      reviewType
    )
  } catch (e) {
    yield put({ type: FETCH_REVIEW_LIBRARY_ERROR, payload: { error: e } })
  } finally {
    yield put({ type: TOGGLE_IS_FETCHING })
    yield put({ type: TOGGLE_IS_WORKING })
  }
}

export function* reviewLibrarySagas() {
  yield all([
    takeLeading(String(FETCH_REVIEW_LIBRARY), fetchReviewLibrary),
    takeLeading(String(FETCH_REVIEW_LIBRARY_IMAGES), fetchReviewLibraryImage),
    takeLeading(String(PUSH_REVIEWED_LIBRARY), putReviewImage),
    takeLeading(String(DELETE_SELECTED_LIBRARY), deleteLibrary),
    takeLeading(
      String(PUSH_REVIEWED_LIBRARY_TEMP_CLOUD),
      pushTempLibraryToCloud
    ),
  ])
}
