import { FunctionComponent, useEffect, useState } from 'react'
import { TabProps } from '../polygon/WearAppTabs'
import { useSelector } from 'react-redux'
import { WearAppState } from '../../../store/types'
import { Close } from '@material-ui/icons'
import { AnalysisDrawer } from './AnalysisDrawer'
import { DrawerItem } from './DrawerItem'
import { ReplacePanelResults } from '../polygon/ReplacePanelResults'

export const ResultsDrawer: FunctionComponent<TabProps> = (props) => {
  const results = useSelector((store: WearAppState) => store.analysis.results)
  // const selectedPanel = useSelector(
  //   (state: WearAppState) => state.analysis.selectedPanel
  // )
  const [showResults, setShowResults] = useState<boolean>(false)
  useEffect(() => {
    setShowResults(!!results)
  }, [results])

  return (
    <AnalysisDrawer
      {...props}
      setShowResults={setShowResults}
      showResults={showResults}
    >
      <ReplacePanelResults detail>
        <Close onClick={() => setShowResults(false)} />
      </ReplacePanelResults>
      {/* <DrawerItem isGray title='Panel No:'>
        <p>{selectedPanel}</p>
      </DrawerItem> */}
      <DrawerItem isGray title='Average Aperture Width:'>
        <p>{results?.w_mean.toFixed(2)}mm</p>
      </DrawerItem>

      <DrawerItem title='95th Percentile Aperture Width:'>
        <p>{results?.w_p95.toFixed(2)}mm</p>
      </DrawerItem>

      <DrawerItem isGray title='Max Aperture Width:'>
        <p>{results?.w_max.toFixed(2)}mm</p>
      </DrawerItem>

      <DrawerItem title='Min Aperture Width:'>
        <p>{results?.w_min.toFixed(2)}mm</p>
      </DrawerItem>
      <DrawerItem isGray title='Open Area:'>
        <p>{(results?.per_oa ?? 0).toFixed(1)}%</p>
      </DrawerItem>
    </AnalysisDrawer>
  )
}
