import { LibraryType, MiscState } from '../types'
import { handleActions, Action } from 'redux-actions'
import {
  SET_LAST_SYNCED_TIME,
  SET_PICTURE_DATA,
  SET_REVIEW_DATA,
  SET_WENT_OFFLINE,
  TOGGLE_NEW_VERSION_AVAILABLE,
  UPDATE_SELECTED_PHOTOS,
  CLEAR_SELECTION,
} from './miscActions'
import { LOG_OUT_SUCCESS } from '../auth/authActions'

const initialState: MiscState = {}

export type MiscPayload = {
  miscState: MiscState
  newVersion?: ServiceWorkerRegistration
  wentOffline?: boolean
  currentReview?: string
  selectedItem?: string
  reviewType?: LibraryType
}

export const miscReducer = handleActions<MiscState, MiscPayload>(
  {
    [String(SET_PICTURE_DATA)]: (
      miscState: MiscState,
      { payload }: Action<MiscPayload>
    ) => ({
      ...miscState,
      ...payload,
    }),
    [String(SET_REVIEW_DATA)]: (
      miscState: MiscState,
      { payload }: Action<MiscPayload>
    ) => ({
      ...miscState,
      ...payload,
    }),
    [String(SET_LAST_SYNCED_TIME)]: (
      miscState: MiscState,
      { payload }: Action<MiscPayload>
    ) => ({
      ...miscState,
      ...payload,
    }),
    [String(TOGGLE_NEW_VERSION_AVAILABLE)]: (
      state: MiscState,
      { payload }: Action<MiscPayload>
    ) => {
      return {
        ...state,
        newVersionAvailable: payload.newVersion,
      }
    },
    [String(SET_WENT_OFFLINE)]: (
      state: MiscState,
      { payload }: Action<MiscPayload>
    ) => ({
      ...state,
      wentOffline: payload.wentOffline,
    }),
    [String(LOG_OUT_SUCCESS)]: () => {
      return {}
    },
    [String(UPDATE_SELECTED_PHOTOS)]: (
      state: MiscState,
      { payload }: Action<MiscPayload>
    ) => {
      const item = payload.selectedItem ?? ''
      const alreadySelected = state.multiSelection?.items ?? []
      const itemIndex = alreadySelected.indexOf(item)
      if (itemIndex !== -1) {
        alreadySelected.splice(itemIndex, 1)
      } else {
        alreadySelected.push(item)
      }
      return {
        ...state,
        multiSelection: {
          items: [...alreadySelected],
          reviewType: payload.reviewType,
        },
      }
    },
    [String(CLEAR_SELECTION)]: (state: MiscState) => {
      return {
        ...state,
        multiSelection: {
          items: [],
          isReview: undefined,
        },
      }
    },
  },
  initialState
)
