import { Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { DropDown } from '../../DropDown'

const useStyles = makeStyles({
  textField: {
    width: '100%',
  },
})

const screeningTypesAndMaterials: Record<string, string[]> = {
  'Sizing: Dry, Wet, Scalping, etc': [],
  'Deslime, Drain & Rinse': [
    'Coal',
    'Fine Trash',
    'Course Trash',
    'Iron Ore Course',
    'Oil Sand Trash',
    'Iron Ore Fine',
  ],
  Dewatering: [
    'Coal',
    'Coal Trash',
    'Gold Trash',
    'Oil Sand Fines',
    'Oil Sand Trash',
    'Dense Ore Fines',
    'Water',
    'Stone crushed',
  ],
}

const screeningTypes = Object.keys(screeningTypesAndMaterials)

type ProcessEfficiencyProps = {
  onDataChanged: (key: string, value?: string | number) => void
}

export const ProcessEfficiency: FunctionComponent<ProcessEfficiencyProps> = ({
  onDataChanged,
}) => {
  const [openArea, setOpenArea] = useState<string>('25')
  const [screeningType, setScreeningType] = useState<string>(screeningTypes[0])
  const [material, setMaterial] = useState<string>('')

  useEffect(() => {
    onDataChanged('openArea', openArea)
  }, [openArea])

  useEffect(() => {
    onDataChanged('screeningType', screeningType)
  }, [screeningType])

  useEffect(() => {
    onDataChanged('material', material)
  }, [material])

  const styles = useStyles()
  return (
    <Grid container item direction='column' spacing={2}>
      <Grid item>
        <Typography align='center' variant='h6'>
          Process Efficiency
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          onChange={(event) => setOpenArea(event.target.value)}
          className={styles.textField}
          label='Original Panel Open Area (%)'
          value={openArea}
        />
      </Grid>
      <Grid item>
        <DropDown
          data={Object.keys(screeningTypesAndMaterials).map(
            (screeningType) => ({
              Id: screeningType,
              Name: screeningType,
            })
          )}
          dropDownKey='screeningType'
          label='Screening Type'
          value={screeningType}
          onChange={setScreeningType}
        />
      </Grid>
      <Grid item>
        <DropDown
          data={screeningTypesAndMaterials[screeningType].map(
            (screeningType) => ({
              Id: screeningType,
              Name: screeningType,
            })
          )}
          dropDownKey='screeningType'
          label='Material'
          value={material}
          onChange={setMaterial}
          enabled={screeningTypesAndMaterials[screeningType].length > 0}
        />
      </Grid>
    </Grid>
  )
}
