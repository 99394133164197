/* tslint:disable */
/* eslint-disable */
//@ts-nocheck
import {GraphQLResult} from "@aws-amplify/api-graphql";
import {API} from "aws-amplify";
import {
    listCustomers,
    listDecks,
    listFailureModes,
    listLocations,
    listMaterials,
    listPanels,
    listSites
} from "../graphql/queries";
import {DeckHeader, FailureMode, ObjectWithId, PanelType} from "../store/types";

export const fetchClients = async () => {
    const {data}: GraphQLResult = (await API.graphql({
        query: listCustomers,
    })) as GraphQLResult
    const items = (data?.listCustomers.items ?? []) as ObjectWithId[]
    return items.sort((item1, item2) => item1.Name.localeCompare(item2.Name))
}

export const fetchSiteName = async (CustomerId: string) => {
    const {data}: GraphQLResult = (await API.graphql({
        query: listSites,
        variables: {
            filter: {
                CustomerId: {
                    eq: CustomerId
                }
            }
        }
    })) as GraphQLResult
    return (data.listSites.items as ObjectWithId[]).sort((a, b) => a.Name.localeCompare(b.Name))
}

export const fetchLocations = async (siteId: string) => {
    const {data}: GraphQLResult = (await API.graphql({
        query: listLocations,
        variables: {
            filter: {
                SiteId: {
                    eq: siteId
                }
            }
        }
    })) as GraphQLResult
    return (data.listLocations.items as ObjectWithId[]).sort((a, b) => a.Name.localeCompare(b.Name))
}


export const fetchScreens = async (locationId: string) => {
    const {data}: GraphQLResult = (await API.graphql({
        query: listDecks,
        variables: {
            filter: {
                LocationId: {
                    eq: locationId
                }
            }
        }
    })) as GraphQLResult
    return (data.listDecks.items as DeckHeader[]).sort((a, b) => {
        const aName = `${a.DeckHeader.EquipmentId} ${a.DeckHeader.DeckLevel}`
        const bName = `${b.DeckHeader.EquipmentId} ${b.DeckHeader.DeckLevel}`

        return aName.localeCompare(bName)
    })
}

export const fetchFailureModes = async (): Promise<Array<FailureMode>> => {
    const {data}: GraphQLResult = (await API.graphql({
        query: listFailureModes,
    })) as GraphQLResult

    const items = data.listFailureModes.items as Array<{ Failure_Mode: string }>;

    return items.sort((a, b) => a.Failure_Mode.localeCompare(b.Failure_Mode)).map(failure => failure.Failure_Mode)
}

export const fetchMaterialTypes = async (): Promise<Array<PanelType>> => {
    let nextToken;
    let items = [];
    do {
        const {data}: GraphQLResult = (await API.graphql({
            query: listMaterials,
            variables: {
                nextToken: nextToken ? nextToken : undefined
            }
        })) as GraphQLResult

        items = items.concat(data.listMaterials.items as Array<{ Id: string; MaterialDescription: string; MaterialNumber: string }>);
        nextToken = data.listMaterials.nextToken;
    } while (!!nextToken)

    return items.sort((a, b) => a.MaterialNumber.localeCompare(b.MaterialNumber)).reduce((previous, panel) => {
        return {
            ...previous,
            [`${panel.MaterialNumber} - ${panel.MaterialDescription}`]: panel.MaterialNumber
        }
    }, {});
}


export const fetchPanel = async (panelId: string) => {
    console.log('fetching', panelId)
    const { data }: GraphQLResult = (await API.graphql({
        query: listPanels,
        variables: {
            filter: {
                Material: {
                    eq: panelId
                }
            }
        }
    })) as GraphQLResult
    return data.listPanels.items[0] as PanelType
}
