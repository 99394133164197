import { Close } from '@material-ui/icons'
import { FunctionComponent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { WearAppState } from '../../../store/types'
import { ReplacePanelResults } from '../polygon/ReplacePanelResults'
import { TabProps } from '../polygon/WearAppTabs'
import { AnalysisDrawer } from './AnalysisDrawer'
import { DrawerItem } from './DrawerItem'

export const EfficiencyDrawer: FunctionComponent<TabProps> = (props) => {
  const efficiency = useSelector(
    (store: WearAppState) => store.analysis.efficiency
  )

  console.log('efficiency', efficiency)

  const [showResults, setShowResults] = useState<boolean>(false)
  useEffect(() => {
    setShowResults(!!efficiency)
  }, [efficiency])

  return (
    <AnalysisDrawer
      {...props}
      setShowResults={setShowResults}
      showResults={showResults}
    >
      <ReplacePanelResults>
        <Close onClick={() => setShowResults(false)} />
      </ReplacePanelResults>

      <DrawerItem isGray title='Change In Screening Efficiency:'>
        <p>{efficiency?.calculatedEfficiency?.toFixed(2) ?? '-'}%</p>
      </DrawerItem>
      <DrawerItem title='Original Panel Open Area:'>
        <p>{efficiency?.originalPanelOpenArea ?? '-'}%</p>
      </DrawerItem>
      <DrawerItem isGray title='Measured Panel Open Area:'>
        <p>{efficiency?.measuredPanelOpenArea.toFixed(2) ?? '-'}%</p>
      </DrawerItem>
      <DrawerItem title='Original Average Aperture Width:'>
        <p>{efficiency?.originalApertureWidth.toFixed(2) ?? '-'} mm</p>
      </DrawerItem>
      <DrawerItem isGray title='Measured Average Aperture Width:'>
        <p>{efficiency?.measuredApertureWidth.toFixed(2) ?? '-'} mm</p>
      </DrawerItem>
    </AnalysisDrawer>
  )
}
