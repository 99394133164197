import { FunctionComponent } from 'react'
import { CircularProgress } from '@material-ui/core'

export const Redirect: FunctionComponent = () => {
  return (
    <div>
      <CircularProgress color='inherit' />
    </div>
  )
}
