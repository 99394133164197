import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  useRef,
  useEffect,
} from 'react'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Fab,
  Tab,
  Tabs,
} from '@material-ui/core'
import { TabPanel } from './TabPanel'
import { useDispatch, useSelector } from 'react-redux'
import { WearAppState } from '../../../store/types'
import appertureImage from '../../../assets/aperture.jpeg'
import { fetchPanel } from '../../../services/appSync'
import { analyze, calcPanelWear } from '../../../services/openCV'
import cv from 'opencv-ts'
import { usePolygonFabric } from '../../../hooks/usePolygonFabric'
import {
  SET_ACTIVETAB,
  SET_DEPTHS,
  SET_RESULTS,
} from '../../../store/analysis/analysisActions'
import { DeleteForever, Save } from '@material-ui/icons'
import { WarningDialog } from '../../WarningDialog'
import { useGetReviewData } from '../../../hooks/useGetReviewData'
import { replacePanelDecision } from '../../../services/replacePanelDecision'
import { calculateEfficiency } from '../../../services/efficiency'

export type TabProps = {
  onSavePressed: () => void
  isReviewing: boolean
  openDeleteWarning: () => void
}

let timeout: NodeJS.Timeout

export const WearAppTabs: FunctionComponent<TabProps> = ({
  isReviewing,
  onSavePressed,
  openDeleteWarning,
}) => {
  const activeTab = useSelector(
    (state: WearAppState) => state.analysis.activeTab
  )
  const { image } = useGetReviewData()

  const results = useSelector((store: WearAppState) => store.analysis.results)
  const result = useSelector((store: WearAppState) => store.analysis.result)
  const result2 = useSelector((store: WearAppState) => store.analysis.result2)

  const [templateImage, setTemplateImage] = useState<string>('')
  const dispatch = useDispatch()
  const templateRef = useRef<HTMLImageElement | null>(null)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)
  const selectedPanel = useSelector(
    (state: WearAppState) => state.analysis.selectedPanel
  )
  const bbox = useSelector((state: WearAppState) => state.analysis.bbox)
  const wearInfo = useSelector((state: WearAppState) => state.analysis.info)

  console.log('wearInfo', wearInfo)

  const { startAnalysis } = usePolygonFabric()

  const handleChange = (
    event: ChangeEvent<Record<string, unknown>>,
    newValue?: number
  ) => {
    // console.log('change', newValue)
    // setTemplateImage('')
    // startAnalysis()
    // const canvas = document.getElementById('canvas_cv') as HTMLCanvasElement
    // const homography = canvas.toDataURL()
    // setTemplateImage(homography)

    dispatch({
      type: String(SET_ACTIVETAB),
      payload: {
        activeTab: newValue,
      },
    })
  }

  useEffect(() => {
    startAnalysis()
    const canvas = document.getElementById('canvas_cv') as HTMLCanvasElement
    const homography = canvas.toDataURL()
    setTemplateImage(homography)
  }, [])

  useEffect(() => {
    if (showLoader) {
      timeout && clearTimeout(timeout)
      timeout = setTimeout(() => {
        setShowLoader(false)
      }, 5 * 1000)
    } else if (timeout) {
      timeout && clearTimeout(timeout)
    }
    console.log('timeout', timeout)

    return () => clearTimeout(timeout)
  }, [showLoader])

  useEffect(() => {
    if (!templateRef.current) {
      return
    }
    if (result && results && result2) {
      cv.imshow('canvas_cv', result)
      cv.imshow('canvas_cv2', result2)
      return
    }
    templateRef.current.onload = async () => {
      setShowLoader(true)
      const panel = await fetchPanel(selectedPanel ?? '')
      if (panel && bbox) {
        const { results, result2, result } = await analyze(
          'template_image',
          'aperture_image',
          bbox,
          panel
        )
        setShowErrorDialog(false)

        if (result && result2) {
          cv.imshow('canvas_cv', result) // V662233.B01
          cv.imshow('canvas_cv2', result2)
        }

        if (results) {
          const {
            depth: depth_p95,
            less_topzone: less_topzone_p95,
            more_wd: more_wd_p95,
          } = calcPanelWear(results.w_p95, panel)

          const {
            depth: depth_mean,
            less_topzone: less_topzone_mean,
            more_wd: more_wd_mean,
          } = calcPanelWear(results.w_mean, panel)

          const {
            depth: depth_std,
            less_topzone: less_topzone_std,
            more_wd: more_wd_std,
          } = calcPanelWear(results.w_std, panel)

          const {
            depth: depth_max,
            less_topzone: less_topzone_max,
            more_wd: more_wd_max,
          } = calcPanelWear(results.w_max, panel)

          const {
            depth: depth_min,
            less_topzone: less_topzone_min,
            more_wd: more_wd_min,
          } = calcPanelWear(results.w_min, panel)
          let replacePanel = undefined
          console.log('95th percentile depth = ', depth_p95)
          if (
            wearInfo &&
            wearInfo.replace &&
            wearInfo.replace.nextShutDownDate &&
            wearInfo.replace.panelInstallDate &&
            wearInfo.replace.safetyDepth
          ) {
            replacePanel = replacePanelDecision({
              nextShutDownDate: wearInfo.replace.nextShutDownDate,
              panel,
              panelInstallDate: wearInfo.replace.panelInstallDate,
              saftyDepth: wearInfo.replace.safetyDepth,
              wear95Percent: depth_p95,
              inTopVertSection: less_topzone_p95,
              inBotVertSection: more_wd_p95,
            })
            console.log('replacePanel', replacePanel)
          }

          let efficiency = undefined
          if (wearInfo?.process) {
            efficiency = calculateEfficiency({
              screenType: wearInfo?.process?.screeningType,
              originalApertureWidth: Number(panel.Aperture_Size_W1),
              originOpenArea: wearInfo.process.openArea,
              apertureWidth: results.w_mean,
              openArea: results.per_oa,
              particleType: wearInfo.process.material,
            })
          }
          dispatch({
            type: String(SET_DEPTHS),
            payload: {
              depths: {
                depth_p95,
                depth_mean,
                depth_std,
                depth_min,
                depth_max,
                less_topzone_p95,
                less_topzone_mean,
                less_topzone_std,
                less_topzone_min,
                less_topzone_max,
                more_wd_p95,
                more_wd_mean,
                more_wd_std,
                more_wd_min,
                more_wd_max,
              },
            },
          })
          dispatch({
            type: String(SET_RESULTS),
            payload: {
              results,
              result,
              result2,
              replacePanel,
              efficiency,
            },
          })
        }
      }
      setShowLoader(false)
    }
  }, [templateImage])

  let tabNum = 0
  if (activeTab) tabNum = activeTab

  return (
    <Box sx={{ width: '100%', zIndex: 1000 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          padding: '.5em .5em 0 .5em',
        }}
      >
        <Fab
          color='primary'
          style={{
            position: 'fixed',
            left: 0,
            zIndex: 6000,
            top: '8%',
            marginLeft: '2em',
          }}
          size='small'
          onClick={onSavePressed}
        >
          <Save />
        </Fab>

        {isReviewing && (
          <Fab
            color='primary'
            onClick={openDeleteWarning}
            style={{
              position: 'fixed',
              right: 0,
              zIndex: 6000,
              top: '8%',
              marginRight: '2em',
            }}
            size='small'
          >
            <DeleteForever />
          </Fab>
        )}
      </div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label='Tabs'
          centered
        >
          <Tab label='WIDTH' id='tab-0' />
          <Tab label='WEAR' id='tab-1' />
          <Tab label='EFFICIENCY' id='tab-2' />
        </Tabs>
      </Box>
      <TabPanel index={tabNum} />
      {/* <a id='downloadAnchorElem' style={{ display: 'none' }}></a> */}
      <img
        id='panel_image'
        alt='not found'
        src={image}
        hidden
        style={{ width: '100%', height: '100%' }}
      />

      <img
        id='template_image'
        alt='not found'
        src={templateImage}
        ref={templateRef}
        hidden
      />
      <img id='aperture_image' alt='not found' src={appertureImage} hidden />
      <Dialog open={showLoader}>
        <DialogContent>
          <CircularProgress color='inherit' size={20} />
        </DialogContent>
      </Dialog>
      <WarningDialog
        open={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        title='Error'
        description='An error occurred while analysing the selected panel'
        positiveButtonText='Ok'
      />
    </Box>
  )
}
