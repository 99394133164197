import { createAction } from 'redux-actions'

const ns = 'auth'

export const LOGIN_USER = createAction(`${ns}/login`)
export const LOGIN_USER_SUCCESS = createAction(`${ns}/loginSuccess`)
export const AUTH_FAILURE = createAction(`${ns}/loginFailure`)

export const RESET_PASSWORD = createAction(`${ns}/resetPassword`)
export const RESET_PASSWORD_SUCCESS = createAction(`${ns}/resetPasswordSuccess`)
export const CLEAR_RESET_PASSWORD = createAction(`${ns}/clearResetPassword`)
export const CLEAR_RESET_PASSWORD_SUCCESS = createAction(
  `${ns}/clearResetPasswordSuccess`
)

export const CHANGE_PASSWORD = createAction(`${ns}/changePassword`)
export const CHANGE_PASSWORD_SUCCESS = createAction(
  `${ns}/changePasswordSuccess`
)

export const LOG_OUT = createAction(`${ns}/logOut`)
export const LOG_OUT_SUCCESS = createAction(`${ns}/logOutSuccess`)

export const TOGGLE_IS_LOADING = createAction(`${ns}/toggleIsFetching`)
