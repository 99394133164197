import { useCallback, useEffect, useState } from 'react'
import { FETCH_ALL_FAILURE_MODES } from '../store/failureMode/failureModeActions'
import { FETCH_ALL_PANEL_TYPES } from '../store/panelType/panelTypeActions'
import { useShouldFetchData } from './useShouldFetchData'
import { useDispatch, useSelector } from 'react-redux'
import { WearAppState } from '../store/types'

export const useFetchMiscData = (initFetch?: boolean) => {
  const shouldFetchData_ = useShouldFetchData()
  const [shouldFetchData, setShouldFetchData] = useState<boolean>(
    initFetch ?? shouldFetchData_
  )
  const isFetchingFailureModes = useSelector(
    (store: WearAppState) => store.failureModes.isFetching
  )
  const isFetchingPanelTypes = useSelector(
    (store: WearAppState) => store.panelTypes.isFetching
  )
  const isLoggedIn = useSelector(
    (state: WearAppState) => state.auth?.isLoggedIn
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoggedIn && shouldFetchData) {
      dispatch({
        type: String(FETCH_ALL_FAILURE_MODES),
      })
    }
  }, [dispatch, shouldFetchData, isLoggedIn])

  useEffect(() => {
    if (isLoggedIn && shouldFetchData) {
      dispatch({
        type: String(FETCH_ALL_PANEL_TYPES),
      })
    }
  }, [dispatch, shouldFetchData, isLoggedIn])

  useEffect(() => {
    if (!isFetchingFailureModes && !isFetchingPanelTypes) {
      setShouldFetchData(false)
    }
  }, [isFetchingFailureModes, isFetchingPanelTypes])

  return useCallback(() => {
    setShouldFetchData(true)
  }, [])
}
