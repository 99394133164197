import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LibraryType, Review, WearAppState } from '../../store/types'
import { CircularProgress, Grid } from '@material-ui/core'
import {
  CLEAR_SELECTION,
  SET_PICTURE_DATA,
  SET_REVIEW_DATA,
  UPDATE_SELECTED_PHOTOS,
} from '../../store/misc/miscActions'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSetShowBackButton } from '../../hooks/useSetShowBackButton'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { FETCH_REVIEW_LIBRARY_IMAGES } from '../../store/reviewLibrary/reviewLibraryActions'

const useStyles = makeStyles({
  imageContainer: {
    transition: 'transform ease-out .2s',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(.95, .95)',
    },
  },
  imageContainerReviewed: {
    transform: 'scale(1, 1)',
    borderRadius: '4px',
  },
  imageContainerSelected: {
    transform: 'scale(.8,.8)',
    border: '4px solid #007073',
  },
})

export const ReviewImages: FunctionComponent = () => {
  const { state } = useLocation()
  const type = (state as { type: LibraryType })?.type ?? 'nonAnnotated'
  const reviewData = useSelector(
    (state: WearAppState) => state.reviewLibrary[type]
  )

  const [imageLoaded, setImageLoaded] = useState<number[]>([])

  const dispatch = useDispatch()

  const selectedFiles = useSelector(
    (state: WearAppState) => state.misc?.multiSelection?.items ?? []
  )

  const navigate = useNavigate()
  useSetShowBackButton(true)

  useEffect(() => {
    return () => {
      dispatch({
        type: String(CLEAR_SELECTION),
      })
    }
  }, [])

  useEffect(() => {
    dispatch({
      type: String(SET_PICTURE_DATA),
      payload: {
        currentPhoto: {
          picture: undefined,
        },
      },
    })
    dispatch({
      type: String(SET_REVIEW_DATA),
      payload: {
        currentReview: undefined,
      },
    })
  }, [dispatch])

  const onImageClicked = (review: Review) => {
    if (review.isFetching) {
      return
    }
    dispatch({
      type: String(SET_REVIEW_DATA),
      payload: {
        currentReview: review.imageS3Key,
      },
    })
    dispatch({
      type: String(FETCH_REVIEW_LIBRARY_IMAGES),
      payload: {
        imageKeys: { [review.imageS3Key]: review.imageUrl },
        type,
      },
    })
    navigate('/dashboard/preview', {
      state: {
        isReviewing: true,
        type,
      },
    })
  }

  const onImageSelected = (review: Review) => {
    dispatch({
      type: String(UPDATE_SELECTED_PHOTOS),
      payload: {
        selectedItem: review.imageS3Key,
        reviewType: type,
      },
    })
  }

  const styles = useStyles()

  return (
    <Grid container spacing={2} style={{ padding: '.6em' }}>
      {reviewData.map((review, index) => {
        return (
          <Grid
            className={`${styles.imageContainer} ${
              review.label.hasBeenReviewed ? styles.imageContainerReviewed : ''
            } ${
              selectedFiles.includes(review.imageUrl ?? review.imageS3Key)
                ? styles.imageContainerSelected
                : ''
            }`}
            onClick={() => onImageClicked(review)}
            item
            xs={6}
            md={3}
            key={`${review.label.timeStamps.createdAtUTC}`}
          >
            {review.isFetching ? (
              <div
                style={{
                  width: '100%',
                  height: '20em',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress color='inherit' size={12} />
              </div>
            ) : (
              <>
                <div
                  style={{
                    width: '100%',
                    height: '20em',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'fill',
                      display: !imageLoaded.includes(index)
                        ? 'none'
                        : 'inherit',
                    }}
                    src={
                      review.thumbnailUrl?.trim().length > 0
                        ? review.thumbnailUrl
                        : review.image
                    }
                    alt='review'
                    onLoad={() =>
                      setImageLoaded((prevState) => [...prevState, index])
                    }
                  />
                  {!imageLoaded.includes(index) && (
                    <CircularProgress color='inherit' size={12} />
                  )}
                </div>

                {review.label.hasBeenReviewed && (
                  <CheckCircleIcon
                    onClick={(e) => {
                      e.stopPropagation()
                      onImageSelected(review)
                    }}
                    style={{
                      position: 'fixed',
                      top: 0,
                      right: '5px',
                      color: '#007073',
                      marginRight: '4px',
                      fontSize: '3em',
                    }}
                  />
                )}
              </>
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}
