import { combineReducers } from 'redux'
import { Action } from 'redux-actions'
import { WearAppState } from './types'
import { panelTypeReducer } from './panelType/panelTypeReducer'
import { miscReducer } from './misc/miscReducer'
import { failureModeReducer } from './failureMode/failureModeReducer'
import { authReducer } from './auth/authReducer'
import { libraryReducer } from './library/libraryReducer'
import { uploadReducer } from './upload/uploadReducer'
import { reviewLibraryReducer } from './reviewLibrary/reviewLibraryReducer'
import { analysisReducer } from './analysis/analysisReducer'

export const appReducer = () => (
  state: WearAppState,
  action: Action<unknown>
) => {
  return combineReducers<WearAppState>({
    library: libraryReducer,
    panelTypes: panelTypeReducer,
    misc: miscReducer,
    failureModes: failureModeReducer,
    auth: authReducer,
    fileUploads: uploadReducer,
    reviewLibrary: reviewLibraryReducer,
    analysis: analysisReducer,
  })(state, action)
}
