import React, { FunctionComponent, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Logo from '../../assets/schenck_logo.png'
import { Typography } from '@material-ui/core'
import { Navigate, useLocation } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { WearAppState } from '../../store/types'
import { AUTH_FAILURE } from '../../store/auth/authActions'
import { useAuthentication } from '../../hooks/useAuthentication'

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (min-width: 600px)': {
      height: '100%',
    },
  },
  innerContainer: {
    display: 'flex',
    width: '100%',
    padding: '4em',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (min-width: 600px)': {
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '60%',
    },
  },
  logo: {
    objectFit: 'contain',
  },
  caption: {
    marginTop: '2em',
    marginBottom: '2em',
    fontSize: '1.2em',
    textAlign: 'center',
  },
})

export const Auth: FunctionComponent = () => {
  const isUserLoggedIn = useSelector(
    (store: WearAppState) => store.auth?.isLoggedIn
  )
  const styles = useStyles()
  const { pathname, state } = useLocation()
  const authFailure = useSelector((state: WearAppState) => state.auth?.error)
  const isLoggingIn = useSelector(
    (state: WearAppState) => state.auth?.isLoading
  )
  const dispatch = useDispatch()

  useAuthentication()
  useEffect(() => {
    dispatch({
      type: String(AUTH_FAILURE),
      payload: {
        error: undefined,
      },
    })
  }, [])

  const authMessage = useMemo(() => {
    if (isLoggingIn || !authFailure) {
      let authMessage = 'Please login to continue'
      if (pathname === '/auth/reset-password') {
        authMessage = 'Please enter your email to reset your password'
      } else if (pathname === '/auth/change-password') {
        // @ts-ignore
        authMessage = `Please enter the code you received on ${state?.email}`
      } else if (pathname === '/auth/redirect') {
        authMessage = 'Logging in...'
      }
      return (
        <Typography className={styles.caption} variant='caption'>
          {authMessage}
        </Typography>
      )
    }
    let message = 'An error occurred, please try again later'
    switch (authFailure.code) {
      case 'UserNotFoundException':
      case 'NotAuthorizedException':
        message = 'Username/password combination is not valid'
        break
      case 'CodeMismatchException':
        message = 'The code you entered is invalid'
        break
      case 'InvalidPasswordException':
        message = authFailure.message
        break
    }
    return (
      <Typography
        className={styles.caption}
        style={{ color: '#f44336' }}
        variant='caption'
      >
        {message}
      </Typography>
    )
    // @ts-ignore
  }, [authFailure, isLoggingIn, pathname, state?.email, styles.caption])

  return !isUserLoggedIn ? (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <img src={Logo} alt='logo' className={styles.logo} />
        {authMessage}
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to='/dashboard' replace />
  )
}
