import { all } from 'redux-saga/effects'
import { panelTypesSagas } from './panelType/panelTypeSagas'
import { failureModeSagas } from './failureMode/failureModeSagas'
import { authSagas } from './auth/authSagas'
import { librarySagas } from './library/librarySaga'
import { uploadSagas } from './upload/uploadSaga'
import { reviewLibrarySagas } from './reviewLibrary/reviewLibrarySagas'

export function* appSagas() {
  yield all([
    panelTypesSagas(),
    failureModeSagas(),
    authSagas(),
    librarySagas(),
    uploadSagas(),
    reviewLibrarySagas(),
  ])
}
