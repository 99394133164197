import { Route, Routes } from 'react-router-dom'
import { Home } from '../pages/dashboard/Home'
import { Audit } from '../pages/dashboard/Audit'
import { Camera } from '../pages/dashboard/Camera'
import { Preview } from '../pages/dashboard/Preview'
import WearScanning from '../pages/dashboard/WearScanning'
import FailureModeDetection from '../pages/dashboard/FailureModeDetection'
import React, { FunctionComponent } from 'react'
import { Auth } from '../pages/auth/Auth'
import { Login } from '../pages/auth/Login'
import { ResetPassword } from '../pages/auth/ResetPassword'
import RequireAuth from './RequireAuth'
import { ChangePassword } from '../pages/auth/ChangePassword'
import { FilesUpload } from '../pages/dashboard/FilesUpload'
import { Review } from '../pages/dashboard/Review'
import { ReviewImages } from '../pages/dashboard/ReviewImages'
import { Redirect } from '../pages/auth/Redirect'
import { PreviewContextProvider } from '../context/PreviewContext'

export const WearAppRouter: FunctionComponent = () => {
  return (
    <Routes>
      <Route path='/dashboard/*' element={<RequireAuth />}>
        <Route path='*' element={<Home />} />
        <Route index element={<Home />} />
        <Route path='audit' element={<Audit />} />
        <Route path='camera' element={<Camera />} />
        <Route
          path='preview'
          element={
            <PreviewContextProvider>
              <Preview />
            </PreviewContextProvider>
          }
        />
        <Route path='wear-scanning' element={<WearScanning />} />
        <Route path='review' element={<Review />} />
        <Route path='review/images' element={<ReviewImages />} />
        <Route
          path='failure-mode-detection'
          element={<FailureModeDetection />}
        />
        <Route path='files' element={<FilesUpload />} />
      </Route>
      <Route path='/auth/*' element={<Auth />}>
        <Route path='login' element={<Login />} />
        <Route path='redirect' element={<Redirect />} />
        <Route path='reset-password' element={<ResetPassword />} />
        <Route path='change-password' element={<ChangePassword />} />
        <Route path='*' element={<Login />} />
        <Route index element={<Login />} />
      </Route>
      <Route path='*' element={<RequireAuth />} />
    </Routes>
  )
}
