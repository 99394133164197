export const cognitoAzureConfig = {
  aws_project_region: process.env.REACT_APP_aws_project_region,
  aws_cognito_region: process.env.REACT_APP_aws_project_region,
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_COGNITO_APP_CLIENT_ID_AZURE,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${window.location.origin}/auth/redirect`,
    redirectSignOut: `${window.location.origin}/auth/login`,
    responseType: 'code',
    client_id: process.env.REACT_APP_COGNITO_APP_CLIENT_ID_AZURE,
  },
  aws_appsync_apiKey: process.env.REACT_APP_SITE_DETAIL_API_KEY,
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_SITE_DETAIL_ENDPOINT,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  aws_appsync_region: process.env.REACT_APP_aws_project_region,
  aws_appsync_authenticationType: 'API_KEY',
}

export const awsConfig = {
  region: process.env.REACT_APP_aws_project_region,
  secretAccessKey: process.env.REACT_APP_secretAccessKey ?? '',
  accessKeyId: process.env.REACT_APP_accessKeyId ?? '',
}
