import { File, FileUploads } from '../types'
import { handleActions, Action } from 'redux-actions'
import { CLEAR_PHOTOS, SET_PHOTOS } from './uploadActions'

const initialState: FileUploads = []

type FileUploadPayload = {
  files: FileUploads
  file: File
}

export const uploadReducer = handleActions<FileUploads, FileUploadPayload>(
  {
    [String(SET_PHOTOS)]: (
      fileUploads: FileUploads,
      { payload }: Action<FileUploadPayload>
    ) => [...payload.files],
    [String(CLEAR_PHOTOS)]: () => [],
  },
  initialState
)
