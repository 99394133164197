import { FunctionComponent } from 'react'
import { SiteDetail } from '../../../store/types'
import { Dialog, DialogContent, withStyles } from '@material-ui/core'
import { Audit } from '../../../pages/dashboard/Audit'

type SiteDetailDialogProps = {
  materialSelected: string
  siteDetail?: SiteDetail
  onSavePressed: (siteDetail: SiteDetail, panelSelected: string) => void
  onClose: () => void
  isAnalysis?: boolean
}

const StyledDialog = withStyles({
  root: {
    zIndex: 100000,
  },
})(Dialog)

export const SiteDetailDialog: FunctionComponent<SiteDetailDialogProps> = ({
  onSavePressed,
  onClose,
  siteDetail,
  materialSelected,
  isAnalysis,
}) => {
  return (
    <StyledDialog open={true}>
      <DialogContent>
        <Audit
          siteDetails={siteDetail}
          materialSelected={materialSelected}
          onSavePressed={onSavePressed}
          onCancel={onClose}
          isAnalysis={isAnalysis}
        />
      </DialogContent>
    </StyledDialog>
  )
}
