import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { DialogActions, useMediaQuery, useTheme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import {
  AuditData,
  ObjectWithId,
  SiteDetail,
  WearAppState,
} from '../../store/types'
import { useDispatch } from 'react-redux'
import {
  SAVE_FETCHED_AUDIT_DATA,
  SAVE_SITE_DETAIL,
} from '../../store/library/libraryActions'
import { useSetShowBackButton } from '../../hooks/useSetShowBackButton'
import { WearAppAutoComplete } from '../../components/WearAppAutoComplete'
import {
  fetchClients,
  fetchLocations,
  fetchSiteName,
  fetchScreens,
} from '../../services/appSync'
import { WarningDialog } from '../../components/WarningDialog'
import { DropDown } from '../../components/DropDown'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containerPadding: {
    padding: '4em',
  },
  dropDownContainer: {
    marginBottom: '1em',
    flex: 1,
  },
  start_audit: {
    width: '100%',
    flex: 1,
    fontFamily: 'arial',
    fontSize: 14,
    color: '#F2F2F2',
    backgroundColor: '#006068',
    marginBottom: '1em',
  },
  clear_all: {
    width: '100%',
    fontFamily: 'arial',
    fontSize: 14,
    color: '#F2F2F2',
    backgroundColor: 'red',
  },
  dropdown: {
    color: 'white',
    fontFamily: 'arial',
    fontSize: 14,
  },
  label: {
    fontColor: 'red',
    fontFamily: 'arial',
    fontSize: 10.5,
    '&:focus': {
      fontSize: 5,
      fontColor: 'blue',
    },
  },
  root: {
    maxHeight: 50,
    overflow: 'auto',
    width: '100%',
  },
})

type AuditProps = {
  onSavePressed?: (siteDetail: SiteDetail, materialSelected: string) => void
  onCancel?: () => void
  siteDetails?: SiteDetail
  materialSelected?: string
  isAnalysis?: boolean
}

const statuses = [
  {
    Id: 'Replaced',
    Name: 'Replaced',
  },
  {
    Id: 'Kept',
    Name: 'Kept',
  },
  {
    Id: 'New',
    Name: 'New',
  },
  {
    Id: '',
    Name: '',
  },
]

export const Audit: FunctionComponent<AuditProps> = ({
  siteDetails,
  onSavePressed,
  materialSelected,
  onCancel,
  isAnalysis,
}) => {
  useSetShowBackButton(!onSavePressed)
  const librarySiteDetail = useSelector(
    (store: WearAppState) => siteDetails ?? store.library.siteDetail
  )
  const savedFetchedAuditData = useSelector(
    (store: WearAppState) => store.library.auditData
  )

  const [clients, setClients] = useState<Array<ObjectWithId>>([])
  const [siteNameOptions, setSiteNameOptions] = useState<Array<ObjectWithId>>(
    []
  )
  const [locationOptions, setLocationOptions] = useState<Array<ObjectWithId>>(
    []
  )
  const [screenNameOptions, setScreenNameOptions] = useState<
    Array<ObjectWithId>
  >([])
  const [showWarning, setShowWarning] = useState<boolean>(false)

  const [loadingOptions, setLoadingOptions] = useState({
    client: false,
    site: false,
    location: false,
    screen: false,
    deck: false,
  })

  const [clientName, setClientName] = useState<string>(
    librarySiteDetail?.clientName ?? ''
  )
  const [siteName, setSiteName] = useState<string>(
    librarySiteDetail?.siteName ?? ''
  )
  const [locationName, setLocationName] = useState<string>(
    librarySiteDetail?.location ?? ''
  )
  const [screenName, setScreenName] = useState<string>(
    librarySiteDetail?.screenName ?? ''
  )
  // const [deckLevel, setDeckLevel] = useState<string>(
  //   librarySiteDetail?.deckLevel ?? ''
  // )
  const [currentStatus, setCurrentStatus] = useState<SiteDetail['status'] | ''>(
    librarySiteDetail?.status ?? ''
  )
  const [panelType, setPanelType] = useState<string>(materialSelected ?? '')
  const panelTypes = useSelector(
    (store: WearAppState) => store.panelTypes.panelTypes
  )

  const panelTypeValues = Object.values(panelTypes)

  const [openAutoComplete, setOpenAutoComplete] = useState<boolean>(false)

  const toggleLoadingOption = useCallback(
    (key: keyof typeof loadingOptions) => {
      setLoadingOptions((current) => ({
        ...current,
        [key]: !current[key],
      }))
    },
    []
  )

  useEffect(() => {
    toggleLoadingOption('client')
    fetchClients()
      .then((clients) => {
        if (librarySiteDetail) {
          const client = clients.find(
            (client) => client.Name === librarySiteDetail.clientName
          )
          if (client) {
            setClientName(client.Name)
          }
        }
        setClients(clients)
      })
      .catch(() => {
        setClients(savedFetchedAuditData?.clients ?? [])
      })
      .finally(() => {
        toggleLoadingOption('client')
      })
  }, [])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleOpen = () => {
    setOpenAutoComplete(true)
  }

  useEffect(() => {
    if (!clientName.length) {
      return
    }
    toggleLoadingOption('site')
    const clientId = clients.find((client) => client.Name === clientName)?.Id
    fetchSiteName(clientId ?? '')
      .then((sites) => {
        if (librarySiteDetail) {
          const site = sites.find(
            (site) => site.Name === librarySiteDetail.siteName
          )
          if (site) {
            setSiteName(site.Name)
          }
        }
        setSiteNameOptions(sites)
      })
      .catch(() => {
        setSiteNameOptions(savedFetchedAuditData?.sites ?? [])
      })
      .finally(() => {
        toggleLoadingOption('site')
      })
  }, [
    toggleLoadingOption,
    clientName,
    savedFetchedAuditData?.sites,
    librarySiteDetail,
    clients,
  ])

  useEffect(() => {
    if (!siteName.length || !clientName.length) {
      return
    }
    toggleLoadingOption('location')
    const siteId = siteNameOptions.find((site) => site.Name === siteName)?.Id
    fetchLocations(siteId ?? '')
      .then((locations) => {
        if (librarySiteDetail) {
          const location = locations.find(
            (location) => location.Name === librarySiteDetail.location
          )
          if (location) {
            setLocationName(location.Name)
          }
        }
        setLocationOptions(locations)
      })
      .catch(() => {
        setLocationOptions(savedFetchedAuditData?.locations ?? [])
      })
      .finally(() => {
        toggleLoadingOption('location')
      })
  }, [
    clientName,
    librarySiteDetail,
    savedFetchedAuditData?.locations,
    siteName,
    siteNameOptions,
    toggleLoadingOption,
  ])

  useEffect(() => {
    if (!clientName.length || !locationName.length || !siteName.length) {
      return
    }
    toggleLoadingOption('screen')
    toggleLoadingOption('deck')
    const locationId = locationOptions.find(
      (location) => location.Name === locationName
    )?.Id
    fetchScreens(locationId ?? '')
      .then((screens) => {
        if (librarySiteDetail) {
          const screen = screens.find(
            (screen) =>
              screen.DeckHeader.EquipmentId === librarySiteDetail.screenName &&
              screen.DeckHeader.DeckLevel === librarySiteDetail.deckLevel
          )
          if (screen) {
            setScreenName(
              `${screen.DeckHeader.EquipmentId} ${screen.DeckHeader.DeckLevel}`
            )
          }
        }
        const screens_ = []
        for (const screen of screens) {
          screens_.push({
            Id: screen.Id,
            Name: `${screen.DeckHeader.EquipmentId} ${screen.DeckHeader.DeckLevel}`,
          })
        }
        setScreenNameOptions(screens_)
      })
      .catch(() => {
        setScreenNameOptions(savedFetchedAuditData?.locations ?? [])
      })
      .finally(() => {
        toggleLoadingOption('screen')
        toggleLoadingOption('deck')
      })
  }, [
    toggleLoadingOption,
    clientName,
    locationName,
    siteName,
    savedFetchedAuditData?.locations,
    librarySiteDetail,
    locationOptions,
  ])

  const navigate = useNavigate()
  const classes = useStyles()

  const dispatch = useDispatch()

  const saveSiteDetail = () => {
    const screenNameDeckLevel = screenName.split(' ')
    const screenName_ = screenNameDeckLevel
      .slice(0, screenNameDeckLevel.length - 1)
      .join(' ')
    const siteDetail: SiteDetail = {
      siteName,
      deckLevel: screenNameDeckLevel[screenNameDeckLevel.length - 1],
      location: locationName,
      screenName: screenName_,
      clientName,
      status: currentStatus as SiteDetail['status'],
    }

    const fetchedAuditData: AuditData = {
      sites: siteNameOptions,
      clients,
      locations: locationOptions,
      screens: screenNameOptions,
    }
    dispatch({
      type: String(SAVE_FETCHED_AUDIT_DATA),
      payload: {
        ...fetchedAuditData,
      },
    })
    if (onSavePressed) {
      onSavePressed(siteDetail, panelType)
    } else {
      dispatch({
        type: String(SAVE_SITE_DETAIL),
        payload: {
          ...siteDetail,
        },
      })
      navigate('/dashboard/camera')
    }
  }

  const onSaveSiteDetail = () => {
    if (locationName === '' || screenName === '') {
      setShowWarning(true)
    } else {
      saveSiteDetail()
    }
  }

  const onClearAllPressed = () => {
    setClientName('')
    setSiteName('')
    setSiteNameOptions([])
    setLocationName('')
    setLocationOptions([])
    setScreenName('')
    setScreenNameOptions([])
    //setDeckLevel('')
    setCurrentStatus('')
  }

  const disabled = useMemo(() => {
    if (onSavePressed) {
      return (
        currentStatus.trim().length === 0 ||
        (isAnalysis && panelType.length === 0)
      )
    }
    return clientName.length === 0
  }, [
    clientName.length,
    currentStatus,
    onSavePressed,
    panelType.length,
    isAnalysis,
  ])
  return (
    <div
      className={`${classes.container} ${
        !onSavePressed ? classes.containerPadding : {}
      }`}
    >
      <div style={{ width: isMobile || onSavePressed ? '100%' : '50%' }}>
        {onSavePressed && (
          <WearAppAutoComplete
            isLoading={false}
            selected={panelType}
            setSelectedValues={(value) =>
              value && setPanelType(value as string)
            }
            options={panelTypeValues}
            disablePortal={false}
            onOpen={handleOpen}
            multiple={false}
            open={openAutoComplete}
            textInputLabel='Material Type'
            onClose={() => setOpenAutoComplete(false)}
            onCancel={() => setOpenAutoComplete(false)}
            renderOption={(item) => <span>{item as string}</span>}
          />
        )}

        <DropDown
          label='Client Name'
          value={clientName}
          isLoading={loadingOptions.client}
          dropDownKey='client-name'
          data={clients}
          onChange={(e: any) => {
            setClientName(e)
            setSiteName('')
            setLocationName('')
            setScreenName('')
            //setDeckLevel('')
          }}
        />

        <DropDown
          label='Site Name'
          value={siteName}
          isLoading={loadingOptions.site}
          dropDownKey='site-name'
          data={siteNameOptions}
          onChange={setSiteName}
        />

        <DropDown
          label='Location'
          value={locationName}
          isLoading={loadingOptions.location}
          dropDownKey='location'
          data={locationOptions}
          onChange={setLocationName}
        />

        <DropDown
          label='Screen Name'
          value={screenName}
          isLoading={loadingOptions.screen}
          dropDownKey='screen-name'
          data={screenNameOptions}
          onChange={setScreenName}
        />

        {/* <DropDown
          label='Deck Levels'
          value={deckLevel}
          dropDownKey='deck-level'
          data={deckOptions}
          onChange={setDeckLevel}
        />*/}

        {onSavePressed && (
          <DropDown
            data={statuses}
            value={currentStatus}
            label='Status'
            onChange={(status) => {
              setCurrentStatus(status as SiteDetail['status'])
            }}
            dropDownKey='status'
          />
        )}

        {!onSavePressed && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              size='medium'
              className={`${classes.start_audit} ${
                isMobile ? { width: '100%' } : {}
              }`}
              type='submit'
              onClick={onSaveSiteDetail}
              disabled={disabled}
            >
              SUBMIT
            </Button>

            <Button
              disabled={!(clientName || siteName || locationName || screenName)}
              size='medium'
              className={`${classes.clear_all} ${
                isMobile ? { width: '100%' } : {}
              }`}
              type='submit'
              onClick={onClearAllPressed}
            >
              CLEAR All
            </Button>
          </div>
        )}

        {onSavePressed && (
          <DialogActions>
            <Button
              size='medium'
              style={{ color: '#F2F2F2' }}
              type='submit'
              onClick={onClearAllPressed}
            >
              CLEAR All
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              style={{ color: '#007073' }}
              onClick={onSaveSiteDetail}
              disabled={disabled}
            >
              Submit
            </Button>
          </DialogActions>
        )}
        <WarningDialog
          open={showWarning}
          onClose={() => setShowWarning(false)}
          title='Warning'
          description='Some of the site details are not filled, do you still want to continue?'
          positiveButtonText='Yes'
          onPositiveButtonClicked={() => {
            saveSiteDetail()
            setShowWarning(false)
          }}
          negativeButtonText='No'
          onNegativeButtonClicked={() => setShowWarning(false)}
        />
      </div>
    </div>
  )
}
