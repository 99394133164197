import {
  useLayoutEffect,
  useRef,
  useState,
  MutableRefObject,
  useCallback,
  useEffect,
} from 'react'
import { isMobile } from 'react-device-detect'
import { initFabric } from '../services/util'
import { fabric } from 'fabric'
import { BoundingBox } from '../store/types'
import { WearAppTransformProps } from '../components/preview/WearAppTransform'
import { debounce } from 'lodash'
import { useWindowSize } from 'react-use'
import { useGetReviewData } from './useGetReviewData'
import { usePreviewContext } from '../context/PreviewContext'

type UseInitFabricProps = {
  canvasId: string
  useBoundingBoxRef: MutableRefObject<'rectangle' | 'polygon' | undefined>
  boundingBoxesRef: MutableRefObject<Array<BoundingBox>>
  createBoundingBox: WearAppTransformProps['createBoundingBox']
  canEdit: MutableRefObject<boolean>
  setActiveRect: WearAppTransformProps['setActiveRect']
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useInitFabric = ({
  canvasId,
  useBoundingBoxRef,
  boundingBoxesRef,
  createBoundingBox,
  canEdit,
  setActiveRect,
}: UseInitFabricProps) => {
  const canvasRef = useRef<fabric.Canvas>()
  const imageRef = useRef<fabric.Image>()
  const hasCreatedPolygon = useRef<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const [valueSelected, setValuesSelected] = useState<Array<string>>([])

  const { width, height } = useWindowSize()
  const { image } = useGetReviewData()
  const { dispatch } = usePreviewContext()
  const onClick = useCallback(
    (e: any) => {
      if (
        !canvasRef.current ||
        canvasRef.current?.getActiveObject() ||
        !useBoundingBoxRef.current ||
        !canEdit.current
      ) {
        return
      }
      console.log('boundingBoxesRef', boundingBoxesRef)
      if (useBoundingBoxRef.current === 'polygon') {
        if (hasCreatedPolygon.current) {
          return
        }
        if (boundingBoxesRef.current.some(({ type }) => type === 'polygon')) {
          hasCreatedPolygon.current = true
          return
        }
        hasCreatedPolygon.current = true
      }

      const clickEvent = canvasRef.current?.getPointer(e.e)
      createBoundingBox({
        id: new Date().getTime(),
        top: clickEvent.y - (isMobile ? 600 : 400) / 2,
        left: clickEvent.x - (isMobile ? 600 : 400) / 2,
        width: isMobile ? 600 : 400,
        height: isMobile ? 600 : 400,
        failureModes: [],
        type:
          useBoundingBoxRef.current === 'rectangle' ? 'rectangle' : 'polygon',
        screenDimension: {
          width,
          height,
        },
      })
    },
    [createBoundingBox, height, useBoundingBoxRef, width, canEdit]
  )

  const resizeCanvas = useCallback(() => {
    if (!canvasRef.current || !containerRef.current || !imageRef.current) {
      return
    }
    const canvas = canvasRef.current
    const image = imageRef.current
    canvas.setHeight(containerRef.current.clientHeight)
    canvas.setWidth(containerRef?.current.clientWidth)
    const scaleRatio = Math.min(
      containerRef.current.clientWidth / (image.width ?? 1),
      containerRef.current.clientHeight / (image.height ?? 1)
    )
    canvas.setZoom(scaleRatio)
    canvas.renderAll()
  }, [])

  useEffect(() => {
    const debounceResizer = debounce(resizeCanvas, 30)
    window.addEventListener('resize', debounceResizer)
    return () => window.removeEventListener('resize', debounceResizer)
  }, [resizeCanvas])

  useEffect(() => {
    return () => {
      canvasRef.current?.clear()
      canvasRef.current?.dispose()
    }
  }, [])

  useLayoutEffect(() => {
    canvasRef.current = initFabric(canvasId)
    canvasRef.current.on('mouse:down', onClick)
    canvasRef.current.on('mouse:wheel', (opt) => {
      console.log('opt', opt.e.ctrlKey)
      if (!canvasRef.current) {
        return
      }
      const delta = opt.e.deltaY
      let zoom = canvasRef.current?.getZoom()
      zoom *= 0.999 ** delta
      if (zoom > 20) zoom = 20
      if (zoom < 0.01) zoom = 0.01
      canvasRef.current.zoomToPoint(
        { x: opt.e.offsetX, y: opt.e.offsetY },
        zoom
      )
      opt.e.preventDefault()
      opt.e.stopPropagation()
    })
    fabric.Image.fromURL(image, (image) => {
      imageRef.current = image
      const img = imageRef.current
      img.hasControls = false
      img.hoverCursor = 'cursor'
      img.lockMovementX = true
      img.lockMovementY = true
      img.lockRotation = true
      img.selectable = false
      canvasRef.current?.add(img)
      img.sendToBack()
      resizeCanvas()
      canvasRef.current?.renderAll()
      const center = canvasRef.current?.getCenter()
      dispatch({
        type: 'data',
        data: {
          imageDimension: {
            height: center?.top,
            width: center?.left,
          },
        },
      })
    })

    const setPoint = (e: any) => {
      if (!useBoundingBoxRef.current || !canEdit.current) {
        return
      }
      const event = (e as unknown) as {
        selected: Array<{ id: number }>
      }
      if (!event) {
        setPoint(undefined)
        return
      }
      const selected = event.selected[0]
      if (!selected) {
        setPoint(undefined)
        return
      }

      if (useBoundingBoxRef.current === 'rectangle') {
        const boundingBox = boundingBoxesRef.current.find(
          ({ id }) => id === selected.id
        )
        if (boundingBox) {
          setValuesSelected(boundingBox.failureModes ?? [])
          setActiveRect(selected.id)
        }
      } else {
        setActiveRect(selected.id)
      }
    }
    // canvasRef.current.on('selection:cleared', () => setPoint(undefined))
    canvasRef.current.on('selection:created', setPoint)
    canvasRef.current.on('selection:updated', setPoint)
  }, [canvasId, image])

  return {
    valueSelected,
    canvasRef,
    setValuesSelected,
    containerRef,
  }
}
