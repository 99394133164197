import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { WearAppState } from '../store/types'
import { WearAppContext } from '../context/WearAppContext'
import { useNetworkState, useWindowSize } from 'react-use'
import { CircularProgress } from '@material-ui/core'
import { SET_WENT_OFFLINE } from '../store/misc/miscActions'

const FooterContainer = styled.div`
  position: static;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-top: auto;
  padding-left: 0.4em;
  padding-right: 0.4em;
  background-color: rgb(0, 112, 115);
  width: 100% !important;
  z-index: 200000000;
`

const formatLastSyncTime = (lastSyncTime?: number) => {
  return moment(lastSyncTime).format('YYYY-MM-DD HH:mm')
}

const Footer: FunctionComponent = () => {
  const lastSyncTime = useSelector(
    (state: WearAppState) => state.misc?.lastSync
  )
  const isWorking = useSelector(
    (state: WearAppState) => state.library.isWorking
  )
  const isFetchingFailureModes = useSelector(
    (store: WearAppState) => store.panelTypes.isFetching
  )
  const isFetchingPanelTypes = useSelector(
    (store: WearAppState) => store.panelTypes.isFetching
  )
  const isSyncing = isFetchingPanelTypes || isFetchingFailureModes || isWorking
  const { online } = useNetworkState()
  const dispatch = useDispatch()
  const { setFooterDimension } = useContext(WearAppContext)
  const { width, height } = useWindowSize()

  useEffect(() => {
    if (online) {
      return
    }
    dispatch({
      type: SET_WENT_OFFLINE,
      payload: {
        wentOffline: true,
      },
    })
  }, [dispatch, online])

  const footerRef = useRef<HTMLDivElement>()
  useLayoutEffect(() => {
    if (!footerRef.current) {
      return
    }
    setFooterDimension({
      width: footerRef.current.offsetWidth,
      height: footerRef.current.offsetHeight,
    })
  }, [width, height, setFooterDimension])

  const onlineBackground = online ? '#00b050' : 'red'

  return (
    // @ts-ignore
    <FooterContainer ref={footerRef}>
      <div style={{ display: 'inline-flex' }}>
        <span
          style={{ margin: 'auto', alignItems: 'center', marginRight: '.2em' }}
        >
          <b>CaptureApp</b>
        </span>
        {!isSyncing && (
          <span
            style={{
              background: onlineBackground,
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              border: 'white',
              alignItems: 'center',
              margin: 'auto',
            }}
          />
        )}

        {isSyncing && (
          <CircularProgress
            color='inherit'
            size={16}
            style={{
              marginLeft: '.2em',
              margin: 'auto',
            }}
          />
        )}
      </div>
      <div>
        <span style={{ fontFamily: 'arial', fontSize: 13 }}>
          Last Synced: {formatLastSyncTime(lastSyncTime)}
        </span>
      </div>
    </FooterContainer>
  )
}

export default Footer
