import { all, call, put, takeLeading } from 'redux-saga/effects'
import {
  FETCH_ALL_PANEL_TYPES_FAILURE,
  FETCH_ALL_PANEL_TYPES_SUCCESS,
  FETCH_ALL_PANEL_TYPES,
  TOGGLE_IS_FETCHING,
} from './panelTypeActions'
import { PanelTypeState } from '../types'
import { SET_LAST_SYNCED_TIME } from '../misc/miscActions'
import { fetchMaterialTypes } from '../../services/appSync'

function* fetchAllPanels() {
  try {
    yield put({ type: TOGGLE_IS_FETCHING })
    const panelTypes: PanelTypeState = yield call(fetchMaterialTypes)
    yield put({ type: FETCH_ALL_PANEL_TYPES_SUCCESS, payload: { panelTypes } })
    yield put({
      type: SET_LAST_SYNCED_TIME,
      payload: { lastSync: new Date().getTime() },
    })
  } catch (e) {
    yield put({ type: FETCH_ALL_PANEL_TYPES_FAILURE, payload: { error: e } })
  } finally {
    yield put({ type: TOGGLE_IS_FETCHING })
  }
}

export function* panelTypesSagas() {
  yield all([takeLeading(String(FETCH_ALL_PANEL_TYPES), fetchAllPanels)])
}
