import { FunctionComponent } from 'react'
import SplashScreenGif from '../assets/splash_screen.gif'
import { useFetchMiscData } from '../hooks/useFetchMiscData'

export const SplashScreen: FunctionComponent = () => {
  useFetchMiscData()
  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: '#FFF' }}>
      <img
        style={{ objectFit: 'contain', width: '100%', height: '100%' }}
        src={SplashScreenGif}
        alt='splash-screen'
      />
    </div>
  )
}
