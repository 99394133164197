import { createAction } from 'redux-actions'

const ns = 'panelTypes'

export const FETCH_ALL_PANEL_TYPES = createAction(`${ns}/fetchAll`)
export const FETCH_ALL_PANEL_TYPES_SUCCESS = createAction(
  `${ns}/fetchAllSuccess`
)
export const FETCH_ALL_PANEL_TYPES_FAILURE = createAction(
  `${ns}/fetchAllFailure`
)

export const TOGGLE_IS_FETCHING = createAction(`${ns}/toggleIsFetching`)
