import { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { File, WearAppState } from '../../store/types'
import { Grid } from '@material-ui/core'
import {
  CLEAR_SELECTION,
  UPDATE_SELECTED_PHOTOS,
} from '../../store/misc/miscActions'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { useNavigate } from 'react-router-dom'
import { uniqBy } from 'lodash'

const useStyles = makeStyles({
  imageContainer: {
    transition: 'transform ease-in .3s',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(.95, .95)',
    },
  },
  imageContainerSelected: {
    transform: 'scale(.8, .8)',
    border: '4px solid #007073',
    borderRadius: '4px',
  },
})

export const FilesUpload: FunctionComponent = () => {
  const styles = useStyles()
  const files = uniqBy(
    useSelector((state: WearAppState) => state.fileUploads ?? []),
    (file) => {
      return file.file
    }
  )
  const selectedFiles = useSelector(
    (state: WearAppState) => state.misc?.multiSelection?.items ?? []
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    if (files.length === 0) {
      navigate(-1)
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch({
        type: String(CLEAR_SELECTION),
      })
    }
  }, [])

  const onImageClicked = (file: File) => {
    dispatch({
      type: String(UPDATE_SELECTED_PHOTOS),
      payload: {
        selectedItem: file.file,
      },
    })
  }

  return (
    <Grid container spacing={2} style={{ padding: '.6em' }}>
      {files.map((file) => (
        <Grid
          className={`${styles.imageContainer} ${
            selectedFiles.includes(file.file)
              ? styles.imageContainerSelected
              : ''
          }`}
          onClick={() => onImageClicked(file)}
          item
          xs={6}
          md={3}
          key={file.file}
        >
          <img
            style={{ width: '100%', height: '20em' }}
            src={file.file}
            alt='upload'
          />
          {selectedFiles.includes(file.file) && (
            <CheckCircleIcon
              style={{ position: 'fixed', right: '5px', color: '#007073' }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  )
}
