import { FailureMode, FailureModeState } from '../types'
import { handleActions, Action } from 'redux-actions'
import {
  FETCH_ALL_FAILURE_MODES_SUCCESS,
  TOGGLE_IS_FETCHING,
} from './failureModeActions'
import { LOG_OUT_SUCCESS } from '../auth/authActions'

const initialState: FailureModeState = {
  failureModes: [],
  isFetching: false,
}

export type FailureModePayload = {
  failureModes: Array<FailureMode>
  error?: Error
}

export const failureModeReducer = handleActions<
  FailureModeState,
  FailureModePayload
>(
  {
    [String(FETCH_ALL_FAILURE_MODES_SUCCESS)]: (
      failureModeState: FailureModeState,
      { payload }: Action<FailureModePayload>
    ) => ({
      ...failureModeState,
      isSorted: true,
      failureModes: [...payload.failureModes],
    }),
    [String(TOGGLE_IS_FETCHING)]: (failureModeState: FailureModeState) => {
      return {
        ...failureModeState,
        isFetching: !failureModeState.isFetching,
      }
    },
    [String(LOG_OUT_SUCCESS)]: () => {
      return {
        failureModes: [],
        isFetching: false,
      }
    },
  },
  initialState
)
