import { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { WearAppState } from '../../../store/types'

export const ReplacePanelResults: FunctionComponent<{ detail?: boolean }> = ({
  children,
  detail,
}) => {
  const replacePanel = useSelector(
    (store: WearAppState) => store.analysis.replacePanel
  )
  const activeTab = useSelector(
    (state: WearAppState) => state.analysis.activeTab
  )
  return (
    <>
      <div
        style={{
          backgroundColor: replacePanel
            ? replacePanel.replacePanel === 'replace'
              ? 'red'
              : replacePanel.replacePanel === 'do_not_repalce'
              ? 'green'
              : 'gray'
            : 'gray',
          fontWeight: 'bold',
          display: 'flex',
          flexDirection: replacePanel ? 'row' : 'row-reverse',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: replacePanel ? '0 1em' : '1em 1em',
        }}
      >
        {replacePanel && (
          <p>
            {replacePanel?.replacePanel === 'replace'
              ? 'REPLACE PANEL: EXCESSIVE WEAR'
              : replacePanel?.replacePanel === 'do_not_repalce'
              ? 'PANEL DOES NOT NEED REPLACEMENT'
              : 'UNABLE TO DETERMINE IF PANEL REQUIRES REPLACEMENT'}
          </p>
        )}
        {children}
      </div>
      {detail && (
        <>
          <div
            style={{
              backgroundColor: 'lightgray',
              fontWeight: 'bold',
              color: 'black',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '0px 16px',
              justifyItems: 'center',
            }}
          >
            <p>Next Shutdown:</p>
            <p>
              {replacePanel?.daysToNextShutdown
                ? `${replacePanel.daysToNextShutdown} ${
                    replacePanel.daysToNextShutdown > 1 ? 'days' : 'day'
                  }`
                : '-'}
            </p>
          </div>
          <div
            style={{
              backgroundColor: 'white',
              fontWeight: 'bold',
              color: 'black',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '0px 16px',
              justifyItems: 'center',
            }}
          >
            <p>Remaining Life:</p>
            <p>
              {replacePanel?.remainingLife
                ? `${replacePanel.remainingLife} ${
                    replacePanel.remainingLife > 1 ? 'days' : 'day'
                  }`
                : '-'}
            </p>
          </div>
          {activeTab === 1 && (
            <div
              style={{
                backgroundColor: 'lightgray',
                fontWeight: 'bold',
                color: 'black',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '0px 16px',
                justifyItems: 'center',
              }}
            >
              <p>Panel Wear Rate:</p>
              <p>
                {' '}
                {replacePanel?.wearRate
                  ? `${replacePanel.wearRate.toFixed(2)} mm/day`
                  : '-'}
              </p>
            </div>
          )}
        </>
      )}
    </>
  )
}
