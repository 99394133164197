import { AnalysisState } from '../types'
import { Action, handleActions } from 'redux-actions'
import {
  SELECT_PANEL,
  SET_DEPTHS,
  SET_HOMOGRAPHY,
  SET_POLYGON_BBOX,
  SET_RESULTS,
  SET_ACTIVETAB,
  START_ANALYSIS,
  RESET_ANALYSIS,
} from './analysisActions'

const initialState: AnalysisState = {
  bbox: [[], [], [], []],
  activeTab: 0,
}
type AnalysisPayload = Partial<AnalysisState>

export const analysisReducer = handleActions<AnalysisState, AnalysisPayload>(
  {
    [String(SET_HOMOGRAPHY)]: (
      state: AnalysisState,
      { payload }: Action<AnalysisPayload>
    ) => ({
      ...state,
      homography: payload.homography,
    }),
    [String(SET_POLYGON_BBOX)]: (
      state: AnalysisState,
      { payload }: Action<AnalysisPayload>
    ) => ({
      ...state,
      bbox: payload.bbox,
    }),
    [String(SET_DEPTHS)]: (
      state: AnalysisState,
      { payload }: Action<AnalysisPayload>
    ) => ({
      ...state,
      depths: payload.depths,
    }),
    [String(SET_RESULTS)]: (
      state: AnalysisState,
      { payload }: Action<AnalysisPayload>
    ) => ({
      ...state,
      results: payload.results,
      result: payload.result,
      result2: payload.result2,
      replacePanel: payload.replacePanel,
      efficiency: payload.efficiency,
    }),
    [String(START_ANALYSIS)]: (
      state: AnalysisState,
      { payload }: Action<AnalysisPayload>
    ) => ({
      ...state,
      startAnalysis: payload?.startAnalysis ?? !state.startAnalysis,
      info: payload.info,
    }),
    [String(SELECT_PANEL)]: (
      state: AnalysisState,
      { payload }: Action<AnalysisPayload>
    ) => ({
      ...state,
      selectedPanel: payload.selectedPanel,
    }),
    [String(SET_ACTIVETAB)]: (
      state: AnalysisState,
      { payload }: Action<AnalysisPayload>
    ) => ({
      ...state,
      activeTab: payload.activeTab,
    }),
    [String(RESET_ANALYSIS)]: () => ({
      ...initialState,
    }),
  },
  initialState
)
