import {
  CircularProgress,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core'
import { FunctionComponent } from 'react'
import { ObjectWithId } from '../store/types'

const useStyles = makeStyles({
  containerPadding: {
    padding: '4em',
  },
  dropDownContainer: {
    marginBottom: '1em',
    flex: 1,
  },
  start_audit: {
    width: '100%',
    flex: 1,
    fontFamily: 'arial',
    fontSize: 14,
    color: '#F2F2F2',
    backgroundColor: '#006068',
    marginBottom: '1em',
  },

  dropdown: {
    color: 'white',
    fontFamily: 'arial',
    fontSize: 14,
  },
  label: {
    fontColor: 'red',
    fontFamily: 'arial',
    fontSize: 10.5,
    '&:focus': {
      fontSize: 5,
      fontColor: 'blue',
    },
  },
  root: {
    maxHeight: 50,
    overflow: 'auto',
    width: '100%',
  },
})

type DropDownProps = {
  data: Array<ObjectWithId>
  value: string
  label: string
  onChange: (value: string) => void
  dropDownKey: string
  isLoading?: boolean
  enabled?: boolean
}

export const DropDown: FunctionComponent<DropDownProps> = ({
  data,
  dropDownKey,
  onChange,
  value,
  label,
  isLoading,
  enabled = true,
}) => {
  const classes = useStyles()

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FormControl
        variant='standard'
        className={classes.dropDownContainer}
        disabled={!enabled}
      >
        <InputLabel
          className={classes.dropdown}
          id={`label-for-${dropDownKey}`}
        >
          {label}
        </InputLabel>
        <Select
          labelId='demo-simple-select-standard-label'
          id='demo-simple-select-standard'
          value={value}
          onChange={(event) => onChange(event.target.value as string)}
          label={label}
          disabled={!enabled}
        >
          {(data ?? []).map(function({ Id, Name }) {
            return (
              <MenuItem className={classes.dropdown} key={Id} value={Name}>
                {Name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      {isLoading && (
        <CircularProgress
          style={{ marginLeft: '1em' }}
          color='inherit'
          size={16}
        />
      )}
    </div>
  )
}
