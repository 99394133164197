import { all, call, put, takeLeading } from 'redux-saga/effects'
import { uploadLibraryToS3 } from '../../services/s3'
import {
  CLEAR_LIBRARY,
  PUSH_LIBRARY_TO_CLOUD,
  TOGGLE_IS_WORKING,
} from './libraryActions'
import { fetchReviewLibrary } from '../reviewLibrary/reviewLibrarySagas'

function* uploadLibraryToCloud({ payload: { libraries, results } }: any) {
  try {
    yield put({ type: TOGGLE_IS_WORKING })
    for (let i = 0; i < libraries.length; i++) {
      const library = libraries[i]
      const result = results[i]
      yield call(uploadLibraryToS3, library, result)
    }

    console.log('fetching', true)
    yield call(fetchReviewLibrary)
    yield put({ type: CLEAR_LIBRARY })
  } catch (e) {
    console.log('error', e)
  } finally {
    yield put({ type: TOGGLE_IS_WORKING })
  }
}

export function* librarySagas() {
  yield all([takeLeading(String(PUSH_LIBRARY_TO_CLOUD), uploadLibraryToCloud)])
}
