import React, { FunctionComponent } from 'react'
import { Carousel } from 'react-responsive-carousel'
import useMediaQuery from '@material-ui/core/useMediaQuery'

type TabPanelProps = {
  index: number
}

export const TabPanel: FunctionComponent<TabPanelProps> = ({ index }) => {
  let canvasStyle = {
    zIndex: -9999,
    width: '25%',
    maxHeight: '50%',
    padding: '16px 16px',
    margin: 'auto 0px',
  }

  let matches = useMediaQuery('(min-width: 200px')
  if (matches) {
    canvasStyle = {
      ...canvasStyle,
      width: '70%',
    }
  }
  matches = useMediaQuery('(min-width: 400px')
  if (matches) {
    canvasStyle = {
      ...canvasStyle,
      width: '60%',
    }
  }
  matches = useMediaQuery('(min-width: 800px')
  if (matches) {
    canvasStyle = {
      ...canvasStyle,
      width: '45%',
    }
  }
  matches = useMediaQuery('(min-width:1200px)')
  if (matches) {
    canvasStyle = {
      ...canvasStyle,
      width: '35%',
    }
  }

  // useEffect(() => {
  //   window.matchMedia('(max-width: 1024px)').addEventListener('change', (e) => {
  //     if (e.matches) {
  //       setCanvasStyle({
  //         ...canvasStyle,
  //         width: '45%',
  //       })
  //     } else {
  //       setCanvasStyle({
  //         ...canvasStyle,
  //         width: '25%',
  //       })
  //     }
  //   })

  return (
    <div role='tabpanel' id={`tab-${index}`} aria-labelledby={`tab-${index}`}>
      <Carousel showThumbs={false}>
        <div>
          <canvas
            className='canvas-responsive-width'
            style={canvasStyle}
            id='canvas_cv2'
          />
        </div>
        <div>
          <canvas
            className='canvas-responsive-width'
            style={canvasStyle}
            id='canvas_cv'
          />
        </div>
      </Carousel>
    </div>
  )
}
