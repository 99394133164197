import { TransformWrapper } from 'react-zoom-pan-pinch'
import React, { FunctionComponent, MutableRefObject, useState } from 'react'
import { Dimension } from '../../context/WearAppContext'
import { BoundingBox } from '../../store/types'
import { WearAppTransforms } from './WearAppTransforms'
import { BrowserView, MobileView } from 'react-device-detect'
import { FabricWrapper } from './FabricWrapper'

export type WearAppTransformProps = {
  useBoundingBox: 'rectangle' | 'polygon' | undefined
  boundingBoxes: Array<BoundingBox>
  createBoundingBox: (rectangle: BoundingBox) => void
  onDeleteBoundingBox: (id: number) => void
  onBoundingBoxChanged: (boundingBox: Omit<BoundingBox, 'failureModes'>) => void
  onValuesSelected: (id: number, values: Array<string>) => void
  reviewScreenDimension?: Dimension
  canEdit: boolean
  activeRect?: number
  setActiveRect: (activeRect?: number) => void
  openFailureModes: boolean
  setOpenFailureModes: (value: boolean) => void
  openCvCanvasRef: MutableRefObject<HTMLCanvasElement | null>
  templateRef: MutableRefObject<HTMLImageElement | null>
  polygonPts?: number[][]
}

export const WearAppTransform: FunctionComponent<WearAppTransformProps> = (
  props
) => {
  const [doubleStep, setDoubleStep] = useState<number>(8)
  const onPanningStopCallback = (e: any) => {
    if (props.canEdit) {
      return
    }
    if (e.scale !== 1) {
      setDoubleStep(-64)
    } else {
      setDoubleStep(64)
    }
  }

  console.log('polygonPts', props.polygonPts)

  return (
    <>
      <BrowserView>
        <FabricWrapper {...props} />
      </BrowserView>
      <MobileView>
        <TransformWrapper
          doubleClick={{ step: doubleStep }}
          onPanningStop={onPanningStopCallback}
          panning={{
            disabled: props.canEdit,
          }}
          limitToBounds
          disabled={props.canEdit}
        >
          <WearAppTransforms {...props} />
        </TransformWrapper>
      </MobileView>
    </>
  )
}
