import React, { FunctionComponent, MutableRefObject } from 'react'
import { FailureModes } from './FailureModes'
import { useWindowSize } from 'react-use'
import { WearAppTransformProps } from '../WearAppTransform'
import { fabric } from 'fabric'

type RectangleFabricWrapperProps = {
  open: boolean
  valueSelected: string[]
  activeRect?: number
  onValuesSelected: WearAppTransformProps['onValuesSelected']
  onDeleteBoundingBox: WearAppTransformProps['onDeleteBoundingBox']
  setOpen: (open: boolean) => void
  setValuesSelected: (values: string[]) => void
  canvasRef: MutableRefObject<fabric.Canvas | undefined>
}

export const RectangleFabricWrapper: FunctionComponent<RectangleFabricWrapperProps> = ({
  open,
  onValuesSelected,
  setValuesSelected,
  activeRect,
  setOpen,
  valueSelected,
}) => {
  const { width } = useWindowSize()

  const handleClose = (values?: Array<string>) => {
    if (values && activeRect) {
      onValuesSelected(activeRect, values)
    }
    setOpen(false)
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <canvas
        id='bb_canvas'
        width={width}
        style={{
          width: '100% !important',
        }}
      />
      {open && (
        <FailureModes
          onValuesSelected={setValuesSelected}
          selectedValues={valueSelected}
          onClose={handleClose}
        />
      )}
    </div>
  )
}
