import {
  createContext,
  Dispatch,
  FunctionComponent,
  useCallback,
  useContext,
  useReducer,
} from 'react'
import { BoundingBox, SiteDetail } from '../store/types'
import { Dimension } from './WearAppContext'

type DataType = {
  materialSelected?: string
  openPanelDialog?: boolean
  openWarningDialog?: boolean
  openFailureModes?: boolean
  activeRect?: number
  showPanelWarning?: boolean
  showResultPressed?: boolean
  boundingBoxWithOtherFailure?: BoundingBox
  freeTextFailureMode?: string
  navigatingAwayWarning?: string
  openSiteDetailDialog?: boolean
  reviewSiteDetail?: SiteDetail
  openMaterialWarning?: boolean
  openDeleteWarning?: boolean
  showLoader?: boolean
  boundingBoxes?: Array<BoundingBox>
  imageDimension?: Partial<Dimension>
}

type PreviewRouteType = 'bounding_box' | 'polygon'

type PreviewState = {
  currentRoute: PreviewRouteType
  data: DataType
  routeHistory: Array<PreviewRouteType>
}

type StateTypeKey = keyof PreviewState

type ActionType =
  | {
      type: StateTypeKey
      data: PreviewState[StateTypeKey]
    }
  | {
      type: 'data'
      data: Record<keyof DataType, any>
    }
  | {
      type: 'currentRoute'
      data: PreviewRouteType
    }
  | {
      type: 'routeHistory'
      data: Array<PreviewRouteType>
    }

const defaultState: PreviewState = {
  currentRoute: 'bounding_box',
  data: {},
  routeHistory: [],
}

type PreviewContextType = {
  state: PreviewState
  dispatch: Dispatch<ActionType>
  goBack: () => void
  navigate: (route: PreviewRouteType) => void
}

const PreviewContext = createContext<PreviewContextType>({
  dispatch: () => undefined,
  goBack: () => undefined,
  navigate: () => undefined,
  state: defaultState,
})

export const usePreviewContext = (): PreviewContextType =>
  useContext(PreviewContext)

const stateReducer = (state: PreviewState, action: ActionType) => {
  if (action.type === 'data') {
    return {
      ...state,
      data: {
        ...state.data,
        ...(action.data as Record<keyof DataType, any>),
      },
    }
  }
  return {
    ...state,
    [action.type]: action.data,
  }
}

export const PreviewContextProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, defaultState)

  const goBack = useCallback(() => {
    const newHistory = state.routeHistory.slice()
    const lastRoute = newHistory.pop()

    if (!lastRoute) {
      return
    }

    dispatch({ type: 'routeHistory', data: newHistory })
    dispatch({ type: 'currentRoute', data: lastRoute })
  }, [state.routeHistory])

  const navigate = useCallback(
    (toRoute: PreviewRouteType) => {
      console.log('route', toRoute)
      const routeIndexInHistory = state.routeHistory.indexOf(toRoute)
      if (routeIndexInHistory !== -1) {
        dispatch({
          type: 'routeHistory',
          data: state.routeHistory.slice(0, routeIndexInHistory),
        })
      } else {
        dispatch({
          type: 'routeHistory',
          data: state.routeHistory.slice().concat(state.currentRoute),
        })
      }
      dispatch({ type: 'currentRoute', data: toRoute })
    },
    [state.currentRoute, state.routeHistory]
  )

  return (
    <PreviewContext.Provider value={{ state, dispatch, goBack, navigate }}>
      {children}
    </PreviewContext.Provider>
  )
}
