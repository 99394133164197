import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { CircularProgress, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CHANGE_PASSWORD } from '../../store/auth/authActions'
import { WearAppState } from '../../store/types'

const useStyles = makeStyles({
  textField: {
    width: '100%',
    marginTop: '1em',
  },
  resetPassword: {
    width: '100%',
    fontFamily: 'arial',
    marginTop: '1em',
    fontSize: 14,
    color: '#F2F2F2',
    backgroundColor: '#006068',
  },
  login: {
    textDecoration: 'none',
    fontSize: 12,
    marginTop: '1em',
  },
})

export const ChangePassword: FunctionComponent = () => {
  const styles = useStyles()
  const { state } = useLocation()
  const [code, setCode] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const isLoading = useSelector((state: WearAppState) => state.auth?.isLoading)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const resetPasswordSuccess = useSelector(
    (state: WearAppState) => state.auth?.resetPasswordSuccess
  )
  const isResettingPassword = useSelector(
    (state: WearAppState) => state.auth?.isResettingPassword
  )
  useEffect(() => {
    if (!isResettingPassword) {
      navigate(-1)
    }
  }, [isResettingPassword, navigate])

  useEffect(() => {
    if (!resetPasswordSuccess) {
      return
    }
    navigate('/auth/login')
  }, [resetPasswordSuccess, navigate])

  const changePassword = () => {
    dispatch({
      type: String(CHANGE_PASSWORD),
      payload: {
        username: (state as Record<string, any>).email,
        code,
        password,
      },
    })
  }

  const isValid = useMemo(() => {
    if (!password.length || !confirmPassword.length || code.length < 4) {
      return false
    }
    return password === confirmPassword
  }, [code, password, confirmPassword])

  return (
    <>
      <TextField
        value={code}
        onChange={(event) => setCode(event.target.value.trim())}
        className={styles.textField}
        label='Code'
        type='text'
      />

      <TextField
        value={password}
        onChange={(event) => setPassword(event.target.value.trim())}
        className={styles.textField}
        label='New password'
        type='password'
      />

      <TextField
        value={confirmPassword}
        onChange={(event) => setConfirmPassword(event.target.value.trim())}
        className={styles.textField}
        label='Confirm password'
        type='password'
      />

      {!isLoading && (
        <>
          <Button
            onClick={changePassword}
            disabled={!isValid}
            className={styles.resetPassword}
            size='medium'
          >
            Change password
          </Button>
          <Button
            variant='text'
            className={styles.login}
            onClick={() => navigate('/auth/login')}
          >
            Remember password? Login!
          </Button>
        </>
      )}

      {isLoading && (
        <CircularProgress
          style={{ marginTop: '1em' }}
          color='inherit'
          size={20}
        />
      )}
    </>
  )
}
