import * as tf from '@tensorflow/tfjs'
import cv, { Mat, MatVector, Point } from 'opencv-ts'
// import { Point as PointType } from 'opencv-ts'
import { PanelType } from '../store/types'

const stats = require('stats-lite')

export function prepare_image_for_ap_detection(image: Mat): Mat {
  const new_image = new cv.Mat(image.rows, image.cols, image.type())
  cv.equalizeHist(image, new_image)
  cv.medianBlur(new_image, new_image, 3)
  return new_image
}

export function edge_detection(image: Mat): Mat {
  const new_image = new cv.Mat(image.rows, image.cols, image.type())
  cv.Canny(image, new_image, 50, 150)
  return new_image
}

export function detect_contour(image: Mat): MatVector {
  const contours = new cv.MatVector()
  const hierarchy = new cv.Mat()
  try {
    cv.findContours(
      image,
      contours,
      hierarchy,
      cv.RETR_TREE,
      cv.CHAIN_APPROX_NONE
    )
  } catch (error) {
    // console.log('Error = ', error);
  }

  return contours
}

// export function scale_template(panel_data: Mat, panel_image: Mat, aperture_data: any): Mat {
//   // # Unpack
//   aperture_image, Aperture_Size_W1_pix, Aperture_Size_L_pix = aperture_data
//   Aperture_Size_W1, Aperture_Size_L, Panel_Size_W, Panel_Size_L, _ = panel_data
//   Panel_Size_L_pix, Panel_Size_W_pix, _ = panel_image.shape

//   // # Calc pix/mm for the panel image
//   Panel_Size_W_pix_per_mm = Panel_Size_W_pix / Panel_Size_W
//   Panel_Size_L_pix_per_mm = Panel_Size_L_pix / Panel_Size_L

//   // # Calculate panel image aperture dimensions
//   panel_aperture_W = Panel_Size_W_pix_per_mm * Aperture_Size_W1
//   panel_aperture_L = Panel_Size_L_pix_per_mm * Aperture_Size_L

//   f_hor, f_vert = calc_template_scale(panel_aperture_W, panel_aperture_L, Aperture_Size_W1_pix, Aperture_Size_L_pix)
//   res_im = resize_image(aperture_image, None, f_hor, f_vert)

//   cv2.imwrite("test_images//template_out.jpg", res_im)

//   return res_im
// }

export function calc_section_depth(
  width_est: number,
  top_width: number,
  bottom_width: number,
  section_depth: number
) {
  //         |<-top_width->|
  //  _________           _________
  //    ^depth/           |\             ^
  //    |    /            |a\            |
  //    |   /  width_est  |  \           | section-depth
  //   --- X--------------|<*>X <-*base  |
  //      /               |____\      ___|___
  //     |                      |
  //     |<-   bottom_width   ->|

  const base = (width_est - top_width) / 2
  const alpha = Math.atan((bottom_width - top_width) / 2 / section_depth)
  let depth = base / Math.tan(alpha)

  if (depth < 0) {
    depth = 0
  }
  return depth
}

// function replace_panel_decision(panel: PanelType) {
//   const wear_95_percent = 8 // from WearApp calculations -> depth_mean from WearAppTabs.tsx->calcPanelWear output
//   const safety_margin = 5 //mm // Hook up to UI
//   const next_shutdown_date = new Date('2023-1-1') // Hook up to UI
//   const panel_install_date = new Date('2022-10-5') // Hook up to UI

//   const Working_Depth_Lig = parseFloat(panel.Working_Depth_Lig)
//   const date_today = new Date(Date.now())

//   const d1 = Math.abs(next_shutdown_date.getDate() - data_today.getDate())
//   const d2 = Math.abs(date_today.getDate() - panel_install_date.getDate())
//   const days_to_next_shutdown = Math.ceil(d1 / (1000 * 60 * 60 * 24))
//   const days_installed = Math.ceil(d2 / (1000 * 60 * 60 * 24))

//   console.log(days_to_next_shutdown, ' shutdown days')
//   console.log(days_installed, ' installed days')

//   const wear_rate = wear_95_percent / days_installed
//   console.log(wear_rate, '(wear) mm/day')

//   const remaining_life = (Working_Depth_Lig - safety_margin) / wear_rate
//   console.log('(depth) mm')
//   console.log(remaining_life, '(life) days')

//   console.log(safety_margin, '(safety_margin) mm')

//   if (remaining_life < days_to_next_shutdown) {
//     console.log('Replace Panel')
//     return true
//   } else {
//     console.log('Panel Does Not Need Replacement')
//     return false
//   }
// }

export function calcPanelWear(width_est_95: number, panel: PanelType) {
  const working_depth = parseFloat(panel.Working_Depth_Lig)

  let depth = 0
  let section_depth = 0

  let prev_taper_width = 0
  let next_taper_width = 0
  let prev_taper_depth = 0

  const Aperture_Size_W1 = parseFloat(panel.Aperture_Size_W1)
  const AP_TopZone_W2 = parseFloat(panel.AP_TopZone_W2)
  const AP_TopZone_H12 = parseFloat(panel.AP_TopZone_H12)

  let AP_MidZone_W3 = 0
  let AP_MidZone_H23 = 0

  try {
    if (width_est_95 <= AP_TopZone_W2 && width_est_95 > 0) {
      prev_taper_width = Aperture_Size_W1
      next_taper_width = AP_TopZone_W2

      depth = calc_section_depth(
        width_est_95,
        Aperture_Size_W1,
        AP_TopZone_W2,
        AP_TopZone_H12
      )
    } /*else {
      depth = AP_TopZone_H12
    }*/
  } catch (e) {
    // swallow
  }

  try {
    AP_MidZone_W3 = parseFloat(panel.AP_MidZone_W3)
    AP_MidZone_H23 = parseFloat(panel.AP_MidZone_H23)

    if (width_est_95 <= AP_MidZone_W3 && width_est_95 > AP_TopZone_W2) {
      prev_taper_width = AP_TopZone_W2
      next_taper_width = AP_MidZone_W3
      prev_taper_depth = AP_TopZone_H12

      section_depth = calc_section_depth(
        width_est_95,
        AP_TopZone_W2,
        AP_MidZone_W3,
        AP_MidZone_H23
      )
      depth = section_depth + AP_TopZone_H12
    } /*else {
      depth = AP_MidZone_H23 + AP_TopZone_H12
    }*/
  } catch (e) {
    // swallow
  }

  try {
    const AP_BottomZone_W4 = parseFloat(panel.AP_BottomZone_W4)
    const AP_BottomZone_H34 = parseFloat(panel.AP_BottomZone_H34)

    if (width_est_95 > AP_MidZone_W3) {
      prev_taper_width = AP_MidZone_W3
      next_taper_width = AP_BottomZone_W4
      prev_taper_depth = AP_MidZone_H23 + AP_TopZone_H12

      section_depth = calc_section_depth(
        width_est_95,
        AP_MidZone_W3,
        AP_BottomZone_W4,
        AP_BottomZone_H34
      )
      depth = section_depth + AP_TopZone_H12 + AP_MidZone_H23
    }
  } catch (e) {
    // swallow
  }

  let less_topzone = false
  let more_wd = false

  if (
    depth <= AP_TopZone_H12 &&
    Math.abs(Aperture_Size_W1 - AP_TopZone_W2) < 0.01
  ) {
    // TODO: Should display {"<" AP_TopZone_H12}
    depth = AP_TopZone_H12
    less_topzone = true
  }

  if (depth >= working_depth) {
    more_wd = true
    // TODO: Should display {">" working_depth}
    if (Math.abs(prev_taper_width - next_taper_width) < 0.01) {
      depth = prev_taper_depth
    } else if (prev_taper_width > next_taper_width) {
      depth = working_depth
    }
  }

  return { depth, less_topzone, more_wd }
}

export function fill_image(image: Mat, pts: Point[], percent: number): Mat {
  const color = new cv.Scalar(
    0,
    Math.floor(255 * (1 - percent)),
    Math.floor(255)
  )
  cv.rectangle(image, pts[0], pts[1], color, -1)
  return image
}

export const euclidean = (p: number[], q: number[]): number => {
  if (p.length !== q.length) return -1
  const subtracted = q.map((i, n) => i - p[n])
  const powered = subtracted.map((e) => Math.pow(e, 2))
  const sum = powered.reduce((total, current) => total + current, 0)
  return Math.sqrt(sum)
}

export function threshold(image: Mat): Mat {
  const new_image = new cv.Mat()
  const thresh = 80
  cv.threshold(image, new_image, thresh, 255, cv.THRESH_BINARY | cv.THRESH_OTSU)
  return new_image
}

export function convert_to_gray(image: Mat): Mat {
  if (image.channels() === 1) {
    return image
  }
  const new_image = new cv.Mat()
  if (image.channels() === 4) {
    cv.cvtColor(image, new_image, cv.COLOR_BGRA2GRAY)
    return new_image
  } else {
    cv.cvtColor(image, new_image, cv.COLOR_BGR2GRAY)
    return new_image
  }
}

export function power_transformation(image: Mat, power: number): Mat {
  const table = []
  const new_image = []
  const data = image.data
  const invGamma = 1.0 / power
  for (let i = 0; i < 256; i++) {
    table.push(Math.pow(i / 255, invGamma) * 255)
  }
  for (let i = 0; i < data.length; i++) {
    new_image.push(table[data[i]])
  }
  const table_mat = cv.matFromArray(
    image.rows,
    image.cols,
    cv.CV_8UC1,
    new_image
  )
  return table_mat
}

export function find_aperture(image: Mat, power: number): any {
  // const aperture_width_actual = 8.5;
  // const aperture_length_actual = 21;
  // const aperture_area = aperture_length_actual * aperture_width_actual

  // let new_image = convert_to_gray(image);
  // new_image = power_transformation(new_image, power);
  // new_image = threshold(new_image);
  const new_image = new cv.Mat()
  const new_image_thresh = new cv.Mat()
  const new_image_thresh_inv = new cv.Mat()
  // const ksize = new cv.Size(5, 5)
  const ap_scale = 512 / image.rows
  cv.resize(image, new_image, new cv.Size(0, 0), ap_scale, ap_scale)
  cv.medianBlur(new_image, new_image, 47)
  cv.resize(new_image, new_image, new cv.Size(0, 0), 1 / ap_scale, 1 / ap_scale)
  cv.cvtColor(new_image, new_image, cv.COLOR_RGB2GRAY, 0)
  // let image_mean = cv.mean(new_image)
  // image_mean = (image_mean[0] + image_mean[1] + image_mean[2]) / 3
  // image_mean += 50

  cv.threshold(
    new_image,
    new_image_thresh,
    0,
    255,
    cv.THRESH_BINARY + cv.THRESH_OTSU
  )
  cv.threshold(
    new_image,
    new_image_thresh_inv,
    0,
    255,
    cv.THRESH_BINARY_INV + cv.THRESH_OTSU
  )

  // cv.adaptiveThreshold(
  //   new_image,
  //   new_image_thresh,
  //   255,
  //   cv.ADAPTIVE_THRESH_GAUSSIAN_C,
  //   cv.THRESH_BINARY,
  //   55,
  //   4
  // )
  // cv.adaptiveThreshold(
  //   new_image,
  //   new_image_thresh_inv,
  //   255,
  //   cv.ADAPTIVE_THRESH_GAUSSIAN_C,
  //   cv.THRESH_BINARY_INV,
  //   15,
  //   20
  // )

  // cv.imshow('canvas_cv0', new_image_thresh)
  const contours = detect_contour(new_image_thresh)

  const areas: number[] = []
  for (let i = 0; i < contours.size(); i++) {
    areas.push(cv.contourArea(contours.get(i)))
  }
  // let new_contours = []
  // const boxes: cv.RotatedRect[] = []
  // for (let i = 0; i < contours.size(); i++) {
  //   const newctr = new cv.Mat()
  //   cv.convexHull(contours, newctr)
  //   const rect = cv.minAreaRect(newctr)
  //   const vertices = rect.points
  //   console.log('vert:', newctr)
  //   boxes.push(vertices)
  //   new_contours.push(newctr)
  // }

  // const max_arg = areas.indexOf(Math.max(...areas));
  const indices = areas
    .map((val, ind) => {
      return { ind, val }
    })
    .sort((a, b) => {
      return a.val > b.val ? 1 : a.val === b.val ? 0 : -1
    })
    .map((obj) => obj.ind)
  const max_arg = indices[0]

  // interface Circle {
  //   radius: number
  //   center: PointType
  // }

  // const hull = new cv.Mat()
  // cv.convexHull(contours.get(max_arg), hull)

  // cv.cvtColor(new_image, new_image, cv.COLOR_GRAY2BGR)
  // cv.drawContours(new_image, contours, 1, new cv.Scalar(0, 255, 0), 2)

  // const area = cv.contourArea(contours, false)
  // console.log('area:', area)

  const newctr = new cv.Mat()
  cv.convexHull(contours.get(max_arg), newctr)
  // const circle = (cv.minEnclosingCircle(newctr) as unknown) as Circle
  // const circleColor = new cv.Scalar(255, 0, 0)
  // cv.circle(new_image, circle.center, circle.radius, circleColor, 2)

  const hull_bbox = cv.boundingRect(newctr)
  const x = hull_bbox.x
  const y = hull_bbox.y
  const w = hull_bbox.width
  const h = hull_bbox.height

  const pt1 = new cv.Point(x, y)
  const pt2 = new cv.Point(x + w, y + h)
  const color = new cv.Scalar(0, 255, 0)
  cv.rectangle(new_image, pt1, pt2, color, 8)
  // console.log('hullbbox:', hull_bbox.width)

  // const dist_map = new cv.Mat()
  // cv.distanceTransform(
  //   new_image_thresh_inv,
  //   dist_map,
  //   cv.DIST_L2,
  //   cv.DIST_MASK_PRECISE
  // )
  // const res = cv.minMaxLoc(dist_map)
  // cv.circle(new_image, res.maxLoc, res.maxVal, new cv.Scalar(0, 0, 240), 2)
  // const width = res.maxVal * 2
  // const height = circle.radius * 2

  const width = w
  // const width = Math.min(hull_bbox.width, hull_bbox.size.height)
  // const height = Math.max(hull_bbox.size.width, hull_bbox.size.height)
  const height = h
  // const height = hull_bbox.size.height

  // cv.circle(new_image, circle.center, circle.radius, circleColor, 3);
  // cv.imshow('canvas_cv3', new_image)
  // console.log('DONE:', res)
  // const moments = cv.moments(contours)
  // const cnt = newctr
  // You can try more different parameters
  // const moments = cv.moments(newctr)
  // const centroidx = moments.m10 / moments.m00
  // const centroidy = moments.m01 / moments.m00

  const centroidx = width / 2
  const centroidy = height / 2

  return {
    width,
    height,
    area: width * height,
    centroidx,
    centroidy,
    // angle: hull_bbox.angle,
  }
}

export function get_blur_value(image: Mat): number {
  const new_image = new cv.Mat()
  cv.Laplacian(image, new_image, cv.CV_64F, 3, 1, 0, cv.BORDER_DEFAULT)
  return stats.variance(new_image.data32F)
}

export async function nonMaxSuppression(boxes: any, scores: any, tf_type: any) {
  const return_value1: any = await private_nonMaxSuppression(
    boxes,
    scores,
    tf_type
  )
  return return_value1.selectedIndices.array()
}

export function private_nonMaxSuppression(
  boxes: any,
  scores: any,
  tf_type: boolean
): Promise<tf.NamedTensorMap> {
  if (tf_type) {
    return tf.image.nonMaxSuppressionPaddedAsync(boxes, scores, 1, 0.5, 0)
  } else {
    return tf.image.nonMaxSuppressionPaddedAsync(
      tf.tensor2d(boxes),
      tf.tensor1d(scores),
      500,
      0.6,
      0.7
    )
  }
}

export function perspective_transform(points: number[], image: Mat): Mat {
  // console.log('points = ', points);
  const src_points = cv.matFromArray(4, 1, cv.CV_32FC2, points)
  const dst_points = cv.matFromArray(
    4,
    1,
    cv.CV_32FC2,
    // [0, 0, 200, 0, 200, 310, 0, 310]
    // [0, 0, 0, 310, 200, 310, 200, 0] //[tl, tr, br, bl]
    [0, 0, 200, 310, 200, 0, 0, 310] //[tl, br, bl, tr]
  )
  // console.log('src points = ', src_points);
  // console.log('dst points = ', dst_points);
  const mat = cv.getPerspectiveTransform(src_points, dst_points)
  // console.log('mat = ', mat);
  const card = new cv.Mat(200, 310, cv.CV_8UC4)
  cv.warpPerspective(
    image,
    card,
    mat,
    new cv.Size(310, 200),
    cv.INTER_LINEAR,
    cv.BORDER_CONSTANT,
    new cv.Scalar()
  )
  return card
}

export function rotate_img(image: Mat, degrees: number): Mat {
  const image_center = [image.rows / 2, image.cols / 2]
  const rot_mat = cv.getRotationMatrix2D(
    new cv.Point(image_center[0], image_center[1]),
    degrees,
    1.0
  )
  cv.warpAffine(image, image, rot_mat, new cv.Size(image.rows, image.cols))

  return image
}

export const get_results = async (
  template_image: string,
  aperture_img: string,
  rotation: number,
  tpl_dim: number[]
) => {
  // console.log('read tpl:', template_image)
  let src = cv.imread(template_image)
  const aperture = cv.imread(aperture_img)
  src = rotate_img(src, rotation)

  const TPL_SCALE = 0.3
  cv.resize(src, src, new cv.Size(0, 0), TPL_SCALE, TPL_SCALE)

  // const N_APTS = 128
  const AP_W = tpl_dim[0]
  const AP_H = tpl_dim[1]
  const Panel_Size_W = tpl_dim[2]
  const Panel_Size_H = tpl_dim[3]

  const Panel_Size_W_px = src.cols
  const Panel_Size_H_px = src.rows

  const borderx = 0.635
  const bordery = 0.83

  const AP_W_px = aperture.cols * borderx
  const AP_H_px = aperture.rows * bordery

  const scale_w = AP_W / Panel_Size_W
  const scale_h = AP_H / Panel_Size_H

  const ap_px_w = Panel_Size_W_px * scale_w
  const ap_px_h = Panel_Size_H_px * scale_h

  const f_hor = ap_px_w / AP_W_px
  const f_vert = ap_px_h / AP_H_px

  cv.resize(aperture, aperture, new cv.Size(0, 0), f_hor, f_vert)

  const dst = new cv.Mat(src.cols, src.rows, cv.CV_8UC4)
  const dst_bw = new cv.Mat(src.cols, src.rows, cv.CV_8UC4)
  const aperture_bw = new cv.Mat(aperture.cols, aperture.rows, cv.CV_8UC4)
  const aperture_bw_blur = new cv.Mat(aperture.cols, aperture.rows, cv.CV_8UC4)
  const aperture_bw_blur_thres = new cv.Mat(
    aperture.cols,
    aperture.rows,
    cv.CV_8UC4
  )
  const result = new cv.Mat(
    src.cols - aperture_bw_blur_thres.cols + 1,
    src.rows - aperture_bw_blur_thres.rows + 1,
    cv.CV_32FC1
  )
  cv.cvtColor(src, dst, cv.COLOR_BGR2RGB)
  cv.normalize(dst, dst_bw, 0, 255, cv.NORM_MINMAX, cv.CV_8UC4)
  cv.cvtColor(dst_bw, dst_bw, cv.COLOR_BGR2GRAY)
  cv.cvtColor(aperture, aperture_bw, cv.COLOR_BGR2GRAY)
  cv.medianBlur(aperture_bw, aperture_bw_blur, 9)
  cv.threshold(aperture_bw_blur, aperture_bw_blur_thres, 80, 255, 0)
  cv.medianBlur(dst_bw, dst_bw, 3)

  cv.matchTemplate(
    dst_bw,
    aperture_bw_blur_thres,
    result,
    cv.TM_CCOEFF_NORMED,
    aperture_bw_blur
  )

  const height = aperture.rows
  const width = aperture.cols

  const boxes = []
  const scores = []

  const result_array = result.data32F

  let index

  for (let i = 0; i < result.rows; i++) {
    for (let j = 0; j < result.cols; j++) {
      index = ((i * result.cols) as number) + j
      boxes.push([j, i, j + width, i + height])
      scores.push(result_array[index])
    }
  }

  const return_value1 = await nonMaxSuppression(boxes, scores, false)

  return return_value1.length
}

export const analyze = async (
  template_image: string,
  aperture_image: string,
  template_coords: number[][],
  panel: PanelType
) => {
  // console.log('panel:', panel)
  const base_width = parseFloat(panel.Aperture_Size_W1)
  const base_height = parseFloat(panel.Aperture_Size_L)
  const base_panel_width = parseFloat(panel.Panel_Size_W)
  const base_panel_height = parseFloat(panel.Panel_Size_L)
  const tpl_dim = [base_width, base_height, base_panel_width, base_panel_height]

  const orientation1 = await get_results(
    template_image,
    aperture_image,
    0,
    tpl_dim
  )
  const orientation2 = await get_results(
    template_image,
    aperture_image,
    90,
    tpl_dim
  )

  let src_org = cv.imread(template_image)
  let src_org2 = cv.imread(template_image)
  let src_org3 = cv.imread(template_image)
  let src = cv.imread(template_image)

  // console.log('will rot:', orientation2, orientation1)
  if (orientation2 > orientation1) {
    // console.log('rotate!', orientation2, orientation1)
    src = rotate_img(src, 90)
    src_org = rotate_img(src_org, 90)
    src_org2 = rotate_img(src_org2, 90)
    src_org3 = rotate_img(src_org3, 90)
  }

  const aperture = cv.imread(aperture_image)

  // console.log('read tpl:', src.cols)
  const TPL_SCALE = 1024.0 / src.cols
  cv.resize(src, src, new cv.Size(0, 0), TPL_SCALE, TPL_SCALE)

  const blur_value_ap = get_blur_value(aperture)
  const power_ap = blur_value_ap > 100 ? 0.98 : 1.2

  // const N_APTS = 128
  const AP_W = tpl_dim[0]
  const AP_H = tpl_dim[1]
  const Panel_Size_W = tpl_dim[2]
  const Panel_Size_H = tpl_dim[3]

  const Panel_Size_W_px = src.cols
  const Panel_Size_H_px = src.rows

  // const base_aperture_res = find_aperture(aperture, power_ap)
  // console.log('base:', base_aperture_res)
  // const base_area_mm = AP_W * AP_H

  const borderx = 0.635
  const bordery = 0.83

  const AP_W_px = aperture.cols * borderx
  const AP_H_px = aperture.rows * bordery

  const scale_w = AP_W / Panel_Size_W
  const scale_h = AP_H / Panel_Size_H

  const ap_px_w = Panel_Size_W_px * scale_w
  const ap_px_h = Panel_Size_H_px * scale_h

  const f_hor = ap_px_w / AP_W_px
  const f_vert = ap_px_h / AP_H_px

  cv.resize(aperture, aperture, new cv.Size(0, 0), f_hor, f_vert)
  // const base_area = aperture.cols * 0.635 * aperture.rows * 0.83

  const pix_to_mm_w = Panel_Size_W / Panel_Size_W_px
  const pix_to_mm_h = Panel_Size_H / Panel_Size_H_px

  const dst = new cv.Mat(src.cols, src.rows, cv.CV_8UC4)
  const dst_bw = new cv.Mat(src.cols, src.rows, cv.CV_8UC4)
  const aperture_bw = new cv.Mat(aperture.cols, aperture.rows, cv.CV_8UC4)
  const aperture_bw_blur = new cv.Mat(aperture.cols, aperture.rows, cv.CV_8UC4)
  const aperture_bw_blur_thres = new cv.Mat(
    aperture.cols,
    aperture.rows,
    cv.CV_8UC4
  )
  const result = new cv.Mat(
    src.cols - aperture_bw_blur_thres.cols + 1,
    src.rows - aperture_bw_blur_thres.rows + 1,
    cv.CV_32FC1
  )
  cv.cvtColor(src, dst, cv.COLOR_BGR2RGB)
  cv.cvtColor(src_org, src_org, cv.COLOR_BGR2RGB)
  cv.cvtColor(src_org2, src_org2, cv.COLOR_BGR2RGB)
  cv.cvtColor(src_org3, src_org3, cv.COLOR_BGR2RGB)
  // let start_time: any, end_time: any
  // start_time = Date.now()
  cv.normalize(dst, dst_bw, 0, 255, cv.NORM_MINMAX, cv.CV_8UC4)
  cv.medianBlur(dst, dst_bw, 11)
  cv.cvtColor(dst_bw, dst_bw, cv.COLOR_BGR2GRAY)
  // cv.adaptiveThreshold(
  //   dst,
  //   dst_bw,
  //   255,
  //   cv.ADAPTIVE_THRESH_GAUSSIAN_C,
  //   cv.THRESH_BINARY,
  //   55,
  //   2
  // )

  cv.cvtColor(aperture, aperture_bw, cv.COLOR_BGR2GRAY)
  cv.medianBlur(aperture_bw, aperture_bw_blur, 9)
  cv.adaptiveThreshold(
    aperture_bw_blur,
    aperture_bw_blur_thres,
    255,
    cv.ADAPTIVE_THRESH_GAUSSIAN_C,
    cv.THRESH_BINARY,
    55,
    2
  )
  // cv.medianBlur(dst_bw, dst_bw, 3)

  // end_time = Date.now()
  // setImage((end_time - start_time)/1000);
  // start_time = Date.now()

  cv.matchTemplate(
    dst_bw,
    aperture_bw_blur_thres,
    result,
    cv.TM_CCOEFF_NORMED,
    aperture_bw_blur
  )

  // console.log('results:', result)
  // end_time = Date.now()
  // console.log('matching measurement time:', (end_time - start_time) / 1000)

  const height = aperture.rows
  const width = aperture.cols

  const boxes = []
  const scores = []

  const result_array = result.data32F

  let index

  for (let i = 0; i < result.rows; i++) {
    for (let j = 0; j < result.cols; j++) {
      index = ((i * result.cols) as number) + j
      const score = result_array[index]
      if (score > 0.4) {
        boxes.push([j, i, j + width, i + height])
        scores.push(score)
      }
    }
  }

  // let pts, pt1, pt2
  // const color = new cv.Scalar(255, 255, 0)
  // const thickness = 2
  // start_time = Date.now()
  const return_value1 = await nonMaxSuppression(boxes, scores, false)
  // end_time = Date.now()
  // console.log(
  //   'MAX SUP TIME:',
  //   (end_time - start_time) / 1000,
  //   return_value1.length
  // )

  const results = []
  const scale = 1 / TPL_SCALE
  // start_time = Date.now()
  for (let i = 0; i < return_value1.length; i++) {
    boxes[return_value1[i]][0] *= scale
    boxes[return_value1[i]][1] *= scale
    boxes[return_value1[i]][2] *= scale
    boxes[return_value1[i]][3] *= scale

    const pts = boxes[return_value1[i]]
    const curr_width = pts[2] - pts[0]
    const curr_height = pts[3] - pts[1]

    const new_image = src_org.roi(
      new cv.Rect(pts[0], pts[1], curr_width, curr_height)
    )
    // console.log('new_image 417 - ', new_image);
    try {
      const res = find_aperture(new_image, power_ap)
      // const ap_width = res.width * pix_to_mm_w * TPL_SCALE
      // const ap_height = res.height * pix_to_mm_h * TPL_SCALE
      // if (
      //   ap_width <= base_width / 1.8 ||
      //   ap_width >= base_width * 1.8 //||
      //   // ap_height >= base_height * 2 ||
      //   // ap_height <= base_height / 2
      // ) {
      //   // console.log('outliear:', ap_width, ap_height, base_width, base_height)
      //   continue
      // }
      res['pts'] = pts
      results.push(res)
    } catch (e) {
      console.log('ERR:', e)
      continue
    }
    // cv.imshow("canvas_color", res.new_image)
    // cv.imshow('canvas_cv3', new_image)
  }
  // end_time = Date.now()
  // console.log('ap measurement time:', (end_time - start_time) / 1000)

  const calculated_aperture_widths = results.map(
    (val) => val.width * pix_to_mm_w * TPL_SCALE
  )
  const calculated_aperture_heights = results.map(
    (val) => val.height * pix_to_mm_h * TPL_SCALE
  )
  const calculated_areas = results.map(
    (val) => val.area * pix_to_mm_w * pix_to_mm_h * TPL_SCALE * TPL_SCALE
  )
  const calculated_aperture_centroids = results.map((val) => {
    return {
      x: val.centroidx * pix_to_mm_w * TPL_SCALE,
      y: val.centroidy * pix_to_mm_h * TPL_SCALE,
    }
  })
  const calculated_pts_mm = results.map((val) => {
    const point = val.pts
    point[0] *= pix_to_mm_w * TPL_SCALE
    point[1] *= pix_to_mm_h * TPL_SCALE
    point[2] *= pix_to_mm_w * TPL_SCALE
    point[3] *= pix_to_mm_h * TPL_SCALE

    return point
  })
  // setMeanWidth(stats.mean(calculated_aperture_widths));
  // setStdWidth(stats.stdev(calculated_aperture_widths));
  // setPercentWidth(stats.percentile(calculated_aperture_widths, 0.95));
  // setMeanHeight(stats.mean(calculated_aperture_heights));
  // setStdHeight(stats.stdev(calculated_aperture_heights));
  // setPercentHeight(stats.percentile(calculated_aperture_heights, 0.95));

  const w_mean = stats.mean(calculated_aperture_widths)
  const w_std = stats.stdev(calculated_aperture_widths)
  const w_p95 = stats.percentile(calculated_aperture_widths, 0.95)
  const w_min = Math.min(...calculated_aperture_widths)
  const w_max = Math.max(...calculated_aperture_widths)

  const h_mean = stats.mean(calculated_aperture_heights)
  const h_std = stats.stdev(calculated_aperture_heights)
  const h_p95 = stats.percentile(calculated_aperture_heights, 0.95)
  const h_min = Math.min(...calculated_aperture_heights)
  const h_max = Math.max(...calculated_aperture_heights)

  const a_sum = stats.sum(calculated_areas)

  const per_oa = (a_sum / (Panel_Size_W * Panel_Size_H)) * 100

  // console.log('mean (w):', w_mean)
  // console.log('std (w):', w_std)
  // console.log('per (w):', w_p95)
  // console.log('min (w):', w_min)
  // console.log('max (w):', w_max)
  // console.log('a_sum:', a_sum)

  // console.log('mean (h):', stats.mean(calculated_aperture_heights))
  // console.log('w:', calculated_aperture_widths)

  // console.log('std (h):', stats.stdev(calculated_aperture_heights))
  // console.log('per (h):', stats.percentile(calculated_aperture_heights, 0.95))

  // const srccc = cv.imread("template_image");
  // cv.resize(srccc, srccc, new cv.Size(0, 0), 0.3, 0.3);
  // const srcc1 = new cv.Mat(src.rows, src.cols, cv.CV_8UC3)
  // cv.cvtColor(src, srcc1, cv.COLOR_BGRA2BGR);
  // const areas = results.map((val) => val.width * val.height)
  // const max_area = Math.max.apply(null, calculated_areas)

  const maxWidth = Math.max.apply(null, calculated_aperture_widths)
  // const minWidth = Math.min.apply(null, calculated_aperture_widths)

  for (let i = 0; i < results.length; i++) {
    const pts = results[i]['pts']
    const width = calculated_aperture_widths[i]

    let box_area = 0
    if (width > base_width) {
      box_area = (width - base_width) / (maxWidth - base_width)
    } else {
      box_area = 1
    }
    // console.log('box_area:', box_area)

    const scalex = 1 / (pix_to_mm_w * TPL_SCALE)
    const scaley = 1 / (pix_to_mm_h * TPL_SCALE)

    fill_image(
      src_org,
      [
        new cv.Point(pts[0] * scalex, pts[1] * scaley),
        new cv.Point(pts[2] * scalex, pts[3] * scaley),
      ],
      box_area
    )
  }

  for (let i = 0; i < results.length; i++) {
    // console.log('pos:', results[i]['pts'])
    const centx = (results[i]['pts'][0] + results[i]['pts'][2]) / 2
    const centy = (results[i]['pts'][1] + results[i]['pts'][3]) / 2
    // const centx2 = results[i]['pts'][2] //+ results[i]['pts'][2]) / 2
    // const centy2 = results[i]['pts'][3] //+ results[i]['pts'][3]) / 2
    const scalex = 1 / (pix_to_mm_w * TPL_SCALE)
    const scaley = 1 / (pix_to_mm_h * TPL_SCALE)
    const width = (results[i]['pts'][2] - results[i]['pts'][0]) * borderx
    const height = (results[i]['pts'][3] - results[i]['pts'][1]) * bordery

    let pt1 = new cv.Point(
      (centx - width / 2) * scalex,
      (centy - height / 2) * scaley
    )
    let pt2 = new cv.Point(
      (centx + width / 2) * scalex,
      (centy + height / 2) * scaley
    )
    let color = new cv.Scalar(0, 255, 0)
    cv.rectangle(src_org2, pt1, pt2, color, 8)

    pt1 = new cv.Point((centx - width / 2) * scalex, centy * scaley)
    pt2 = new cv.Point((centx + width / 2) * scalex, centy * scaley)
    color = new cv.Scalar(0, 255, 0)
    cv.line(src_org2, pt1, pt2, color, 8)

    pt1 = new cv.Point(centx * scalex, (centy - height / 2) * scaley)
    pt2 = new cv.Point(centx * scalex, (centy + height / 2) * scaley)
    color = new cv.Scalar(0, 255, 0)
    cv.line(src_org2, pt1, pt2, color, 8)
  }

  // console.log('cent, pts:', calculated_aperture_centroids, calculated_pts_mm)

  const dl_res = {
    panel: panel,
    template_coords: template_coords,
    centroids: calculated_aperture_centroids,
    pts: calculated_pts_mm,
    widths: calculated_aperture_widths,
    areas: calculated_areas,
  }

  // if (process.env.REACT_APP_TESTING) {
  //   console.log('** RUN PYTHON ANALYSIS **')
  //   const options = {
  //     mode: 'json',
  //     pythonOptions: ['-u'], // get print results in real-time
  //     scriptPath: '../../test/',
  //     args: ['value1', 'value2', 'value3'],
  //   } as Options

  //   PythonShell.run('result_analysis.py', options, function(err, results) {
  //     if (err) throw err
  //     // results is an array consisting of messages collected during execution
  //     console.log('results: %j', results)
  //   })
  // } else {

  const dataStr =
    'duta:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(dl_res))
  const dlAnchorElem = document.getElementById('downloadAnchorElem')
  dlAnchorElem?.setAttribute('href', dataStr)
  dlAnchorElem?.setAttribute('download', 'scene.json')
  dlAnchorElem?.click()

  cv.cvtColor(src_org, src_org, cv.COLOR_RGB2BGR)
  cv.cvtColor(src_org2, src_org2, cv.COLOR_RGB2BGR)
  cv.cvtColor(src_org3, src_org3, cv.COLOR_RGB2BGR)

  return {
    result: src_org,
    result2: src_org2,
    result3: src_org3,
    results: {
      template_coords,
      w_mean,
      w_std,
      w_p95,
      w_min,
      w_max,
      h_mean,
      h_std,
      h_p95,
      h_min,
      h_max,
      per_oa,
    },
  }
}
