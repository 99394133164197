import createSagaMiddleware from 'redux-saga'
import { applyMiddleware, createStore, Middleware } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import { PersistConfig } from 'redux-persist/es/types'
// @ts-ignore
import storage from 'redux-persist/lib/storage'
import { appReducer } from './appReducers'
import { appSagas } from './appSagas'
import { createLogger } from 'redux-logger'

const logger = createLogger()

const persistConfig: PersistConfig<any> = {
  key: 'wearapp',
  storage,
  debug: true,
  // is the user is on a desktop, we can save the library and review process in store
  whitelist: ['panelTypes', 'failureModes', 'auth'],
}

export const configureStore = (initialState = {}): Record<string, any> => {
  const sagaMiddleware = createSagaMiddleware()
  const middlewares: Array<Middleware> = [sagaMiddleware]
  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger)
  }
  const reducer: any = appReducer()
  const persistedReducers = persistReducer(persistConfig, reducer)
  const store = createStore(
    persistedReducers,
    initialState,
    applyMiddleware(...middlewares)
  )
  const persistor = persistStore(store)
  sagaMiddleware.run(appSagas)
  return { store, persistor }
}
