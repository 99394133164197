import { Grid, TextField, Typography } from '@material-ui/core'
import React, { FunctionComponent, useEffect, useState } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  textField: {
    width: '100%',
  },
})

type PanelRemainingLifeProps = {
  onDataChanged: (key: string, value?: string | number) => void
}

export const PanelRemainingLife: FunctionComponent<PanelRemainingLifeProps> = ({
  onDataChanged,
}) => {
  const [panelInstallDate, setPanelInstallDate] = useState<Date | null>(null)
  const [nextShutDownDate, setNextShutDownDate] = useState<Date | null>(null)
  const [safetyDepth, setSafetyDepth] = useState<string>('')

  useEffect(() => {
    onDataChanged('panelInstallDate', panelInstallDate?.getTime())
  }, [panelInstallDate])

  useEffect(() => {
    onDataChanged('nextShutDownDate', nextShutDownDate?.getTime())
  }, [nextShutDownDate])

  useEffect(() => {
    onDataChanged('safetyDepth', safetyDepth)
  }, [safetyDepth])

  const styles = useStyles()
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container item direction='column'>
        <Grid item>
          <Typography variant='h6'>Panel Remaining Life</Typography>
        </Grid>
        <Grid item>
          <KeyboardDatePicker
            disableToolbar
            variant='inline'
            format='dd/MM/yyyy'
            margin='normal'
            label='Panel Install Date'
            value={panelInstallDate}
            onChange={setPanelInstallDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <Grid item>
          <KeyboardDatePicker
            disableToolbar
            variant='inline'
            format='dd/MM/yyyy'
            margin='normal'
            label='Next Shutdown Date'
            value={nextShutDownDate}
            onChange={setNextShutDownDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            onChange={(event) => setSafetyDepth(event.target.value)}
            className={styles.textField}
            label='Safety Depth (mm)'
            value={safetyDepth}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}
