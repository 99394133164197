import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { SET_PICTURE_DATA } from '../../store/misc/miscActions'
import Webcam from 'react-webcam'
import { Fab } from '@material-ui/core'
import { ArrowBack, CameraAlt, FlipCameraAndroid } from '@material-ui/icons'
import { useSetShowBackButton } from '../../hooks/useSetShowBackButton'
import { RESET_ANALYSIS } from '../../store/analysis/analysisActions'

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
)

const videoConstraints: MediaStreamConstraints['video'] = {
  width: { ideal: 3200 },
  height: { ideal: 2400 },
  aspectRatio: 4 / 3,
}

export const Camera: FunctionComponent = () => {
  const camera = useRef<Webcam | null>(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useSetShowBackButton(false)
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>(
    !isMobile ? 'user' : 'environment'
  )

  const setPicture = (picture: string) => {
    dispatch({
      type: String(SET_PICTURE_DATA),
      payload: {
        currentPhoto: {
          picture,
        },
      },
    })
    dispatch({
      type: String(RESET_ANALYSIS),
    })
    navigate('/dashboard/preview', { state: { from: 'capture' } })
  }

  useEffect(() => {
    dispatch({
      type: String(SET_PICTURE_DATA),
      payload: {
        currentPhoto: {
          picture: undefined,
        },
      },
    })
  }, [])

  const takePicture = () => {
    if (!camera.current) {
      return
    }
    const imageSrc = camera.current?.getScreenshot()
    if (imageSrc) {
      setPicture(imageSrc)
    }
  }

  const onSwitchCameraPressed = () => {
    setFacingMode((current) => {
      return current === 'user' ? 'environment' : 'user'
    })
  }

  const onBackPressed = () => {
    navigate(-1)
  }

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Webcam
        style={{
          width: '100%',
          height: '90%',
        }}
        audio={false}
        ref={camera}
        forceScreenshotSourceSize
        screenshotQuality={1}
        screenshotFormat='image/png'
        videoConstraints={{
          ...videoConstraints,
          facingMode: {
            ideal: facingMode,
          },
        }}
      />
      <div
        style={{
          display: 'flex',
          width: '100vw',
          maxHeight: '8em',
          paddingBottom: '.5em',
          justifyContent: 'space-around',
          alignItems: 'center',
          paddingTop: '.5em',
          backgroundColor: 'rgba(0,0,0, .3)',
          position: 'static',
          top: 0,
          height: '10%',
        }}
      >
        {isMobile && (
          <Fab
            onClick={onSwitchCameraPressed}
            size='small'
            style={{ marginRight: '1em' }}
          >
            <FlipCameraAndroid color='secondary' />
          </Fab>
        )}
        <Fab onClick={takePicture}>
          <CameraAlt color='secondary' />
        </Fab>
        <Fab size='small' onClick={onBackPressed}>
          <ArrowBack color='secondary' />
        </Fab>
      </div>
    </div>
  )
}
