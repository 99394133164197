import {
  FunctionComponent,
  ReactNode,
  useContext,
  useLayoutEffect,
  useRef,
} from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { WearAppContext } from '../context/WearAppContext'

type WearAppDialogProps = {
  open: boolean
  title: string
  actions?: ReactNode
  onClose: () => void
  isFullWidth?: boolean
  isFullHeight?: boolean
}

export const WearAppDialog: FunctionComponent<WearAppDialogProps> = ({
  title,
  open,
  onClose,
  isFullWidth = true,
  actions,
  isFullHeight = true,
  children,
}) => {
  const { setCurrentDialogDimension } = useContext(WearAppContext)

  const dialogRef = useRef<HTMLDivElement>()

  useLayoutEffect(() => {
    const intervalId = setInterval(() => {
      if (!dialogRef.current) {
        return
      }
      setCurrentDialogDimension({
        width: dialogRef.current.offsetWidth,
        height: dialogRef.current.offsetHeight,
      })
      clearInterval(intervalId)
    })
    return () => clearInterval(intervalId)
  }, [setCurrentDialogDimension])

  const onCloseCallback = (_: unknown, closeType: string) => {
    if (closeType === 'backdropClick') {
      return
    }
    onClose()
  }

  return (
    <Dialog
      onClose={onCloseCallback}
      ref={dialogRef}
      open={open}
      fullWidth={isFullWidth}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ height: isFullHeight ? '30vh' : 'auto' }}>
        {children}
      </DialogContent>
      {actions && (
        <DialogActions style={{ marginTop: '2em', zIndex: 100000 }}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  )
}
