import { FunctionComponent } from 'react'

type DrawerItemProps = {
  title: string
  isGray?: boolean
}

export const DrawerItem: FunctionComponent<DrawerItemProps> = ({
  title,
  isGray,
  children,
}) => {
  return (
    <div
      style={{
        color: 'black',
        fontWeight: 'bold',
        backgroundColor: isGray ? 'lightgray' : 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '0px 16px',
        justifyItems: 'center',
      }}
    >
      <p>{title}</p>
      {children}
    </div>
  )
}
