import React, { FunctionComponent, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Library, Review, SiteDetail, WearAppState } from '../../store/types'
import { makeStyles } from '@material-ui/core/styles'
import { Close, CloudUpload } from '@material-ui/icons'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { CLEAR_SELECTION } from '../../store/misc/miscActions'
import {
  PUSH_REVIEWED_LIBRARY,
  PUSH_REVIEWED_LIBRARY_SUCCESS,
} from '../../store/reviewLibrary/reviewLibraryActions'
import moment from 'moment'
import { WearAppContext } from '../../context/WearAppContext'

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > button': {
      height: '100%',
    },
  },
  innerContainer: {
    height: '100%',
    display: 'flex',
    marginRight: '1em',
    alignItems: 'center',
    '& > button': {
      height: '100%',
    },
  },
})

export const SelectedItems: FunctionComponent = () => {
  const selectedFiles = useSelector(
    (state: WearAppState) => state.misc?.multiSelection?.items ?? []
  )
  const reviewType = useSelector(
    (state: WearAppState) => state.misc?.multiSelection?.reviewType
  )
  const reviewImages = useSelector((state: WearAppState) =>
    reviewType ? state.reviewLibrary[reviewType] : []
  ) as Array<Review>
  const dispatch = useDispatch()
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false)
  const firstName = useSelector(
    (state: WearAppState) => state.auth?.user?.firstName
  )
  const lastName = useSelector(
    (state: WearAppState) => state.auth?.user?.lastName
  )
  const { uploadPhotos } = useContext(WearAppContext)
  const navigate = useNavigate()
  const clearSelection = () => {
    dispatch({
      type: String(CLEAR_SELECTION),
    })
  }

  const toggleShowUploadDialog = () => {
    setShowUploadDialog(!showUploadDialog)
  }

  const uploadImages = () => {
    toggleShowUploadDialog()
    if (!reviewType) {
      const time = moment()
      const timeZone = moment.tz(moment.tz.guess())
      const files = selectedFiles.reduce<
        Array<{ file: string; library: Library }>
      >((files, file) => {
        // @ts-ignore
        const library: Library = {
          siteDetail: {} as SiteDetail,
          panelType: undefined,
          source: 'upload',
          id: time.valueOf(),
          timeStamps: {
            createdAtTimeZone: timeZone
              .add(1, 'second')
              .format('YYYY-MM-DDTHH:mm:sszz'),
            createdAtUTC: time
              .utc()
              .add(1, 'second')
              .format(),
            updatedAtTimeZone: timeZone
              .add(1, 'second')
              .format('YYYY-MM-DDTHH:mm:sszz'),
            updatedAtUTC: time
              .utc()
              .add(1, 'second')
              .format(),
          },
          failureModes: [],
          userName: firstName + ' ' + lastName,
          submittedBy: firstName + ' ' + lastName,
        }
        files.push({
          file,
          library,
        })

        return files
      }, [])
      uploadPhotos(files)
      navigate('/dashboard')
    } else {
      const libraries = reviewImages
        .filter((review) => selectedFiles.includes(review.imageS3Key))
        .map(({ image, label, labelS3Key, imageS3Key, readyForReview }) => {
          dispatch({
            type: String(PUSH_REVIEWED_LIBRARY_SUCCESS),
            payload: {
              update: { library: { image, label, imageS3Key }, reviewType },
            },
          })
          return {
            labelS3Key,
            imageS3Key,
            ...label,
            image,
            readyForReview,
          }
        })
      dispatch({
        type: String(PUSH_REVIEWED_LIBRARY),
        payload: {
          libraries,
          reviewType,
        },
      })
    }
    clearSelection()
    if (reviewImages.length === 0) {
      navigate(-1)
    }
  }

  const styles = useStyles()

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <span style={{ marginRight: '.8em' }}>{selectedFiles.length}</span>
        <Button>
          <CloudUpload onClick={toggleShowUploadDialog} />
        </Button>
      </div>
      <Button>
        <Close onClick={clearSelection} />
      </Button>

      <Dialog
        open={showUploadDialog}
        // @ts-ignore
        TransitionComponent={Transition}
        keepMounted
        onClose={toggleShowUploadDialog}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          Upload {`${selectedFiles.length === 1 ? 'image' : 'images'}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            variant='caption'
            id='alert-dialog-slide-description'
          >
            Are you sure you want to upload the selected{' '}
            {`${selectedFiles.length === 1 ? 'image' : 'images'}`}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={uploadImages}>Yes</Button>
          <Button style={{ color: 'red' }} onClick={toggleShowUploadDialog}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
