import './App.scss'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { SplashScreen } from './components/SplashScreen'
import { Main } from './components/Main'
import { useShouldFetchData } from './hooks/useShouldFetchData'
import { useAuthentication } from './hooks/useAuthentication'

const App: FunctionComponent = () => {
  const hotjarSiteId = process.env.REACT_APP_HOT_JAR_ID
  const shouldFetchData = useShouldFetchData()
  const [showSplashScreen, setShowSplashScreen] = useState<boolean>(false)

  useEffect(() => {
    setShowSplashScreen(window.localStorage.getItem('show-splash') !== 'false')
    //setShowSplashScreen(performance.navigation.type !== 1)
  }, [])

  useAuthentication()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.localStorage.setItem('show-splash', 'false')
      setShowSplashScreen(false)
    }, 7000)

    return () => clearTimeout(timeoutId)
  }, [shouldFetchData])

  return (
    <>
      <Helmet async>
        <script>
          {`(function(h,o,t,j,a,r){
                            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                            h._hjSettings={hjid:${hotjarSiteId},hjsv:6};
                            a=o.getElementsByTagName('head')[0];
                            r=o.createElement('script');r.async=1;
                            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                            a.appendChild(r);
                        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`}
        </script>
      </Helmet>
      {showSplashScreen ? <SplashScreen /> : <Main />}
    </>
  )
}

export default App
