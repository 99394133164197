const cap_map = [
  [0.75, 2.2],
  [1.0, 3.2],
  [1.5, 4.6],
  [2, 6.13],
  [2.5, 7.25],
  [3.0, 8.32],
  [3.5, 9.36],
  [4, 10.36],
  [5, 12.27],
  [6, 14.05],
  [7, 15.71],
  [8, 17.25],
  [9, 18.68],
  [10, 20.01],
  [11, 21.25],
  [12, 22.39],
  [13, 23.45],
  [14, 24.44],
  [15, 25.36],
  [16, 26.21],
  [17, 27.01],
  [18, 27.76],
  [19, 28.46],
  [20, 29.13],
  [21, 29.77],
  [22, 30.39],
  [23, 30.99],
  [24, 31.58],
  [25, 32.16],
  [26, 32.75],
  [27, 33.35],
  [28, 33.96],
  [29, 34.6],
  [30, 35.27],
  [40, 42.22],
  [50, 48.22],
  [60, 53.74],
  [70, 58.9],
  [80, 63.77],
  [90, 68.4],
  [100, 72.82],
  [110, 77.07],
  [120, 81.17],
  [130, 85.13],
  [140, 88.96],
  [150, 92.69],
  [160, 96.32],
  [170, 99.86],
  [180, 103.31],
  [190, 106.69],
  [200, 109.99],
]

const eff_factor = [
  [0.6, 2.2],
  [0.65, 2.01],
  [0.7, 1.8],
  [0.75, 1.59],
  [0.8, 1.38],
  [0.81, 1.34],
  [0.82, 1.3],
  [0.83, 1.26],
  [0.84, 1.22],
  [0.85, 1.18],
  [0.86, 1.14],
  [0.87, 1.11],
  [0.88, 1.07],
  [0.89, 1.03],
  [0.9, 1.01],
  [0.91, 0.99],
  [0.92, 0.98],
  [0.93, 0.97],
  [0.94, 0.96],
  [0.95, 0.94],
  [0.96, 0.92],
  [0.97, 0.89],
  [0.98, 0.85],
  [0.99, 0.79],
  [1, 0.7],
]

const eff_ratio_eff = [
  [27.2727272727273, 0.6],
  [32.398753894081, 0.65],
  [38.8888888888889, 0.7],
  [47.2069236821401, 0.75],
  [57.9710144927537, 0.8],
  [60.4834193293061, 0.81],
  [63.1313131313132, 0.82],
  [65.9216723454615, 0.83],
  [68.8614900314797, 0.84],
  [71.957671957672, 0.85],
  [75.2169045619928, 0.86],
  [78.645489635971, 0.87],
  [82.2491401226259, 0.88],
  [86.0327311042157, 0.89],
  [89.1089108910891, 0.9],
  [91.9191919191919, 0.91],
  [93.8775510204082, 0.92],
  [95.8762886597938, 0.93],
  [97.9166666666667, 0.94],
  [101.063829787234, 0.95],
  [104.918032786885, 0.96],
  [108.988764044944, 0.97],
  [115.976331360947, 0.98],
  [125.634517766497, 0.99],
  [142.450142450142, 1],
]

const ORIGINAL_SCREEN_EFF = 0.95

type EfficiencyCalcParams = {
  screenType:
    | 'Sizing: Dry, Wet, Scalping, etc'
    | 'Deslime, Drain & Rinse'
    | 'Dewatering'
  openArea: number
  originOpenArea: number
  particleType: string
  originalApertureWidth: number
  apertureWidth: number
}

export type EfficiencyCalcResults = {
  originalApertureWidth: number
  measuredApertureWidth: number
  originalPanelOpenArea: number
  measuredPanelOpenArea: number
  calculatedEfficiency: number
}

export const calculateEfficiency = ({
  screenType,
  apertureWidth,
  openArea,
  originOpenArea,
  originalApertureWidth,
  particleType,
}: EfficiencyCalcParams): EfficiencyCalcResults => {
  let efficiency = undefined
  if (screenType === 'Sizing: Dry, Wet, Scalping, etc') {
    efficiency = calc_efficiency_sizing(
      originalApertureWidth,
      apertureWidth,
      originOpenArea,
      openArea
    )
  } else if (screenType === 'Deslime, Drain & Rinse') {
    efficiency = calc_efficiency_deslime(openArea, originOpenArea, particleType)
  } else {
    efficiency = calc_efficiency_dewater(openArea, originOpenArea, particleType)
  }

  return {
    originalApertureWidth,
    measuredApertureWidth: apertureWidth,
    calculatedEfficiency: efficiency,
    measuredPanelOpenArea: openArea,
    originalPanelOpenArea: originOpenArea,
  }
}

// *** INPUTS ***
// FOR <DESLIME, DRAIN & RINSE> SCREENING TYPE ONLY
// org_screen_eff -> Original screen efficiency -> From UI
// particle_type -> One of: "Coal", "Coal Trash", "Gold Trash",
//   "Oil Sand Fines", "Oil Sand Trash", "Dense Ore Fines", "Water",
//   "Stone, crushed"
const calc_efficiency_deslime = (
  open_area: number,
  org_open_area: number,
  particle_type: string
): number => {
  // org_open_area *= 100
  // open_area *= 100

  const particle_types: { [key: string]: number } = {}
  particle_types['Coal'] =
    0.0075 * org_open_area ** 3 -
    0.1353 * org_open_area ** 2 +
    5.2822 * org_open_area -
    11.437

  particle_types['Fine Trash'] =
    0.0054 * org_open_area ** 3 -
    0.0054 * org_open_area ** 2 +
    2.115 * org_open_area +
    18.168

  particle_types['Course Trash'] =
    0.0054 * org_open_area ** 3 -
    0.0054 * org_open_area ** 2 +
    2.115 * org_open_area +
    18.168

  particle_types['Iron Ore Course'] = particle_types['Course Trash']
  particle_types['Oil Sand Trash'] = particle_types['Course Trash']
  particle_types['Iron Ore Fine'] = particle_types['Course Trash']
  particle_types['Water'] = 22.17 * Math.exp(0.0814 * org_open_area)
  particle_types['Stone, crushed'] = particle_types['Course Trash']

  const particle_types2: { [key: string]: number } = {}
  particle_types2['Coal'] =
    0.0075 * open_area ** 3 -
    0.1353 * open_area ** 2 +
    5.2822 * open_area -
    11.437

  particle_types2['Fine Trash'] =
    0.0054 * open_area ** 3 -
    0.0054 * open_area ** 2 +
    2.115 * open_area +
    18.168

  particle_types2['Course Trash'] =
    0.0054 * open_area ** 3 -
    0.0054 * open_area ** 2 +
    2.115 * open_area +
    18.168

  particle_types2['Iron Ore Course'] = particle_types2['Course Trash']
  particle_types2['Oil Sand Trash'] = particle_types2['Course Trash']
  particle_types2['Iron Ore Fine'] = particle_types2['Course Trash']
  particle_types2['Water'] = 22.17 * Math.exp(0.0814 * open_area)
  particle_types2['Stone, crushed'] = particle_types2['Course Trash']

  const type_keys = Object.keys(particle_types)

  let i = 0
  for (i = 0; i < type_keys.length - 1; i++) {
    if (type_keys[i] === particle_type) {
      break
    }
  }
  // i--
  const drain_rate0 = particle_types[type_keys[i]]

  for (i = 0; i < type_keys.length - 1; i++) {
    if (type_keys[i] === particle_type) {
      break
    }
  }
  // i--
  const drain_rate1 = particle_types2[type_keys[i]]

  const measured_eff = (drain_rate1 * ORIGINAL_SCREEN_EFF) / drain_rate0

  return (measured_eff - ORIGINAL_SCREEN_EFF) * 100
}

// *** INPUTS ***
// FOR <DEWATERING> SCREENING TYPE ONLY
// org_screen_eff -> Original screen efficiency -> From UI
// particle_type -> One of: "Coal", "Coal Trash", "Gold Trash",
//   "Oil Sand Fines", "Oil Sand Trash", "Dense Ore Fines", "Water",
//   "Stone, crushed"
const calc_efficiency_dewater = (
  open_area: number,
  org_open_area: number,
  particle_type: string
): number => {
  const particle_types: { [key: string]: number } = {}
  particle_types['Coal'] =
    0.0075 * org_open_area ** 3 -
    0.1353 * org_open_area ** 2 +
    5.2822 * org_open_area -
    11.437 / 2
  particle_types['Coal Trash'] =
    0.0054 * org_open_area ** 3 -
    0.0054 * org_open_area ** 2 +
    2.115 * org_open_area +
    18.168
  particle_types['Gold Trash'] =
    0.0054 * org_open_area ** 3 -
    0.0054 * org_open_area ** 2 +
    2.115 * org_open_area +
    18.168
  particle_types['Oil Sand Fines'] = particle_types['Gold Trash'] * 0.7
  particle_types['Oil Sand Trash'] = particle_types['Gold Trash']
  particle_types['Dense Ore Fines'] = particle_types['Gold Trash'] * 0.5
  particle_types['Water'] = 22.17 * Math.exp(0.0814 * org_open_area)
  particle_types['Stone, crushed'] = particle_types['Gold Trash']

  const particle_types2: { [key: string]: number } = {}
  particle_types2['Coal'] =
    0.0075 * open_area ** 3 -
    0.1353 * open_area ** 2 +
    5.2822 * open_area -
    11.437 / 2
  particle_types2['Coal Trash'] =
    0.0054 * open_area ** 3 -
    0.0054 * open_area ** 2 +
    2.115 * open_area +
    18.168
  particle_types2['Gold Trash'] =
    0.0054 * open_area ** 3 -
    0.0054 * open_area ** 2 +
    2.115 * open_area +
    18.168
  particle_types2['Oil Sand Fines'] = particle_types2['Gold Trash'] * 0.7
  particle_types2['Oil Sand Trash'] = particle_types2['Gold Trash']
  particle_types2['Dense Ore Fines'] = particle_types2['Gold Trash'] * 0.5
  particle_types2['Water'] = 22.17 * Math.exp(0.0814 * open_area)
  particle_types2['Stone, crushed'] = particle_types2['Gold Trash']

  const type_keys = Object.keys(particle_types)

  let i = 0
  for (i = 0; i < type_keys.length - 1; i++) {
    if (type_keys[i] === particle_type) {
      break
    }
  }
  // i--
  const drain_rate0 = particle_types[type_keys[i]]

  for (i = 0; i < type_keys.length - 1; i++) {
    if (type_keys[i] === particle_type) {
      break
    }
  }
  // i--
  const drain_rate1 = particle_types2[type_keys[i]]

  const measured_eff = (drain_rate1 * ORIGINAL_SCREEN_EFF) / drain_rate0
  return (measured_eff - ORIGINAL_SCREEN_EFF) * 100
}

// *** INPUTS ***
// FOR <DEWATERING> SCREENING TYPE ONLY
// org_ap_w -> Original aperture width -> from state panel.Aperture_Size_W1
// ap_w -> mean Aperture width -> from WearApp calculation -> w_mean
// org_open_area -> Original Panel Open Area -> From UI
// open_area -> Open Area -> from WearApp Calculation -> per_oa
// org_screen_eff -> Original screen efficiency -> CONSTANT: 95%
const calc_efficiency_sizing = (
  org_ap_w: number,
  ap_w: number,
  org_op_area: number,
  op_area: number
): number => {
  let i = 0
  for (i = 0; i < cap_map.length - 1; i++) {
    if (cap_map[i][0] > org_ap_w) {
      break
    }
  }
  i--
  let size_0 = cap_map[i][0]
  let cap_0 = cap_map[i][1]
  let size_1 = cap_map[i + 1][0]
  let cap_1 = cap_map[i + 1][1]

  const org_cap_res =
    (((cap_0 - cap_1) / (size_0 - size_1)) * (org_ap_w - size_1) + cap_1) / 100
  // console.log("cap_0:", i, cap_map[i], org_cap_res)

  for (i = 0; i < cap_map.length - 1; i++) {
    if (cap_map[i][0] > ap_w) {
      break
    }
  }
  i--
  size_0 = cap_map[i][0]
  cap_0 = cap_map[i][1]
  size_1 = cap_map[i + 1][0]
  cap_1 = cap_map[i + 1][1]
  const cap_res =
    (((cap_0 - cap_1) / (size_0 - size_1)) * (ap_w - size_1) + cap_1) / 100
  // console.log("cap_1:", i, cap_map[i], cap_res)

  for (i = 1; i < eff_factor.length - 1; i++) {
    if (eff_factor[i][0] > ORIGINAL_SCREEN_EFF) {
      break
    }
  }
  i--
  const eff_factor_num = eff_factor[i][0]
  const eff_factor_ratio = eff_ratio_eff[i][0]
  const eff_factor_num_next = eff_factor[i + 1][0]
  const eff_factor_ratio_next = eff_ratio_eff[i + 1][0]

  const interp_eff =
    ((eff_factor_ratio - eff_factor_ratio_next) /
      (eff_factor_num - eff_factor_num_next)) *
      (ORIGINAL_SCREEN_EFF - eff_factor_num_next) +
    eff_factor_ratio_next

  // console.log("INTERP EFF:", i, eff_factor_num, eff_factor_num_next, interp_eff)

  const aux_eff_factor_ratio =
    (interp_eff * cap_res * op_area) / (org_cap_res * org_op_area)

  for (i = 1; i < eff_ratio_eff.length - 1; i++) {
    if (eff_ratio_eff[i][0] > aux_eff_factor_ratio) {
      break
    }
  }
  i--

  const eff_factor_num2 = eff_ratio_eff[i][0]
  const eff_factor_ratio2 = eff_factor[i][0]
  const eff_factor_num_next2 = eff_ratio_eff[i + 1][0]
  const eff_factor_ratio_next2 = eff_factor[i + 1][0]

  const interp_eff2 =
    ((eff_factor_ratio2 - eff_factor_ratio_next2) /
      (eff_factor_num2 - eff_factor_num_next2)) *
      (aux_eff_factor_ratio - eff_factor_num_next2) +
    eff_factor_ratio_next2

  // console.log("INTERP EFF2:", i, eff_factor_num2, eff_factor_num_next2, interp_eff2)

  // eff_diff is the Change in Screening Efficiency that is shown on the UI
  return (interp_eff2 - ORIGINAL_SCREEN_EFF) * 100
}
