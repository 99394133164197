import { useState, FunctionComponent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { WearAppState } from '../../../store/types'
import { WearAppDialog } from '../../WearAppDialog'
import { WearAppAutoComplete } from '../../WearAppAutoComplete'
import { Button } from '@material-ui/core'
import { useFetchMiscData } from '../../../hooks/useFetchMiscData'

type PanelTypesProps = {
  onValueSelected: (value: string) => void
  selectedValue: string
  onClose: () => void
}

export const PanelTypes: FunctionComponent<PanelTypesProps> = ({
  onValueSelected,
  selectedValue,
  onClose,
}) => {
  const [open, setOpen] = useState(true)
  const panelTypes = useSelector(
    (store: WearAppState) => store.panelTypes.panelTypes ?? {}
  )
  console.log('panelTypes', panelTypes)
  const isFetching = useSelector(
    (store: WearAppState) => store.panelTypes.isFetching
  )
  const [value, setValue] = useState<string>('')
  useFetchMiscData()

  useEffect(() => {
    if (!selectedValue) {
      return
    }
    setValue(() => {
      for (const key in panelTypes) {
        if (panelTypes[key] === selectedValue) {
          return key
        }
      }
      return ''
    })
  }, [])

  const values = Object.keys(panelTypes)

  const handleClose = () => {
    onValueSelected(panelTypes[value])
    setOpen(false)
    onClose()
  }

  const onCancel = () => {
    setOpen(false)
    onClose()
  }

  return (
    <WearAppDialog
      open={open}
      title='Select Panel Type'
      onClose={handleClose}
      actions={
        <>
          <Button onClick={onCancel}>
            <span style={{ color: 'purple', marginTop: 'auto' }}>Cancel</span>
          </Button>
          <Button onClick={handleClose} disabled={value?.length === 0}>
            <span style={{ marginTop: 'auto' }}>OK</span>
          </Button>
        </>
      }
    >
      <WearAppAutoComplete
        isLoading={isFetching}
        selected={value}
        setSelectedValues={(value) => setValue(value as string)}
        options={values}
        multiple={false}
        textInputLabel='Material Type'
        onCancel={onCancel}
        renderOption={(item) => <span>{item as string}</span>}
        // getOptionSelected={(option) => option.M === value.M}
      />
    </WearAppDialog>
  )
}
