// @ts-nocheck
import React, {
  FunctionComponent,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import {
  AppBar,
  Toolbar,
  makeStyles,
  useTheme,
  useMediaQuery,
  Button,
} from '@material-ui/core'
import { DrawerComponent } from './Drawer'
import { useWindowSize } from 'react-use'
import { WearAppContext } from '../../context/WearAppContext'
import logo from '../../assets/schenck_logo.png'
import { Menu } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import { WearAppState } from '../../store/types'
import { SelectedItems } from './SelectedItems'
import { ActionMenuItems } from './ActionMenuItems'

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(5),
    display: 'flex',
    zIndex: 100000,
  },
  logo: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: '1',
    zIndex: 100000,
    height: '100%',
  },
  link: {
    textDecoration: 'none',
    zIndex: 100000,
    color: 'white',
    fontSize: '20px',
    marginLeft: theme.spacing(20),
    '&:hover': {
      color: 'yellow',
      borderBottom: '1px solid white',
    },
  },
}))

const Navbar: FunctionComponent = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { setAppBarDimension } = useContext(WearAppContext)
  const { width, height } = useWindowSize()

  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const selectedFiles = useSelector(
    (state: WearAppState) => state.misc?.multiSelection?.items ?? []
  )

  const appBarRef = useRef<HTMLHeadElement>()
  useLayoutEffect(() => {
    if (!appBarRef.current) {
      return
    }
    setAppBarDimension({
      width: appBarRef.current.offsetWidth,
      height: appBarRef.current.offsetHeight,
    })
  }, [width, height, setAppBarDimension])
  //console.log('height', height)
  return (
    <AppBar
      style={{ height: 'calc(100% - 15)' }}
      ref={appBarRef}
      position='static'
    >
      <Toolbar>
        {isMobile ? (
          <>
            <Button onClick={() => setOpenDrawer(true)}>
              <Menu />
            </Button>
            <DrawerComponent open={openDrawer} setOpen={setOpenDrawer} />
          </>
        ) : (
          <ActionMenuItems />
        )}
        <div className={classes.logo}>
          {selectedFiles.length === 0 ? (
            <img alt='logo' height='50px' align='right' src={logo} />
          ) : (
            <SelectedItems />
          )}
        </div>
      </Toolbar>
      {/* <Typography align="center">Wear App Lite</Typography> */}
    </AppBar>
  )
}
export default Navbar
