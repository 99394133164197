import { createAction } from 'redux-actions'

const ns = 'failureModes'

export const FETCH_ALL_FAILURE_MODES = createAction(`${ns}/fetchAll`)
export const FETCH_ALL_FAILURE_MODES_SUCCESS = createAction(
  `${ns}/fetchAllSuccess`
)
export const FETCH_ALL_FAILURE_MODES_FAILURE = createAction(
  `${ns}/fetchAllFailure`
)

export const TOGGLE_IS_FETCHING = createAction(`${ns}/toggleIsFetching`)
