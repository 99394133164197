import React, { FunctionComponent } from 'react'
import { TransformComponent } from 'react-zoom-pan-pinch'
import { useWindowSize } from 'react-use'
import { WearAppTransformProps } from './WearAppTransform'
import { FabricWrapper } from './FabricWrapper'

export const WearAppTransforms: FunctionComponent<WearAppTransformProps> = (
  props
) => {
  const { width } = useWindowSize()

  return (
    <TransformComponent
      contentStyle={{
        justifyContent: 'center',
        height: '100%',
        width,
      }}
    >
      <FabricWrapper {...props} />
    </TransformComponent>
  )
}
