import { fabric } from 'fabric'

export const initFabric = (canvasEl: string): fabric.Canvas => {
  fabric.Object.prototype.selectable = false
  fabric.Object.prototype.hasControls = false
  fabric.Object.prototype.hasBorders = false
  fabric.Group.prototype.selectable = false
  fabric.Group.prototype.hasControls = false
  fabric.Group.prototype.hasBorders = false
  return new fabric.Canvas(canvasEl)
}
