import React, { FunctionComponent } from 'react'
import { WearAppTransformProps } from '../WearAppTransform'
import { useWindowSize } from 'react-use'

type PolygonFabricWrapperProps = {
  openCvCanvasRef: WearAppTransformProps['openCvCanvasRef']
  templateRef: WearAppTransformProps['templateRef']
}

export const PolygonFabricWrapper: FunctionComponent<PolygonFabricWrapperProps> = () => {
  const { width } = useWindowSize()

  return (
    <div>
      <canvas
        id='polygon_canvas'
        width={width}
        style={{
          width: '100% !important',
        }}
      />
    </div>
  )
}
