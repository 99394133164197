// @ts-nocheck
import React, { useState } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import cv from 'opencv-ts'

const FullScreenImagePreview = styled.div<{ image: string | null }>`
  width: 100%;
  height: 50%;
  padding-left: 100%;
  z-index: -1;
  margin: auto;
  position: fixed;
  ${({ image }) => (image ? `background-image:  url(${image});` : '')}
  // background-color: black;
background-size: contain;
  background-repeat: no-repeat;
  background-position: top;

  @media (max-width: 100%) {
    width: 50%;
    height: 50%;
  }
`

const TakePhotoButton = styled.button`
  background: url('https://img.icons8.com/ios/50/000000/compact-camera.png');
  margin: auto;
  align: center;
  background-position: center;
  background-size: 70px;
  background-repeat: no-repeat;
  background-color: rgb(255, 255, 0);
  width: 90px;
  height: 90px;
  border: solid 4px black;
  border-radius: 50%;
  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`

const StartAnalysis = styled.button`
  background: url('https://img.icons8.com/ios/50/000000/power-off-button--v2.png');
  margin: auto;
  align: right;
  background-position: center;
  background-size: 70px;
  background-repeat: no-repeat;
  background-color: rgb(255, 255, 0);
  width: 90px;
  height: 90px;
  border: solid 4px black;
  border-radius: 50%;
  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
  &:disabled {
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const RevertToDraft = styled.button`
  background: url('https://img.icons8.com/ios/50/000000/back-to-draft.png');
  margin: auto;
  align: right;
  background-position: center;
  background-size: 70px;
  background-repeat: no-repeat;
  background-color: rgb(255, 255, 0);
  width: 90px;
  height: 90px;
  border: solid 4px black;
  border-radius: 50%;
  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
  &:disabled {
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: auto;
  z-index: -1;
`

const Control = styled.div`
  position: fixed;
  display: flex;
  right: 0;
  width: 20%;
  min-width: 130px;
  min-height: 130px;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px;
  box-sizing: border-box;
  flex-direction: column-reverse;
  @media (max-aspect-ratio: 1/1) {
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 20%;
  }
  @media (max-width: 400px) {
    padding: 10px;
  }
`

const FailureModeDetection = () => {
  const state = useLocation()
  const navigate = useNavigate()
  console.log('state', state)
  let image = null
  try {
    image = state.state.photo
  } catch (Error) {
    image = null
  }
  const [image_snapped, setImageSnapped] = useState<string | null>(image)
  const old_image = JSON.parse(JSON.stringify(image))
  let canvas, new_image

  return (
    <Wrapper>
      <img id='old_image' src={old_image} hidden></img>
      <canvas className='Image' id='canvas_cv' hidden></canvas>
      <FullScreenImagePreview image={image_snapped}></FullScreenImagePreview>
      <Control>
        <TakePhotoButton
          onClick={() => {
            navigate('/camera')
          }}
        ></TakePhotoButton>
        <StartAnalysis
          disabled={image ? false : true}
          onClick={() => {
            try {
              console.log('old_image = ', old_image)
              const src = cv.imread('old_image')
              const dst = new cv.Mat(src.cols, src.rows, cv.CV_8UC4)
              cv.cvtColor(src, dst, cv.COLOR_BGR2GRAY)
              cv.imshow('canvas_cv', dst)
              canvas = document.getElementById('canvas_cv') as HTMLCanvasElement
              console.log('canvas - ', canvas, document.images)
              new_image = canvas.toDataURL('image/jpeg')
              console.log('comparison = ', new_image === old_image)
              setImageSnapped(new_image)
            } catch (Error) {
              console.log(Error)
              alert(Error)
            }
          }}
        ></StartAnalysis>
        <RevertToDraft
          disabled={image ? false : true}
          onClick={() => {
            setImageSnapped(old_image)
          }}
        ></RevertToDraft>
      </Control>
    </Wrapper>
  )
}

export default FailureModeDetection
