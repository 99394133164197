// @ts-nocheck
import React, { useState } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import cv from 'opencv-ts'

const FullScreenImagePreview = styled.div<{ image: string | null }>`
  width: 100%;
  height: 50%;
  padding-left: 100%;
  z-index: -1;
  margin: auto;
  position: fixed;
  ${({ image }) => (image ? `background-image:  url(${image});` : '')}
  // background-color: black;
background-size: contain;
  background-repeat: no-repeat;
  background-position: top;

  @media (max-width: 100%) {
    width: 50%;
    height: 50%;
  }
`

const TakePhotoButton = styled.button`
  background: url('https://img.icons8.com/ios/50/000000/compact-camera.png');
  margin: auto;
  align: center;
  background-position: center;
  background-size: 70px;
  background-repeat: no-repeat;
  background-color: rgb(255, 255, 0);
  width: 90px;
  height: 90px;
  border: solid 4px black;
  border-radius: 50%;
  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`

const StartAnalysis = styled.button`
  background: url('https://img.icons8.com/ios/50/000000/power-off-button--v2.png');
  margin: auto;
  align: right;
  background-position: center;
  background-size: 70px;
  background-repeat: no-repeat;
  background-color: rgb(255, 255, 0);
  width: 90px;
  height: 90px;
  border: solid 4px black;
  border-radius: 50%;
  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: auto;
  z-index: -1;
`

const Control = styled.div`
  position: fixed;
  display: flex;
  right: 0;
  width: 20%;
  min-width: 130px;
  min-height: 130px;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px;
  box-sizing: border-box;
  flex-direction: column-reverse;
  @media (max-aspect-ratio: 1/1) {
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 20%;
  }
  @media (max-width: 400px) {
    padding: 10px;
  }
`

const WearScanning = () => {
  const state = useLocation()
  const navigate = useNavigate()
  let width = 0,
    height = 0
  try {
    image = state.state.photo
    width = state.state.size_image.width
    height = state.state.size_image.height
    console.log('height = ', height, 'width = ', width)
  } catch (Error) {
    image = null
  }
  const [image_snapped, setImageSnapped] = useState<string | null>(image)
  const old_image = JSON.parse(JSON.stringify(image))
  let canvas, new_image

  return (
    <div>
      <img id='old_image' src={'../template.jpg'} hidden></img>
      <img id='aperture_img' src={'../aperture.jpg'} hidden></img>
      <canvas className='Image' id='canvas_cv' hidden></canvas>
      <Wrapper>
        <FullScreenImagePreview image={image_snapped}></FullScreenImagePreview>
        <Control>
          <TakePhotoButton
            onClick={() => {
              navigate('/camera')
            }}
          ></TakePhotoButton>
          <StartAnalysis
            disabled={image ? false : true}
            onClick={() => {
              try {
                const aperture_one = cv.imread('aperture_img')
                const aperture_one_gray = new cv.Mat(aperture_one)
                cv.cvtColor(aperture_one, aperture_one_gray, cv.COLOR_BGR2GRAY)
                const aperture_one_blur = new cv.Mat(aperture_one)
                cv.medianBlur(aperture_one_gray, aperture_one_blur, 9)
                const aperture_one_threshold = new cv.Mat(aperture_one)
                cv.threshold(
                  aperture_one_blur,
                  aperture_one_threshold,
                  80,
                  255,
                  0
                )

                console.log('old_image = ', old_image, aperture_one)
                const src = cv.imread('old_image')
                console.log('old_image src = ', src.cols, src.rows)
                const dst = new cv.Mat(src.cols, src.rows, cv.CV_8UC4)
                cv.cvtColor(src, dst, cv.COLOR_BGR2GRAY)
                const dst_blur = new cv.Mat(src)
                cv.medianBlur(dst, dst_blur, 15)

                console.log(
                  'result computation = ',
                  dst_blur.rows - aperture_one_blur.rows + 1,
                  dst_blur.cols - aperture_one_blur.cols + 1
                )

                const result = new cv.Mat(
                  dst_blur.rows - aperture_one_blur.rows + 1,
                  dst_blur.cols - aperture_one_blur.cols + 1,
                  cv.CV_8UC4
                )
                const mask = cv.Mat.eye(
                  aperture_one_blur.rows,
                  aperture_one_blur.cols,
                  cv.CV_8UC4
                )

                console.log('before matching template')
                console.log(
                  '-> ',
                  mask.rows,
                  mask.cols,
                  result.rows,
                  result.cols
                )
                try {
                  cv.matchTemplate(
                    dst_blur,
                    aperture_one_blur,
                    result,
                    cv.TM_CCOEFF_NORMED,
                    mask
                  )
                  console.log('result = ', result)
                } catch (Error) {
                  console.log('Error = ', Error)
                }
                console.log('after matching template')
                console.log(result)

                cv.imshow('canvas_cv', dst)
                canvas = document.getElementById(
                  'canvas_cv'
                ) as HTMLCanvasElement
                console.log('canvas - ', canvas, document.images)
                new_image = canvas.toDataURL('image/jpeg')
                console.log('comparison = ', new_image === old_image)
                setImageSnapped(new_image)
              } catch (Error) {
                console.log(Error)
                alert(Error)
              }
            }}
          ></StartAnalysis>
        </Control>
      </Wrapper>
    </div>
  )
}

export default WearScanning
