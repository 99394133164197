import React, { ReactNode, Fragment, FunctionComponent } from 'react'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import { Search } from '@material-ui/icons'
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete/Autocomplete'

type WearAppAutoCompleteProps = {
  isLoading: boolean
  multiple: boolean
  selected: unknown
  setSelectedValues: (selected: unknown) => void
  options: Array<unknown>
  onCancel: () => void
  renderOption: (
    option: unknown,
    state: AutocompleteRenderOptionState
  ) => ReactNode
  textInputLabel: unknown
  freeSolo?: boolean
  open?: boolean
  onOpen?: () => void
  onClose?: () => void
  onInputChange?: (value: string) => void
  disablePortal?: boolean
  maxSelection?: number
  canSearch?: boolean
  // getOptionSelected: (option: any, value: any) => boolean
}

export const WearAppAutoComplete: FunctionComponent<WearAppAutoCompleteProps> = ({
  isLoading,
  selected,
  setSelectedValues,
  options,
  onCancel,
  renderOption,
  multiple,
  textInputLabel,
  freeSolo,
  open,
  onOpen,
  onInputChange,
  onClose,
  disablePortal,
  maxSelection,
  canSearch = true,
}) => {
  const filterOptions = createFilterOptions<unknown>({
    matchFrom: 'any',
    limit: 100,
  })

  return (
    <Autocomplete
      freeSolo={freeSolo}
      filterOptions={filterOptions}
      size='medium'
      multiple={multiple}
      disablePortal={disablePortal ?? true}
      onOpen={onOpen}
      onInputChange={(event, value) => onInputChange && onInputChange(value)}
      value={selected}
      onClose={onClose}
      onChange={(event, newInputValue) => {
        // @ts-ignore
        if (!maxSelection || newInputValue.length - 1 < maxSelection) {
          setSelectedValues(newInputValue)
        }
      }}
      disableCloseOnSelect={false}
      ListboxProps={{
        style: {
          maxHeight: '10rem',
          position: 'bottom-start',
          overflow: 'auto',
          paddingBottom: '3rem',
        },
      }}
      id='combo-box-demo'
      options={options}
      open={open ?? true}
      openOnFocus
      autoSelect
      limitTags={3}
      loading={isLoading}
      renderOption={renderOption}
      renderInput={(params) => {
        return (
          <TextField
            {...params.inputProps}
            variant='outlined'
            label={textInputLabel as ReactNode}
            fullWidth
            margin='dense'
            size='medium'
            style={{ marginBottom: '1em' }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {isLoading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                </Fragment>
              ),
            }}
          />
        )
      }}
      popupIcon={<Search />}
      noOptionsText={<Button onClick={onCancel}>Cancel</Button>}
    />
  )
}
