import { useSelector } from 'react-redux'
import { LibraryType, Review, WearAppState } from '../store/types'
import { useLocation } from 'react-router-dom'

export const useGetReviewData = (): { review?: Review; image: string } => {
  const { state } = useLocation()
  const stateTyped = state as Record<string, LibraryType>
  const reviewType = stateTyped?.type
  const review = useSelector((store: WearAppState) => {
    if (reviewType) {
      return store.reviewLibrary[reviewType].find(
        (rev) => rev.imageS3Key === store.misc?.currentReview
      )
    }
    return undefined
  })

  const image = useSelector((store: WearAppState) => {
    if (reviewType) {
      return review?.image ?? ''
    }
    return store.misc?.currentPhoto?.picture ?? ''
  })

  return { review, image }
}
