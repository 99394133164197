import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth, Hub } from 'aws-amplify'
import { isEqual } from 'lodash'
import { useCallback, useContext, useEffect } from 'react'
import { store } from '../index'
import { LOG_OUT_SUCCESS, LOGIN_USER_SUCCESS } from '../store/auth/authActions'
import { useDispatch } from 'react-redux'
import { WearAppContext } from '../context/WearAppContext'

const EVENT_CONFIGURE = 'configured'
const EVENT_SIGN_IN = 'signIn'
const EVENT_SIGN_OUT = 'signOut'

let currentUser: CognitoUser | null

export const REVIEWERS = [
  'm.vine@schenckprocess.com',
  'to.ngo@schenckprocess.com',
  's.gilmour@schenckprocess.com',
  'j.hitchcock@schenckprocess.com',
  'j.banvait@schenckprocess.com',
  'g.morton@schenckprocess.com',
  's.chittendon@schenckprocess.com',
  'reviewer@schenckprocess.com',
  'morgan.vine@sandvik.com',
  'tommy.ngo@sandvik.com',
  'scott.gilmour@sandvik.com',
  'john.hitchcock@sandvik.com',
  'jamal.banvait@sandvik.com',
  'glenn.morton@sandvik.com',
  'steve.chittendon@sandvik.com',
  'reviewer@sandvik.com',
]

export const ADMINS = [
  'l.hitchcock@schenckprocess.com',
  'louise.hitchcock@sandvik.com',
  'j.rowe@schenckprocess.com',
  'jacob.rowe@sandvik.com',
  'saeedissah@gmail.com',
  'a.mammadov@schenckprocess.com',
  'asad.mammadov@sandvik.com',
  'fpaboim@gmail.com',
  'ext.s.issah@schenckprocess.com',
  'ext.f.aboim@schenckprocess.com',
  'saeed.issah_c@sandvik.com',
  'francisco.aboim_c@sandvik.com',
  'jeremy.herd@sandvik.com',
  'admin@schenckprocess.com',
]

const currentAuthenticatedUser = async (bypassCache = false) => {
  return Auth.currentAuthenticatedUser({ bypassCache })
}

type AuthenticationProps = {
  onLoginCallback: (user: CognitoUser | null) => void
  onLogoutCallback?: () => void
  onConfiguredCallback?: (user: CognitoUser | null) => void
  onAuthenticationAvailable?: (user: CognitoUser | null) => void
}

const loginCallbacks: Array<AuthenticationProps['onLoginCallback']> = []
const logoutCallbacks: Array<AuthenticationProps['onLogoutCallback']> = []
const valueChangedCallbacks: Array<(
  currentUser: CognitoUser | null
) => void> = []

Hub.listen('auth', async ({ payload }) => {
  const { event } = payload
  const oldValue = currentUser
  try {
    switch (event) {
      case EVENT_CONFIGURE:
        // We force without cache first, if they have no internet connection then with cache option is used
        currentUser =
          (await currentAuthenticatedUser(true)) ??
          (await currentAuthenticatedUser())
        break
      case EVENT_SIGN_IN:
        currentUser = await currentAuthenticatedUser()
        currentUser && loginCallbacks.forEach((cb) => cb?.(currentUser))
        break
      case EVENT_SIGN_OUT:
        currentUser = null
        logoutCallbacks.forEach((cb) => cb?.())
        break
      default:
        break
    }
  } catch (err) {
    currentUser = null
    store.dispatch({
      type: String(LOG_OUT_SUCCESS),
    })
  }

  if (!isEqual(oldValue, currentUser)) {
    valueChangedCallbacks.forEach((cb) => cb(currentUser))
  }
})

export const useAuthentication = () => {
  const { setShowDisclaimerModal } = useContext(WearAppContext)
  const dispatch = useDispatch()
  const onLogin = useCallback((user: CognitoUser | null) => {
    console.log('user', user)
    if (!user) {
      return
    }
    // @ts-ignore
    const attributes = user.attributes ?? user.challengeParam.userAttributes
    const email = attributes.email.toLowerCase()
    dispatch({
      type: LOGIN_USER_SUCCESS,
      payload: {
        user: {
          firstName: attributes.given_name,
          lastName: attributes.family_name,
          name: attributes.name,
          isReviewer: REVIEWERS.includes(email),
          isAdmin: ADMINS.includes(email),
          email,
        },
      },
    })
  }, [])

  const onLogout = useCallback(() => {
    setShowDisclaimerModal(false)
    dispatch({
      type: String(LOG_OUT_SUCCESS),
    })
  }, [])
  useEffect(() => {
    if (!loginCallbacks.includes(onLogin)) {
      loginCallbacks.push(onLogin)
    }
    if (!valueChangedCallbacks.includes(onLogin)) {
      valueChangedCallbacks.push(onLogin)
    }
    if (!logoutCallbacks.includes(onLogout)) {
      logoutCallbacks.push(onLogout)
    }
  }, [onLogin, onLogout])
}
