import { all, call, put, takeLeading } from 'redux-saga/effects'
import {
  FETCH_ALL_FAILURE_MODES,
  FETCH_ALL_FAILURE_MODES_FAILURE,
  FETCH_ALL_FAILURE_MODES_SUCCESS,
  TOGGLE_IS_FETCHING,
} from './failureModeActions'
import { FailureModeState } from '../types'
import { SET_LAST_SYNCED_TIME } from '../misc/miscActions'
import { fetchFailureModes } from '../../services/appSync'

function* fetchAllFailureModes() {
  try {
    yield put({ type: TOGGLE_IS_FETCHING })
    const failureModes: FailureModeState = yield call(fetchFailureModes)
    console.log('failureModes', failureModes)
    yield put({
      type: FETCH_ALL_FAILURE_MODES_SUCCESS,
      payload: { failureModes },
    })
    yield put({
      type: SET_LAST_SYNCED_TIME,
      payload: { lastSync: new Date().getTime() },
    })
  } catch (e) {
    yield put({ type: FETCH_ALL_FAILURE_MODES_FAILURE, payload: { error: e } })
  } finally {
    yield put({ type: TOGGLE_IS_FETCHING })
  }
}

export function* failureModeSagas() {
  yield all([
    takeLeading(String(FETCH_ALL_FAILURE_MODES), fetchAllFailureModes),
  ])
}
