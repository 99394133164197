import { createAction } from 'redux-actions'

const ns = 'review-library'

export const FETCH_REVIEW_LIBRARY = createAction(`${ns}/fetchLibrary`)
export const FETCH_REVIEW_LIBRARY_IMAGES = createAction(
  `${ns}/fetchLibraryImages`
)

export const FETCH_REVIEW_LIBRARY_IMAGE_LOADING = createAction(
  `${ns}/fetchLibraryImagesLoading`
)
export const FETCH_REVIEW_LIBRARY_SUCCESS = createAction(
  `${ns}/fetchLibrarySuccess`
)

export const FETCH_REVIEW_LIBRARY_IMAGE_SUCCESS = createAction(
  `${ns}/fetchLibraryImageSuccess`
)
export const FETCH_REVIEW_LIBRARY_ERROR = createAction(
  `${ns}/fetchLibraryFailure`
)
export const TOGGLE_IS_FETCHING = createAction(`${ns}/toggleIsSaving`)

export const PUSH_REVIEWED_LIBRARY = createAction(`${ns}/pushReviewedLibrary`)
export const PUSH_REVIEWED_LIBRARY_LOCAL = createAction(
  `${ns}/pushReviewedLibraryLocal`
)
export const PUSH_REVIEWED_LIBRARY_TEMP_CLOUD = createAction(
  `${ns}/pushReviewedLibraryTempLocal`
)
export const PUSH_REVIEWED_LIBRARY_SUCCESS = createAction(
  `${ns}/pushReviewedLibrarySuccess`
)

export const DELETE_SELECTED_LIBRARY = createAction(
  `${ns}/deleteSelectedLibrary`
)
