import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import {
  Dimension,
  WearAppContext,
  WearAppContextState,
} from './WearAppContext'
import { Library } from '../store/types'
import { useDispatch } from 'react-redux'
import { PUSH_PHOTO_TO_CLOUD } from '../store/upload/uploadActions'
import { cognitoAzureConfig } from '../services/aws_export'
import { Amplify } from 'aws-amplify'
import { Auth as AmplifyAuth } from '@aws-amplify/auth/lib-esm/Auth'

export const WearAppContextProvider: FunctionComponent = ({ children }) => {
  const [footerDimension, setFooterDimension] = useState<Dimension>()
  const [appBarDimension, setAppBarDimension] = useState<Dimension>()
  const [currentDialog, setCurrentDialogDimension] = useState<Dimension>()
  const [showBackButton, setShowBackButton] = useState<boolean>(false)
  const [loginWithEmail, setLoginWithEmail] = useState<boolean>(false)
  const [showDisclaimerModal, setShowDisclaimerModal] = useState<boolean>(false)
  const dispatch = useDispatch()

  const uploadPhotos = useCallback(
    (files: Array<{ file: string; library: Library }>) => {
      dispatch({
        type: String(PUSH_PHOTO_TO_CLOUD),
        payload: {
          files,
        },
      })
    },
    [dispatch]
  )

  useEffect(() => {
    AmplifyAuth.configure({
      awsmobile: cognitoAzureConfig,
    })
    Amplify.configure(cognitoAzureConfig)
  }, [loginWithEmail])

  const value: WearAppContextState = {
    appBarDimension,
    setAppBarDimension,
    footerDimension,
    setFooterDimension,
    currentDialog,
    setCurrentDialogDimension,
    showBackButton,
    setLoginWithEmail,
    loginWithEmail,
    setShowBackButton,
    uploadPhotos,
    showDisclaimerModal,
    setShowDisclaimerModal,
  }

  return (
    <WearAppContext.Provider value={value}>{children}</WearAppContext.Provider>
  )
}
