import React, { FunctionComponent, useEffect, useState } from 'react'
import { TabProps } from '../polygon/WearAppTabs'
import { useSelector } from 'react-redux'
import { WearAppState } from '../../../store/types'
import { Close } from '@material-ui/icons'
import { AnalysisDrawer } from './AnalysisDrawer'
import { ReplacePanelResults } from '../polygon/ReplacePanelResults'
import { DrawerItem } from './DrawerItem'

export const WearDrawer: FunctionComponent<TabProps> = (props) => {
  const depths = useSelector((store: WearAppState) => store.analysis.depths)
  // const selectedPanel = useSelector(
  //   (state: WearAppState) => state.analysis.selectedPanel
  // )

  const [showDepths, setShowDepths] = useState<boolean>(false)
  useEffect(() => {
    setShowDepths(!!depths)
  }, [depths])

  return (
    <AnalysisDrawer
      {...props}
      setShowResults={setShowDepths}
      showResults={showDepths}
    >
      <div>
        <ReplacePanelResults detail>
          <Close onClick={() => setShowDepths(false)} />
        </ReplacePanelResults>

        {/* <DrawerItem isGray title='Panel No:'>
          <p>{selectedPanel}</p>
        </DrawerItem> */}

        <DrawerItem title='Average Panel Wear:'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {depths?.less_topzone_mean ? (
              <p>
                <b>&lt;&nbsp;</b>
              </p>
            ) : null}
            {depths?.more_wd_mean ? (
              <p>
                <b>&gt;&nbsp;</b>
              </p>
            ) : null}
            <p>{depths?.depth_mean.toFixed(2)}mm</p>
          </div>
        </DrawerItem>

        <DrawerItem isGray title='95th Percentile Panel Wear:'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {depths?.less_topzone_p95 ? (
              <p>
                <b>&lt;&nbsp;</b>
              </p>
            ) : null}
            {depths?.more_wd_p95 ? (
              <p>
                <b>&gt;&nbsp;</b>
              </p>
            ) : null}
            <p>{depths?.depth_p95.toFixed(2)}mm</p>
          </div>
        </DrawerItem>

        <DrawerItem title='Max Panel Wear:'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {depths?.less_topzone_max ? (
              <p>
                <b>&lt;&nbsp;</b>
              </p>
            ) : null}
            {depths?.more_wd_max ? (
              <p>
                <b>&gt;&nbsp;</b>
              </p>
            ) : null}
            <p>{depths?.depth_max.toFixed(2)}mm</p>
          </div>
        </DrawerItem>
        <DrawerItem isGray title='Min Panel Wear:'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {depths?.less_topzone_min ? (
              <p>
                <b>&lt;&nbsp;</b>
              </p>
            ) : null}
            {depths?.more_wd_min ? (
              <p>
                <b>&gt;&nbsp;</b>
              </p>
            ) : null}
            <p>{depths?.depth_min.toFixed(2)}mm</p>
          </div>
        </DrawerItem>
      </div>
    </AnalysisDrawer>
  )
}
