import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import { configureStore } from './store/configureStore'
import { ThemeOptions } from '@material-ui/core/styles/createTheme'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline } from '@material-ui/core'
import { TOGGLE_NEW_VERSION_AVAILABLE } from './store/misc/miscActions'
// import Test from './services/__tests__/Test'
import markerSDK from '@marker.io/browser'
import { WearAppContextProvider } from './context/WearAppContextProvider'

const { store, persistor } = configureStore()

markerSDK.loadWidget({
  project: process.env.REACT_APP_MARKER_IO_PROJECT_CONFIG as string,
})

if (!process.env.REACT_APP_TESTING) {
  const themeOptions = {
    palette: {
      type: 'dark',
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#007073',
      },
    },
    overrides: {
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: 'white',
          },
        },
      },
    },
  } as ThemeOptions

  const theme = createTheme(themeOptions)

  ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <CssBaseline />
            <WearAppContextProvider>
              <App />
            </WearAppContextProvider>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  )

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.register({
    onUpdate: (registration: ServiceWorkerRegistration) => {
      store.dispatch({
        type: String(TOGGLE_NEW_VERSION_AVAILABLE),
        payload: {
          newVersion: registration,
        },
      })
    },
  })

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  // reportWebVitals();
} else {
  ReactDOM.render(
    <Provider store={store}>{/* <Test></Test> */}</Provider>,
    document.getElementById('root')
  )
}

export { store }
