import { Library, LibraryType, Results, Review } from '../store/types'
import { S3 } from 'aws-sdk'
import { awsConfig } from './aws_export'
import moment from 'moment-timezone'
import { ApiClient } from './apiClient'
import axios from 'axios'

const s3Params = {
  region: awsConfig.region,
  credentials: {
    accessKeyId: awsConfig.accessKeyId,
    secretAccessKey: awsConfig.secretAccessKey,
  },
}

const s3 = new S3(s3Params)

const getName = (library: Library): string => {
  let name
  const panelTypeName = library.panelType || 'Unknown'
  if (library.siteDetail) {
    const siteDetail = library.siteDetail
    const clientName =
      (siteDetail.clientName ?? '').length > 0
        ? siteDetail.clientName
        : 'Unknown'
    const siteName =
      (siteDetail.siteName ?? '').length > 0 ? siteDetail.siteName : 'Unknown'
    name = `${clientName}-${siteName}-${panelTypeName}`
  } else {
    name = `Unknown-Unknown-${panelTypeName}`
  }
  return `${name}-${moment
    .utc(library.timeStamps.createdAtUTC)
    .format('YYYYMMDD-HHmmssZ')}`
}

type GetKey = {
  type: 'Images' | 'Labels' | 'Results'
  destinationFolder?:
    | 'Reviewed'
    | 'Ready For Review'
    | 'All Images'
    | 'Rejected'
    | 'Verified'
    | 'Accepted'
  fileName: string
}

const transformLibraryTypeToDestinationFolder = (
  type?: LibraryType
): GetKey['destinationFolder'] => {
  if (!type || type === 'annotated') {
    return 'Ready For Review'
  } else if (type === 'notAnnotated' || type === 'yourImages') {
    return 'All Images'
  } else if (type === 'reviewed') {
    return 'Reviewed'
  }
}

const getKey = ({ destinationFolder, fileName, type }: GetKey) => {
  return `${destinationFolder}/${type}/${fileName}`
}

const uploadImage = async (image: string, fileName: string) => {
  const { data: url } = await ApiClient.post('/saveData', {
    key: fileName,
  })
  return axios.put(url, image, {
    headers: { 'content-type': 'text/plain' },
  })
}

const uploadLabel = async (library: any, fileName: string) => {
  const { data: url } = await ApiClient.post('/saveData', {
    key: fileName,
  })
  return axios.put(url, JSON.stringify(library), {
    headers: { 'content-type': 'text/plain' },
  })
}

const deleteObject = async (key: string) => {
  const { data: url } = await ApiClient.delete('/deleteData', {
    params: {
      key,
    },
  })
  console.log('deleteObject', url)
  return axios.delete(url)
}

const uploadLibraryToS3Temp = async (
  library: Library,
  originalLabelKey: string
) => {
  const copyLibrary = {
    ...JSON.parse(JSON.stringify(library)),
    image: undefined,
  }

  await uploadLabel(copyLibrary, originalLabelKey)

  return true
}

export const uploadLibraryToS3 = async (
  library: Library,
  readyForReview?: boolean,
  results?: Results,
  isReview?: boolean,
  originalLabelKey?: string,
  originalImageKey?: string,
  isTemp?: boolean,
  reviewType?: LibraryType
): Promise<boolean> => {
  if (isTemp) {
    return await uploadLibraryToS3Temp(library, originalLabelKey ?? '')
  }

  let image = library.image
  if (!image.trim() && originalImageKey) {
    image = String(
      (
        await s3
          .getObject({
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME ?? '',
            Key: originalImageKey,
          })
          .promise()
      ).Body
    )
  }

  const copyLibrary = {
    ...JSON.parse(JSON.stringify(library)),
    image: undefined,
  }
  delete copyLibrary.image
  if (isReview) {
    delete copyLibrary.hasBeenReviewed
  }
  const name = getName(library)
  let destinationFolder: GetKey['destinationFolder'] = 'All Images'
  if (reviewType === 'reviewed') {
    destinationFolder = 'Accepted'
  } else if (reviewType === 'annotated') {
    destinationFolder = 'Reviewed'
  } else if (!readyForReview) {
    destinationFolder = 'All Images'
  } else if (readyForReview) {
    destinationFolder = 'Ready For Review'
  }

  console.log('destinationFolder', destinationFolder)

  const labelKey = getKey({
    destinationFolder,
    type: 'Labels',
    fileName: `${name}.json`,
  })
  const imageKey = getKey({
    destinationFolder,
    type: 'Images',
    fileName: `${name}.txt`,
  })

  const promises = [
    uploadImage(image as string, imageKey),
    uploadLabel(copyLibrary, labelKey),
  ]

  if (results) {
    const resultsKey = getKey({
      destinationFolder,
      type: 'Results',
      fileName: `${name}.json`,
    })
    promises.push(uploadLabel(results, resultsKey))
  }

  const success = await Promise.all(promises)

  if (success && isReview) {
    //(isAnnotated: boolean, isImage: boolean, isReview: boolean, fileName: string)

    const deleteImageKey =
      originalImageKey ??
      getKey({
        destinationFolder: transformLibraryTypeToDestinationFolder(reviewType),
        type: 'Images',
        fileName: `${name}.txt`,
      })
    const deleteJsonKey =
      originalLabelKey ??
      getKey({
        destinationFolder: transformLibraryTypeToDestinationFolder(reviewType),
        type: 'Labels',
        fileName: `${name}.json`,
      })
    const deleteThumbnailKey = deleteImageKey.replace('.txt', '-thumb.png')
    console.log('deleteImageKey', deleteImageKey)
    console.log('deleteJsonKey', deleteJsonKey)
    console.log('deleteThumbnailKey', deleteThumbnailKey)
    if (deleteImageKey === imageKey || deleteJsonKey === labelKey) {
      return false
    }
    await Promise.all([
      deleteObject(deleteImageKey),
      deleteObject(deleteJsonKey),
      deleteObject(deleteThumbnailKey),
    ])
  }
  return success.length === (results ? 3 : 2)
}

export const deleteLibrary = async (
  library: Library,
  s3ImageKey: string,
  labelS3Key: string,
  thumbnailS3Key: string
): Promise<void> => {
  const name = getName(library)
  const image = library.image
  const copyLibrary = {
    ...JSON.parse(JSON.stringify(library)),
    image: undefined,
  }
  delete copyLibrary.image

  const success = await Promise.all([
    uploadImage(
      image as string,
      getKey({
        destinationFolder: 'Rejected',
        type: 'Images',
        fileName: `${name}.txt`,
      })
    ),
    uploadLabel(
      copyLibrary,
      getKey({
        destinationFolder: 'Rejected',
        type: 'Labels',
        fileName: `${name}.json`,
      })
    ),
  ])
  if (success.length === 2) {
    await Promise.all([
      deleteObject(s3ImageKey),
      deleteObject(labelS3Key),
      deleteObject(thumbnailS3Key),
    ])
  }
}

export const downloadImages = async (imageUrls: {
  [key: string]: string
}): Promise<{ [key: string]: string }> => {
  const images: Record<string, string> = {}
  for (const key in imageUrls) {
    const data = await axios.get(imageUrls[key])
    images[key] = String(data.data)
  }

  return images
}

export const getData = async (): Promise<Record<string, Array<Review>>> => {
  const apiData = (await ApiClient.get('/getData')).data as Record<
    string,
    any[]
  >

  const apiResults: Record<
    'annotated' | 'notAnnotated' | 'yourImages' | 'reviewed',
    Array<Review>
  > = {
    yourImages: [],
    notAnnotated: [],
    annotated: [],
    reviewed: [],
  }

  Object.keys(apiData).forEach((key) => {
    const data = apiData[key]

    for (const datum of data) {
      apiResults[key as keyof typeof apiResults].push({
        image: '',
        label: datum.label,
        imageS3Key: datum.imageKey,
        imageUrl: datum.imageUrl,
        labelS3Key: datum.labelKey,
        isFetching: !datum.thumbnailUrl,
        thumbnailUrl: datum.thumbnailUrl,
        thumbnailS3Key: datum.imageKey.replace('.txt', '-thumb.png'),
      })
    }
  })

  return apiResults
}

export const uploadNotAnnotatedFile = (
  file: string,
  library: Library
): Promise<unknown> => {
  const name = getName(library)
  const imageKey = getKey({
    destinationFolder: 'All Images',
    type: 'Images',
    fileName: `${name}.txt`,
  })
  const labelKey = getKey({
    destinationFolder: 'All Images',
    type: 'Labels',
    fileName: `${name}.json`,
  })
  return Promise.all([
    uploadImage(file, imageKey),
    uploadLabel(library, labelKey),
  ])
}
