import { User, AuthState } from '../types'
import { handleActions, Action } from 'redux-actions'
import {
  LOG_OUT_SUCCESS,
  AUTH_FAILURE,
  LOGIN_USER_SUCCESS,
  TOGGLE_IS_LOADING,
  RESET_PASSWORD_SUCCESS,
  CLEAR_RESET_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CLEAR_RESET_PASSWORD_SUCCESS,
} from './authActions'

const initialState: AuthState = {
  isLoggedIn: false,
  isLoading: false,
}

export type AuthPayload = {
  user: User
  error?: any
}

export const authReducer = handleActions<AuthState, AuthPayload>(
  {
    [String(LOGIN_USER_SUCCESS)]: (
      authState: AuthState,
      { payload }: Action<AuthPayload>
    ) => ({
      ...authState,
      user: {
        ...payload.user,
      },
      isLoggedIn: true,
      resetPassword: false,
    }),
    [String(TOGGLE_IS_LOADING)]: (authState: AuthState) => {
      return {
        ...authState,
        isLoading: !authState.isLoading,
      }
    },
    [String(AUTH_FAILURE)]: (
      authState: AuthState,
      { payload }: Action<AuthPayload>
    ) => {
      return {
        ...authState,
        error: payload.error,
      }
    },
    [String(LOG_OUT_SUCCESS)]: (authState: AuthState) => {
      return {
        ...authState,
        user: undefined,
        isLoggedIn: false,
      }
    },
    [String(RESET_PASSWORD_SUCCESS)]: (authState: AuthState) => {
      return {
        ...authState,
        isResettingPassword: true,
        resetPasswordSuccess: false,
      }
    },
    [String(CHANGE_PASSWORD_SUCCESS)]: (authState: AuthState) => {
      return {
        ...authState,
        isResettingPassword: true,
        resetPasswordSuccess: true,
      }
    },
    [String(CLEAR_RESET_PASSWORD)]: (authState: AuthState) => {
      return {
        ...authState,
        isResettingPassword: false,
      }
    },
    [String(CLEAR_RESET_PASSWORD_SUCCESS)]: (authState: AuthState) => {
      return {
        ...authState,
        resetPasswordSuccess: false,
      }
    },
  },
  initialState
)
