import React, { FunctionComponent, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { CLEAR_RESET_PASSWORD } from '../../store/auth/authActions'
import { withOAuth } from 'aws-amplify-react'
import { LoginWithEmailAndPassword } from '../../components/login/LoginWithEmailAndPassword'
import { WearAppContext } from '../../context/WearAppContext'
// @ts-ignore
import sha256 from 'crypto-js/sha256'
import Base64 from 'crypto-js/enc-base64'

const useStyles = makeStyles({
  textField: {
    width: '100%',
    marginTop: '1em',
  },
  resetPassword: {
    width: '100%',
    fontFamily: 'arial',
    marginTop: '1em',
    fontSize: 14,
    color: '#F2F2F2',
    backgroundColor: '#006068',
  },
  login: {
    textDecoration: 'none',
    fontSize: 12,
    marginTop: '1em',
  },
})

const base64URL = (string: any) => {
  return string
    .toString(Base64)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
}

const generateChallenge = (code: string) => {
  return base64URL(sha256(code))
}

const bufferToString = (buffer: Uint8Array) => {
  const CHARSET =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const state = []
  for (let i = 0; i < buffer.byteLength; i += 1) {
    const index = buffer[i] % CHARSET.length
    state.push(CHARSET[index])
  }
  return state.join('')
}

const generateRandom = (size: number) => {
  const CHARSET =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~'
  const buffer = new Uint8Array(size)
  if (typeof window !== 'undefined' && !!window.crypto) {
    window.crypto.getRandomValues(buffer)
  } else {
    for (let i = 0; i < size; i += 1) {
      buffer[i] = (Math.random() * CHARSET.length) | 0
    }
  }
  return bufferToString(buffer)
}

export const OauthButton = withOAuth(Button)

export const Login: FunctionComponent = () => {
  const styles = useStyles()

  const dispatch = useDispatch()
  const {
    loginWithEmail,
    setLoginWithEmail,
    setShowDisclaimerModal,
  } = useContext(WearAppContext)

  useEffect(() => {
    dispatch({
      type: String(CLEAR_RESET_PASSWORD),
    })
  }, [])

  const login = async () => {
    setShowDisclaimerModal(true)
    const pkce_key = generateRandom(128)
    window.sessionStorage.setItem('ouath_pkce_key', pkce_key)
    const code_challenge = generateChallenge(pkce_key)
    const code_challenge_method = 'S256'

    const redirectSignIn = `${window.location.origin}/auth/redirect`
    const clientId =
      process.env.REACT_APP_COGNITO_APP_CLIENT_ID_AZURE ??
      '5v577ke62oeknh92g6snc8i276'
    const queryString = Object.entries({
      redirect_uri: redirectSignIn,
      response_type: 'code',
      client_id: clientId,
      identity_provider: 'Sandvik-IDP',
      scope: 'email profile openid aws.cognito.signin.user.admin',
      code_challenge,
      code_challenge_method,
    })
      .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
      .join('&')

    window.location.replace(
      `https://azuread-wearapp-sandvik.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize?${queryString}`
    )

    // await Auth.federatedSignIn({
    //   customProvider:
    //     process.env.REACT_APP_COGNITO_CUSTOM_PROVIDER ?? 'Sandvik-IDP',
    // })
  }

  const loginWithEmailPassword = async () => {
    setLoginWithEmail(true)
  }

  return (
    <>
      {loginWithEmail ? (
        <LoginWithEmailAndPassword
          toggleLoginWithEmailPassword={() => setLoginWithEmail(false)}
        />
      ) : (
        <>
          <Button
            onClick={login}
            className={styles.resetPassword}
            size='medium'
          >
            Sandvik Login
          </Button>
          <Button
            variant='outlined'
            onClick={loginWithEmailPassword}
            className={styles.login}
            size='medium'
          >
            External Login
          </Button>
        </>
      )}
    </>
  )
}
