import { Drawer, Fab } from '@material-ui/core'
import { Visibility } from '@material-ui/icons'
import React, { FunctionComponent, useContext } from 'react'
import { WearAppContext } from '../../../context/WearAppContext'
import { TabProps, WearAppTabs } from '../polygon/WearAppTabs'

type Props = TabProps & {
  showResults: boolean
  setShowResults: (show: boolean) => void
}

export const AnalysisDrawer: FunctionComponent<Props> = ({
  showResults,
  setShowResults,
  children,
  ...restProps
}) => {
  const { footerDimension } = useContext(WearAppContext)

  return (
    <div
      id='results_container'
      style={{
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1000000,
        paddingBottom: '1em',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <WearAppTabs {...restProps} />
      {!showResults && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            right: 0,
            zIndex: 6000,
            bottom: '15%',
            marginRight: '2em',
          }}
        >
          <Fab color='secondary' onClick={() => setShowResults(true)}>
            <Visibility />
          </Fab>
        </div>
      )}
      <Drawer
        open={showResults}
        anchor='bottom'
        PaperProps={{
          style: { bottom: footerDimension?.height ?? 30 },
        }}
        style={{
          zIndex: 100,
        }}
        onClose={() => setShowResults(false)}
        hideBackdrop
        disablePortal
      >
        <div
          style={{
            maxHeight: '24em',
          }}
        >
          {children}
        </div>
      </Drawer>
    </div>
  )
}
