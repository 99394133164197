import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { WearAppState } from '../../store/types'
import { useContext, useState } from 'react'
import { Button } from '@material-ui/core'

import { version } from '../../../package.json'
import { SiteDetailWarning } from '../../components/SiteDetailWarning'
import { useSetShowBackButton } from '../../hooks/useSetShowBackButton'
import { useNetworkState } from 'react-use'
import { WarningDialog } from '../../components/WarningDialog'
import { useFetchMiscData } from '../../hooks/useFetchMiscData'
import { WearAppContext } from '../../context/WearAppContext'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  welcome: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  button: {
    fontFamily: 'arial',
    fontSize: 14,
    color: '#F2F2F2',
    minWidth: '130px',
    height: '40px',
    borderRadius: '.5em',
  },
  start_audit: {
    backgroundColor: '#006068',
  },
  review_images: {
    backgroundColor: '#006068',
    marginBottom: '5em',
  },
  review_images_no_internet: {
    backgroundColor: 'rgba(255, 0, 0, .5)',
  },
  camera: {
    borderColor: '#006068',
    marginTop: '2em',
  },
  beta_string: {
    fontFamily: 'arial',
    fontSize: 14,
    color: '#F2F2F2',
    // transform: 'translate(-50%, -50%)'
  },
})

export const Home = () => {
  const navigate = useNavigate()
  const firstName = useSelector(
    (state: WearAppState) => state.auth?.user?.firstName
  )

  const classes = useStyles()
  const [openSiteDetailsWarning, setOpenSiteDetailsWarning] = useState(false)
  const toggleOpenSiteDetailsWarning = () => {
    setOpenSiteDetailsWarning((open) => {
      return !open
    })
  }

  const [openReviewWarningDialog, setOpenReviewWarningDialog] = useState(false)
  const toggleOpenReviewWarningDialog = () => {
    setOpenReviewWarningDialog(!openReviewWarningDialog)
  }
  const { online } = useNetworkState()
  const onReviewImageClicked = () => {
    if (online) {
      navigate('/dashboard/review')
    } else {
      toggleOpenReviewWarningDialog()
    }
  }
  useSetShowBackButton(false)

  useFetchMiscData()

  const { showDisclaimerModal, setShowDisclaimerModal } = useContext(
    WearAppContext
  )

  return (
    <div className={classes.container}>
      <div className={classes.buttonContainer}>
        <Typography variant='h6' className={classes.welcome}>
          {`Welcome back, ${firstName}!`}
        </Typography>

        <Button
          size='medium'
          className={`${classes.button} ${classes.start_audit}`}
          variant='contained'
          onClick={() => {
            navigate('/dashboard/audit')
          }}
        >
          SITE DETAILS
        </Button>
        <Button
          size='medium'
          className={`${classes.button} ${classes.camera}`}
          variant='outlined'
          onClick={toggleOpenSiteDetailsWarning}
        >
          CAMERA
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Button
          size='medium'
          className={`${classes.button} ${classes.review_images} ${
            !online ? classes.review_images_no_internet : undefined
          } `}
          variant='contained'
          onClick={onReviewImageClicked}
        >
          Review Images
        </Button>

        <p className={classes.beta_string}>v{version}</p>
      </div>
      <SiteDetailWarning
        open={openSiteDetailsWarning}
        onClose={toggleOpenSiteDetailsWarning}
      />
      <WarningDialog
        open={openReviewWarningDialog}
        onClose={toggleOpenReviewWarningDialog}
        title="You're offline"
        description='You need to have an active Internet connection to be able to review images'
        positiveButtonText='Ok'
        onPositiveButtonClicked={toggleOpenReviewWarningDialog}
      />

      <WarningDialog
        open={!!showDisclaimerModal}
        onClose={() => setShowDisclaimerModal(false)}
        description='The measurements and panel replacement indicators provided by WearApp are to be used for guideline purposes only and should not be used as the sole basis for determining if a panel requires placement. Please consult with a qualifed screening media expert for an accurate evaluation.'
        title='Disclaimer'
        positiveButtonText='ok'
      />
    </div>
  )
}
