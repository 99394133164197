import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PanelTypes } from '../../components/preview/rectangle/PanelTypes'
import { useDispatch, useSelector } from 'react-redux'
import {
  BoundingBox,
  Library,
  LibraryType,
  Results,
  SiteDetail,
  WearAppState,
  WearInfo,
} from '../../store/types'
import { useOrientation } from 'react-use'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Slide,
  TextField,
} from '@material-ui/core'
import {
  Edit,
  Close,
  Save,
  ArrowBack,
  Assignment,
  DeleteForever,
  ArrowForward,
  Delete,
  Add,
  Visibility,
} from '@material-ui/icons'
import moment from 'moment-timezone'
import { SAVE_TO_LIBRARY } from '../../store/library/libraryActions'
import { isEqual, cloneDeep } from 'lodash'
import {
  DELETE_SELECTED_LIBRARY,
  PUSH_REVIEWED_LIBRARY_LOCAL,
  PUSH_REVIEWED_LIBRARY_TEMP_CLOUD,
} from '../../store/reviewLibrary/reviewLibraryActions'
import { useSetShowBackButton } from '../../hooks/useSetShowBackButton'
import { SiteDetailDialog } from '../../components/preview/rectangle/SiteDetailDialog'
import { WarningDialog } from '../../components/WarningDialog'
import { WearAppTransform } from '../../components/preview/WearAppTransform'
import {
  RESET_ANALYSIS,
  SELECT_PANEL,
  SET_POLYGON_BBOX,
  START_ANALYSIS,
} from '../../store/analysis/analysisActions'
import { ResultsDrawer } from '../../components/preview/drawer/ResultsDrawer'
import { WearDrawer } from '../../components/preview/drawer/WearDrawer'
import { fetchPanel } from '../../services/appSync'
import { useGetReviewData } from '../../hooks/useGetReviewData'
import { WearAnalysisInfoDialog } from '../../components/preview/wearanalysis/WearAnalysisInfoDialog'
import { EfficiencyDrawer } from '../../components/preview/drawer/EfficiencyDrawer'

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction='up' ref={ref} {...props} />
})

export const Preview: FunctionComponent = () => {
  const navigate = useNavigate()
  const [useBoundingBox, setUseBoundingBox] = useState<
    'rectangle' | 'polygon' | undefined
  >(undefined)

  const [canEdit, setCanEdit] = useState<boolean>(false)

  useSetShowBackButton(false)
  const [materialSelected, setMaterialSelected] = useState<string>('')
  const [openPanelDialog, setOpenPanelDialog] = useState(false)
  const [openWarningDialog, setOpenWarningDialog] = useState(false)
  const { state } = useLocation()
  const stateTyped = state as Record<string, string | boolean>
  const isReviewing = (stateTyped?.isReviewing as boolean) ?? false
  const reviewType = stateTyped?.type as LibraryType
  const { review, image } = useGetReviewData()
  const [boundingBoxes, setBoundingBoxes] = useState<Array<BoundingBox>>([])
  const siteDetail = useSelector(
    (state: WearAppState) => state.library.siteDetail
  )
  const startAnalysis = useSelector(
    (store: WearAppState) => store.analysis.startAnalysis
  )

  const [activeRect, setActiveRect] = useState<number | undefined>(undefined)
  const [openFailureModes, setOpenFailureModes] = useState<boolean>(false)
  const polygon = useSelector(
    (state: WearAppState) => state.analysis.bbox ?? []
  )
  const [showPanelWarning, setShowPanelWarning] = useState<boolean>(false)
  const [showResultPressed, setShowResultPressed] = useState<boolean>(false)
  const handleClose = () => {
    setOpenPanelDialog(false)
  }
  const firstName = useSelector(
    (state: WearAppState) => state.auth?.user?.firstName
  )
  const lastName = useSelector(
    (state: WearAppState) => state.auth?.user?.lastName
  )

  const email = useSelector((state: WearAppState) => state.auth?.user?.email)

  const activeTab = useSelector(
    (state: WearAppState) => state.analysis.activeTab
  )
  const [
    boundingBoxWithOtherFailure,
    setBoundingBoxWithOtherFailure,
  ] = useState<BoundingBox>()
  const [freeTextFailureMode, setFreeTextFailureMode] = useState('')
  const { type } = useOrientation()
  const isLandscape = type === 'landscape-primary'
  const dispatch = useDispatch()
  const [navigatingAwayWarning, setNavigatingAwayWarning] = useState<string>(
    'Are you sure you want to retake the image without uploading?'
  )
  const [openSiteDetailDialog, setOpenSiteDetailDialog] = useState<boolean>(
    false
  )
  const [reviewSiteDetail, setReviewSiteDetail] = useState<
    SiteDetail | undefined
  >()

  useEffect(() => {
    setReviewSiteDetail(siteDetail)
  }, [])

  const [openMaterialWarning, setOpenMaterialWarning] = useState<boolean>(false)
  const [openDeleteWarning, setOpenDeleteWarning] = useState<boolean>(false)
  const analysisResult = useSelector(
    (state: WearAppState) => state.analysis.results
  )
  const depths = useSelector((state: WearAppState) => state.analysis.depths)
  const panelSelectedForResults = useSelector(
    (state: WearAppState) => state.analysis.selectedPanel
  )

  const [showLoader, setShowLoader] = useState<boolean>(!image)
  const [showWearAnalysisDialog, setShowWearAnalysisDialog] = useState<boolean>(
    false
  )
  const [wearInfoData, setWearInfoData] = useState<WearInfo>()

  useEffect(() => {
    setShowLoader(!image)
  }, [image])

  useEffect(() => {
    return () => {
      dispatch({
        type: String(RESET_ANALYSIS),
      })
    }
  }, [])

  const parsedLabel: Omit<Library, 'image'> | null = useMemo(() => {
    if (!review) {
      return null
    }
    if (review.label.polygonPts) {
      dispatch({
        type: String(SET_POLYGON_BBOX),
        payload: {
          bbox: [
            review.label.polygonPts.tl,
            review.label.polygonPts.tr,
            review.label.polygonPts.br,
            review.label.polygonPts.bl,
          ],
        },
      })
    }
    return review.label
  }, [review])

  const canShowActionButtons = useMemo(() => {
    if (useBoundingBox === 'rectangle') {
      return activeRect
    } else if (useBoundingBox === 'polygon') {
      return polygon.some((pol) => pol.length > 0)
    }
    return false
  }, [useBoundingBox, activeRect, polygon])

  useEffect(() => {
    if ((!review || !isReviewing) && image) {
      return
    }

    if (!parsedLabel) {
      return
    }
    if (Object.keys(parsedLabel).length > 0) {
      const hasReviewedSiteDetail =
        Object.keys(parsedLabel.reviewedSiteDetail ?? {}).length > 0
      setReviewSiteDetail(
        hasReviewedSiteDetail
          ? parsedLabel.reviewedSiteDetail
          : parsedLabel.siteDetail
      )
      const selectedMaterial =
        parsedLabel.reviewedPanelType ?? parsedLabel.panelType
      setMaterialSelected(selectedMaterial as string)
      dispatch({
        type: String(SELECT_PANEL),
        payload: {
          selectedPanel: selectedMaterial,
        },
      })
      const failureModes = parsedLabel.failureModes ?? []
      setBoundingBoxes(failureModes)
      console.log('failureModes', failureModes)
    }
  }, [])

  const library: Library = useMemo(() => {
    if (review && parsedLabel) {
      return {
        ...parsedLabel,
        image,
        failureModes: boundingBoxes,
        modifiedBy: firstName + ' ' + lastName,
        reviewedSiteDetail: reviewSiteDetail,
        reviewedPanelType: materialSelected,
        polygonPts: {
          tl: polygon[0],
          tr: polygon[1],
          br: polygon[2],
          bl: polygon[3],
        },
      }
    }
    const time = moment()
    const timeZone = moment.tz(moment.tz.guess())

    // JSON SCHEMA controlling the order of the file
    //['red', 'blue', 'green', 'yellow']
    return {
      id: time.valueOf(),
      email: email ?? '',
      image: image ?? '',
      timeStamps: {
        createdAtTimeZone: timeZone.format('YYYY-MM-DDTHH:mm:sszz'),
        createdAtUTC: time.utc().format(),
        updatedAtTimeZone: timeZone.format('YYYY-MM-DDTHH:mm:sszz'),
        updatedAtUTC: time.utc().format(),
      },
      cloudHasLatestUpdate: false,
      failureModes: boundingBoxes.map<BoundingBox>((rectangle) => ({
        ...rectangle,
      })),
      panelType: materialSelected,
      userName: firstName + ' ' + lastName,
      savedToCloud: false,
      siteDetail: reviewSiteDetail ?? ({} as SiteDetail),
      source: state
        ? (state as Record<'from', 'capture' | 'upload'>).from ?? 'upload'
        : 'upload',
      modifiedBy: review ? firstName + ' ' + lastName : undefined,
      polygonPts: {
        tl: polygon[0],
        tr: polygon[1],
        br: polygon[2],
        bl: polygon[3],
      },
    }
  }, [
    review,
    parsedLabel,
    image,
    boundingBoxes,
    materialSelected,
    firstName,
    lastName,
    reviewSiteDetail,
    state,
    reviewType,
    polygon,
    email,
  ])

  const results: Results | undefined = useMemo(() => {
    if (!analysisResult || !depths) {
      return undefined
    }
    const time = moment()
    const timeZone = moment.tz(moment.tz.guess())

    return {
      id: time.valueOf(),
      timeStamps: {
        createdAtTimeZone: timeZone.format('YYYY-MM-DDTHH:mm:sszz'),
        createdAtUTC: time.utc().format(),
      },
      userName: firstName + ' ' + lastName,
      polygonPts: {
        tl: polygon[0],
        tr: polygon[1],
        br: polygon[2],
        bl: polygon[3],
      },
      source: state
        ? (state as Record<'from', 'capture' | 'upload'>).from ?? 'upload'
        : 'upload',
      panelType: panelSelectedForResults ?? '',
      maxDepth: analysisResult?.w_max ?? 0,
      minDepth: analysisResult?.w_min,
      avgDepth: depths?.depth_mean,
      '95Depth': depths?.depth_p95,
      ...(analysisResult?.dl_res ?? {}),
    }
  }, [
    firstName,
    lastName,
    polygon,
    state,
    panelSelectedForResults,
    analysisResult,
    depths,
  ])

  const [showReadyToReviewDialog, setShowReadyToReviewDialog] = useState<
    boolean
  >(false)

  const toggleWarningDialog = () => {
    setOpenWarningDialog(!openWarningDialog)
  }

  const onBackButtonPressed = useCallback(() => {
    if (useBoundingBox === 'polygon') {
      setActiveRect(undefined)
      setCanEdit(false)
      if (startAnalysis) {
        dispatch({
          type: String(START_ANALYSIS),
          payload: {
            info: {
              ...wearInfoData,
            },
          },
        })
      } else {
        setUseBoundingBox('rectangle')
      }
    } else if (isReviewing && isEqual(library, { ...parsedLabel, image })) {
      navigate(-1)
    } else {
      setNavigatingAwayWarning(
        'Are you sure you want to go back without saving your changes?'
      )
      toggleWarningDialog()
    }
  }, [useBoundingBox])

  const addRectangle = useCallback(
    (boundingBox: BoundingBox) => {
      setBoundingBoxes((current) => [
        boundingBox,
        ...current.filter(({ id }) => id !== boundingBox.id),
      ])
      if (useBoundingBox === 'polygon') {
        setShowLoader(true)
        onBackButtonPressed()
        const timeout = setTimeout(() => {
          setUseBoundingBox('polygon')
          setCanEdit(true)
          setShowLoader(false)
          clearTimeout(timeout)
        }, 1000)
      }
    },
    [onBackButtonPressed]
  )

  const onDeleteRectangle = useCallback(
    (id: number) =>
      setBoundingBoxes((current) =>
        current.filter(({ id: rectId }) => id !== rectId)
      ),
    []
  )

  const onRectangleChanged = useCallback(
    (data: Omit<BoundingBox, 'failureModes'>) => {
      setBoundingBoxes((current) => {
        const rectangleIndex = current.findIndex(
          (rectangle) => rectangle.id === data.id
        )
        if (rectangleIndex === -1) {
          return current
        }
        current.splice(rectangleIndex, 1, {
          ...current[rectangleIndex],
          ...data,
        })
        return [...current]
      })
    },
    []
  )

  const onValuesSelected = useCallback(
    (id: number, failureModes: Array<string>) => {
      setBoundingBoxes((current) => {
        const currentCopy = [...current]
        const index = currentCopy.findIndex(({ id: rectId }) => id === rectId)
        currentCopy.splice(index, 1, {
          ...current[index],
          failureModes,
        })
        if (failureModes.includes('Other Failures')) {
          setBoundingBoxWithOtherFailure({ ...currentCopy[index] })
        }
        return currentCopy
      })
    },
    []
  )

  const onFreeFailureModeTextAdded = () => {
    if (!boundingBoxWithOtherFailure || !freeTextFailureMode.length) {
      return
    }
    const newFailureModes = [
      ...(boundingBoxWithOtherFailure.failureModes ?? []).filter(
        (item) => item !== 'Other Failures'
      ),
      freeTextFailureMode,
    ]
    onValuesSelected(boundingBoxWithOtherFailure.id, newFailureModes)
    setBoundingBoxWithOtherFailure(undefined)
    setFreeTextFailureMode('')
  }

  const [saveType, setSaveType] = useState<
    'save_for_later' | 'ready_for_review' | undefined
  >()

  const openCvCanvasRef = useRef<HTMLCanvasElement>(null)

  const templateRef = useRef<HTMLImageElement>(null)

  const checkIfPanelIsValid = async (selectedPanel?: string) => {
    const panel = await fetchPanel(selectedPanel ?? materialSelected)
    return (
      panel &&
      !(
        !panel.Aperture_Size_L ||
        !panel.Aperture_Size_W1 ||
        !panel.Panel_Size_L ||
        !panel.Panel_Size_W ||
        !panel.Working_Depth_Lig ||
        !panel.AP_TopZone_H12 ||
        !panel.AP_TopZone_W2 ||
        !panel.Aperture_Type ||
        !panel.Panel_Size_H
      )
    )
  }

  const onShowResultPressed = async (data?: any, force = true) => {
    if (!materialSelected) {
      setShowResultPressed(true)
      setOpenSiteDetailDialog(true)
    } else if (!data && !force) {
      setShowWearAnalysisDialog(true)
    } else {
      setShowWearAnalysisDialog(false)
      if (data) {
        setWearInfoData(data)
      }
      if (!(await checkIfPanelIsValid())) {
        setShowPanelWarning(true)
      } else {
        dispatch({
          type: String(START_ANALYSIS),
          payload: {
            info: {
              ...data,
            },
          },
        })
      }
    }
  }

  const onSavePressed = (forceSave = true, readyForReview = false) => {
    setShowReadyToReviewDialog(false)
    setSaveType(readyForReview ? 'ready_for_review' : 'save_for_later')
    if (!materialSelected && !forceSave) {
      setOpenMaterialWarning(true)
      return
    }

    let hasBeenReviewed = false

    if (
      readyForReview ||
      saveType === 'ready_for_review' ||
      ['reviewed', 'annotated'].includes(reviewType)
    ) {
      hasBeenReviewed = true
    }

    const time = moment()
    const timeZone = moment.tz(moment.tz.guess())
    const updatedLibrary: Library = {
      ...library,
      timeStamps: {
        ...library.timeStamps,
        updatedAtTimeZone: isReviewing
          ? timeZone.format('YYYY-MM-DDTHH:mm:sszz')
          : library.timeStamps.updatedAtTimeZone,
        updatedAtUTC: time.utc().format(),
      },
      hasBeenReviewed,
      reviewerEmail: email,
    }
    if (!isReviewing && !readyForReview) {
      dispatch({
        type: String(SAVE_TO_LIBRARY),
        payload: {
          library: updatedLibrary,
          results,
          readyForReview: readyForReview || saveType === 'ready_for_review',
        },
      })
    } else {
      dispatch({
        type: String(PUSH_REVIEWED_LIBRARY_TEMP_CLOUD),
        payload: {
          library: cloneDeep(updatedLibrary),
          results,
          s3ImageKey: review?.imageS3Key,
          labelS3Key: review?.labelS3Key,
          reviewType,
          readyForReview: readyForReview || saveType === 'ready_for_review',
        },
      })
      dispatch({
        type: String(PUSH_REVIEWED_LIBRARY_LOCAL),
        payload: {
          update: {
            library: cloneDeep(updatedLibrary),
            results,
            reviewType,
            readyForReview: readyForReview || saveType === 'ready_for_review',
            s3ImageKey: review?.imageS3Key,
            labelS3Key: review?.labelS3Key,
          },
        },
      })
    }
    navigate(-1)
  }

  const onNavigatingAwayPositiveActionPressed = () => {
    toggleWarningDialog()
    navigate(-1)
  }

  const onSaveSiteDetailPressed = async (
    siteDetail: SiteDetail,
    materialSelected: string
  ) => {
    setReviewSiteDetail(siteDetail)
    setMaterialSelected(materialSelected)
    setOpenSiteDetailDialog(false)
    dispatch({
      type: String(SELECT_PANEL),
      payload: {
        selectedPanel: materialSelected,
      },
    })
    if (showResultPressed) {
      if (!(await checkIfPanelIsValid(materialSelected))) {
        setShowPanelWarning(true)
        return
      }
      if (!wearInfoData) {
        setShowWearAnalysisDialog(true)
      } else {
        setShowWearAnalysisDialog(false)
        dispatch({
          type: String(START_ANALYSIS),
          payload: {
            info: {
              ...wearInfoData,
            },
          },
        })
      }
    } else if (saveType) {
      onSavePressed(false, saveType === 'ready_for_review')
    }
  }

  const deleteImage = () => {
    if (!review) {
      return
    }
    const time = moment()
    const timeZone = moment.tz(moment.tz.guess())
    dispatch({
      type: String(DELETE_SELECTED_LIBRARY),
      payload: {
        library: {
          ...library,
          timeStamps: {
            ...library.timeStamps,
            updatedAtTimeZone: timeZone.format('YYYY-MM-DDTHH:mm:sszz'),
            updatedAtUTC: time.utc().format(),
          },
          modifiedBy: firstName + ' ' + lastName,
        },
        reviewType,
        s3ImageKey: review.imageS3Key,
        labelS3Key: review.labelS3Key,
        thumbnailS3Key: review.thumbnailS3Key,
      },
    })
    setOpenDeleteWarning(false)
    navigate(-2)
  }

  let activeDrawer = (
    <ResultsDrawer
      isReviewing={isReviewing}
      onSavePressed={onSavePressed}
      openDeleteWarning={() => setOpenDeleteWarning(true)}
    />
  )
  if (activeTab === 1) {
    activeDrawer = (
      <WearDrawer
        isReviewing={isReviewing}
        onSavePressed={onSavePressed}
        openDeleteWarning={() => setOpenDeleteWarning(true)}
      />
    )
  } else if (activeTab === 2) {
    activeDrawer = (
      <EfficiencyDrawer
        isReviewing={isReviewing}
        onSavePressed={onSavePressed}
        openDeleteWarning={() => setOpenDeleteWarning(true)}
      />
    )
  }

  // @ts-ignore
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: isLandscape ? 'row-reverse' : 'column',
          height: '100%',
        }}
      >
        <div style={{ flex: 1, overflow: 'hidden' }}>
          {isReviewing && !startAnalysis && (
            <Fab
              color='primary'
              onClick={() => setOpenDeleteWarning(true)}
              style={{
                position: 'fixed',
                right: 0,
                zIndex: 6000,
                top: '8%',
                marginRight: '2em',
              }}
            >
              <DeleteForever />
            </Fab>
          )}
          {!startAnalysis && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'fixed',
                right: 0,
                bottom: '15%',
                marginRight: '2em',
                zIndex: 1,
              }}
            >
              <>
                {(!useBoundingBox || useBoundingBox === 'rectangle') && (
                  <Fab
                    color='secondary'
                    style={{
                      marginBottom: '1em',
                      backgroundColor: '#C4D400',
                    }}
                    onClick={() => {
                      setCanEdit(false)
                      setUseBoundingBox('polygon')
                      setActiveRect(undefined)
                    }}
                  >
                    <ArrowForward />
                  </Fab>
                )}
                {useBoundingBox === 'polygon' && (
                  <>
                    {canShowActionButtons && (
                      <Fab
                        color='secondary'
                        style={{
                          marginBottom: '1em',
                        }}
                        onClick={() => onShowResultPressed(undefined, false)}
                      >
                        <Visibility />
                      </Fab>
                    )}
                    <Fab
                      color='secondary'
                      style={{
                        backgroundColor: '#C4D400',
                      }}
                      onClick={() => setCanEdit((current) => !current)}
                    >
                      {canEdit ? <Close /> : <Edit />}
                    </Fab>
                  </>
                )}
              </>

              {(!useBoundingBox || useBoundingBox === 'rectangle') && (
                <>
                  {canEdit && (
                    <Fab
                      color='secondary'
                      onClick={() => {
                        setCanEdit(false)
                        setActiveRect(undefined)
                      }}
                    >
                      <Close />
                    </Fab>
                  )}

                  {!canEdit && (
                    <Fab
                      color='secondary'
                      onClick={() => {
                        setUseBoundingBox('rectangle')
                        setCanEdit(true)
                      }}
                    >
                      <Edit />
                    </Fab>
                  )}
                </>
              )}
            </div>
          )}
          {startAnalysis && activeDrawer}
          {!startAnalysis && !!image && (
            <WearAppTransform
              onValuesSelected={onValuesSelected}
              onDeleteBoundingBox={onDeleteRectangle}
              createBoundingBox={addRectangle}
              boundingBoxes={boundingBoxes}
              onBoundingBoxChanged={onRectangleChanged}
              useBoundingBox={useBoundingBox}
              canEdit={canEdit}
              activeRect={activeRect}
              setActiveRect={setActiveRect}
              openFailureModes={openFailureModes}
              setOpenFailureModes={setOpenFailureModes}
              openCvCanvasRef={openCvCanvasRef}
              templateRef={templateRef}
              polygonPts={
                library && library.polygonPts
                  ? [
                      library.polygonPts.tl,
                      library.polygonPts.tr,
                      library.polygonPts.br,
                      library.polygonPts.bl,
                    ]
                  : undefined
              }
            />
          )}
        </div>
        {!startAnalysis && (
          <div
            style={{
              width: isLandscape ? 'auto' : '100%',
              height: isLandscape ? '100%' : 'auto',
              flexDirection: isLandscape ? 'column' : 'row',
              display: 'flex',
              justifyContent: isLandscape ? 'center' : 'space-between',
              alignItems: 'center',
              padding: '2em',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: isLandscape ? 'row' : 'column-reverse',
              }}
            >
              <div
                style={{
                  flexDirection: isLandscape ? 'column' : 'row',
                  display: 'flex',
                  justifyContent: isLandscape ? 'center' : 'space-between',
                  marginTop: isLandscape || !activeRect ? 0 : '1em',
                  marginRight: isLandscape ? '1em' : 0,
                  width: '100%',
                }}
              >
                <Fab
                  color='primary'
                  style={{ marginBottom: isLandscape ? '1.5em' : 0 }}
                  onClick={() => {
                    if (
                      (!!library.failureModes?.length ||
                        reviewSiteDetail?.status !== 'Replaced') &&
                      reviewType &&
                      !['reviewed', 'annotated'].includes(reviewType)
                    ) {
                      setShowReadyToReviewDialog(true)
                    } else {
                      onSavePressed(false)
                    }
                  }}
                >
                  <Save />
                </Fab>
                <Fab
                  color='primary'
                  style={{ marginBottom: isLandscape ? '1.5em' : 0 }}
                  onClick={() => setOpenSiteDetailDialog(true)}
                >
                  <Assignment />
                </Fab>
                <Fab color='primary' onClick={onBackButtonPressed}>
                  <ArrowBack />
                </Fab>
              </div>

              <div
                style={{
                  flexDirection: isLandscape ? 'column' : 'row',
                  justifyContent: isLandscape ? 'center' : 'space-between',
                  display:
                    activeRect && useBoundingBox === 'rectangle'
                      ? 'flex'
                      : 'none',
                  alignItems: 'center',
                  zIndex: 10,
                  padding: '1em',
                  backgroundColor: 'rgba(0, 112, 115, 0.07)',
                  borderRadius: 5,
                  bottom: '15%',
                }}
              >
                <Fab
                  size='small'
                  style={{
                    backgroundColor: 'red',
                    color: 'white',
                  }}
                  onClick={() => {
                    if (activeRect) {
                      onDeleteRectangle(activeRect)
                      setActiveRect(undefined)
                    }
                  }}
                >
                  <Delete />
                </Fab>

                <span style={{ fontSize: '1.5em' }}>
                  {boundingBoxes.find(({ id }) => id === activeRect)
                    ?.failureModes?.length ?? 0}
                </span>

                <Fab
                  size='small'
                  onClick={() => {
                    setOpenFailureModes(true)
                  }}
                >
                  <Add />
                </Fab>
              </div>
            </div>
          </div>
        )}
        {openPanelDialog && (
          <PanelTypes
            onValueSelected={setMaterialSelected}
            selectedValue={materialSelected}
            onClose={handleClose}
          />
        )}
        <Dialog
          open={openWarningDialog}
          // @ts-ignore
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle>{'Unsaved Image'}</DialogTitle>
          <DialogContent>
            <DialogContentText
              variant='caption'
              id='alert-dialog-slide-description'
            >
              {navigatingAwayWarning}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ color: 'red' }}
              onClick={onNavigatingAwayPositiveActionPressed}
            >
              Yes
            </Button>
            <Button onClick={toggleWarningDialog}>No</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={!!boundingBoxWithOtherFailure}
          keepMounted
          onClose={() => setBoundingBoxWithOtherFailure(undefined)}
        >
          <DialogTitle>Enter failure mode for "Other Failures"</DialogTitle>
          <DialogContent>
            <TextField
              onChange={(event) => setFreeTextFailureMode(event.target.value)}
              style={{ width: '100%' }}
              label='Failure mode'
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!freeTextFailureMode.length}
              style={{ color: 'red' }}
              onClick={onFreeFailureModeTextAdded}
            >
              Ok
            </Button>
            <Button onClick={() => setBoundingBoxWithOtherFailure(undefined)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {openSiteDetailDialog && (
          <SiteDetailDialog
            siteDetail={reviewSiteDetail}
            materialSelected={materialSelected}
            onSavePressed={onSaveSiteDetailPressed}
            onClose={() => setOpenSiteDetailDialog(false)}
            isAnalysis={useBoundingBox === 'polygon'}
          />
        )}
        <WarningDialog
          open={openMaterialWarning}
          onClose={() => setOpenMaterialWarning(false)}
          title='No panel type selected'
          description='Do you want to save this image without a panel type?'
          positiveButtonText='Yes'
          negativeButtonText='No'
          onNegativeButtonClicked={() => {
            setOpenSiteDetailDialog(true)
            setOpenMaterialWarning(false)
          }}
          onPositiveButtonClicked={() => {
            onSavePressed()
            setOpenMaterialWarning(false)
          }}
        />

        <WarningDialog
          open={openDeleteWarning}
          onClose={() => setOpenDeleteWarning(false)}
          title='Delete image'
          description='Are you sure you want to delete this image?'
          positiveButtonText='Yes'
          negativeButtonText='No'
          onNegativeButtonClicked={() => {
            setOpenDeleteWarning(false)
          }}
          onPositiveButtonClicked={deleteImage}
        />
        <WarningDialog
          open={showPanelWarning}
          onClose={() => setShowPanelWarning(false)}
          title='Invalid Panel'
          description='WearApp is not currently available for the selected Panel type'
          positiveButtonText='Ok'
          onPositiveButtonClicked={() => setShowPanelWarning(false)}
        />

        <Dialog open={showReadyToReviewDialog}>
          <DialogTitle>Upload Confirmation</DialogTitle>
          <DialogContent>
            Do you want to save for later or send for review? You will not be
            able to modify once sent for review.
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowReadyToReviewDialog(false)}>
              Cancel
            </Button>
            <Button
              style={{ color: '#007073' }}
              onClick={() => onSavePressed(false, false)}
            >
              Save For Later
            </Button>
            <Button
              style={{ color: 'red' }}
              onClick={() => onSavePressed(false, true)}
            >
              Send For Review
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showLoader}>
          <DialogContent>
            <CircularProgress color='inherit' size={20} />
          </DialogContent>
        </Dialog>
        {showWearAnalysisDialog && (
          <WearAnalysisInfoDialog
            onCancelPressed={() => setShowWearAnalysisDialog(false)}
            onSkipPressed={onShowResultPressed}
            onSubmitPressed={onShowResultPressed}
          />
        )}
      </div>
    </>
  )
}
