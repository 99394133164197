/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLocations = /* GraphQL */ `
  query GetLocations($SiteId: String!, $Id: String!) {
    getLocations(SiteId: $SiteId, Id: $Id) {
      SiteId
      Id
      Name
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: TableCustomersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Id
        Name
      }
      nextToken
    }
  }
`;
export const listDecks = /* GraphQL */ `
  query ListDecks(
    $filter: TableDecksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDecks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ScreenId
        Id
        DeckHeader {
          EquipmentId
          DeckLevel
        }
      }
      nextToken
    }
  }
`;
export const listDeckRevisions = /* GraphQL */ `
  query ListDeckRevisions(
    $filter: TableDeckRevisionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeckRevisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        DeckId
        Id
        RevisionNumber
      }
      nextToken
    }
  }
`;
export const listFailureModes = /* GraphQL */ `
  query ListFailureModes(
    $filter: TableFailureModesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFailureModes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Failure_Mode
        Failure_Category
      }
      nextToken
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: TableLocationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        SiteId
        Id
        Name
      }
      nextToken
    }
  }
`;
export const listMaterials = /* GraphQL */ `
  query ListMaterials(
    $filter: TableMaterialsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaterials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Id
        MaterialDescription
        MaterialNumber
      }
      nextToken
    }
  }
`;
export const listScreens = /* GraphQL */ `
  query ListScreens(
    $filter: TableScreensFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScreens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        LocationId
        Id
        Name
      }
      nextToken
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites(
    $filter: TableSitesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        CustomerId
        Id
        Name
      }
      nextToken
    }
  }
`;
export const getObjectByKey = /* GraphQL */ `
  query GetObjectByKey($bucket: String!, $key: String!) {
    getObjectByKey(bucket: $bucket, key: $key) {
      id
      key
      title
      bucket
      content
    }
  }
`;
export const listObjects = /* GraphQL */ `
  query ListObjects($bucket: String!) {
    listObjects(bucket: $bucket) {
      bucket
      content
    }
  }
`;

export const listPanels = /* GraphQL */ `
  query ListPanels(
    $filter: TablePanelsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPanels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        AP_BottomZone_H34
        AP_BottomZone_W4
        AP_MidZone_H23
        AP_MidZone_W3
        AP_TopZone_H12
        AP_TopZone_W2
        Aperture_Size_L
        Aperture_Size_W1
        Aperture_Type
        Class
        Description
        Panel_Size_H
        Panel_Size_L
        Panel_Size_W
        Shape_Change_WID
        Tool_Number
        Working_Depth_Lig
      }
      nextToken
    }
  }
`;
