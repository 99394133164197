import Navbar from './Navbar/Navbar'
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { WearAppRouter } from './WearAppRouter'
import Footer from './Footer'
import { Button, Fab, Snackbar } from '@material-ui/core'
import { WarningDialog } from './WarningDialog'
import { useDispatch, useSelector } from 'react-redux'
import { WearAppState } from '../store/types'
import { useNetworkState } from 'react-use'
import {
  SET_WENT_OFFLINE,
  TOGGLE_NEW_VERSION_AVAILABLE,
} from '../store/misc/miscActions'
import { PUSH_LIBRARY_TO_CLOUD } from '../store/library/libraryActions'
import { useLocation, useNavigate } from 'react-router-dom'
import { FETCH_ALL_FAILURE_MODES } from '../store/failureMode/failureModeActions'
import { FETCH_ALL_PANEL_TYPES } from '../store/panelType/panelTypeActions'
import { makeStyles } from '@material-ui/core/styles'
import { ArrowBack } from '@material-ui/icons'
import { WearAppContext } from '../context/WearAppContext'
import { useFetchMiscData } from '../hooks/useFetchMiscData'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  routerContainer: {
    flex: 1,
    overflowX: 'hidden',
  },
})

export const Main: FunctionComponent = () => {
  const classes = useStyles()
  const isLoggedIn = useSelector(
    (store: WearAppState) => store.auth?.isLoggedIn
  )
  const libraries = useSelector(
    (state: WearAppState) => state.library.libraries
  )
  const results = useSelector((state: WearAppState) => state.library.results)
  const isPushingLibrary = useSelector(
    (state: WearAppState) => state.library.isWorking
  )
  const { online } = useNetworkState()
  const newVersion = useSelector(
    (state: WearAppState) => state.misc?.newVersionAvailable
  )
  const wentOffline = useSelector(
    (state: WearAppState) => state.misc?.wentOffline
  )
  const [showOfflineWarning, setShowOfflineWarning] = useState<boolean>(false)

  useFetchMiscData()

  const dispatch = useDispatch()

  const toggleNewVersionOff = () => {
    dispatch({
      type: String(TOGGLE_NEW_VERSION_AVAILABLE),
      payload: {
        newVersion: undefined,
      },
    })
  }

  const handleUpdate = () => {
    if (newVersion) {
      const registration = newVersion.waiting
      if (registration) {
        registration.postMessage({ type: 'SKIP_WAITING' })
        registration.addEventListener('statechange', (event) => {
          if ((event.target as ServiceWorker).state === 'activated') {
            console.log('new version active')
            window.location.reload()
          }
        })
      }
      toggleNewVersionOff()
    }
  }

  useEffect(() => {
    if (!online || isPushingLibrary) {
      return
    }
    const libraryNeedingUpdate = libraries.filter(
      ({ savedToCloud }) => !savedToCloud
    )
    if (libraryNeedingUpdate.length > 0) {
      dispatch({
        type: String(PUSH_LIBRARY_TO_CLOUD),
        payload: {
          libraries: libraryNeedingUpdate,
          results: results,
        },
      })
    }
  }, [online, libraries, dispatch, isPushingLibrary, results])

  const { pathname } = useLocation()

  useEffect(() => {
    if (online && wentOffline) {
      dispatch(String(FETCH_ALL_FAILURE_MODES))
      dispatch(String(FETCH_ALL_PANEL_TYPES))
      dispatch({
        type: SET_WENT_OFFLINE,
        payload: {
          wentOffline: false,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (!online && isLoggedIn) {
      setShowOfflineWarning(true)
    }
  }, [])

  const navigate = useNavigate()
  const { footerDimension, showBackButton } = useContext(WearAppContext)

  const onBackButtonPressed = () => {
    if (pathname.includes('audit')) {
      navigate('/dashboard', {
        replace: true,
      })
    } else {
      navigate(-1)
    }
  }

  return (
    <>
      <div className={`${classes.container} main-container`}>
        {isLoggedIn && <Navbar />}
        <div
          className={classes.routerContainer}
          style={{ overflowY: pathname === '/camera' ? 'hidden' : 'auto' }}
        >
          <WearAppRouter />
        </div>

        {!['/wear-scanning', '/failure-mode-detection'].includes(pathname) &&
          isLoggedIn && <Footer />}
        {showBackButton && isLoggedIn && (
          <Fab
            color='primary'
            style={{
              position: 'fixed',
              bottom: 0,
              right: 0,
              marginBottom: (footerDimension?.height ?? 0) + 10,
              marginRight: '1em',
              backgroundColor: 'rgba(0,0,0,.6)',
            }}
            onClick={onBackButtonPressed}
          >
            <ArrowBack />
          </Fab>
        )}
      </div>
      <Snackbar
        open={!!newVersion}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{ bottom: 25 }}
        action={
          <>
            <Button size='small' color='secondary' onClick={handleUpdate}>
              Yes
            </Button>
            <Button
              style={{ marginLeft: '.5em', color: 'red' }}
              size='small'
              color='secondary'
              onClick={toggleNewVersionOff}
            >
              No
            </Button>
          </>
        }
        message='There is a new version of the app available. Would you like to download this version?'
      />

      <WarningDialog
        open={showOfflineWarning}
        onClose={() => setShowOfflineWarning(false)}
        title="You're offline"
        description='CaptureApp is working with the last updated data set and may be out of date. Please reconnect to the Internet to download the latest data'
        positiveButtonText='Ok'
        onPositiveButtonClicked={() => setShowOfflineWarning(false)}
      />
    </>
  )
}
