// @ts-nocheck
import { fabric } from 'fabric'
import { useCallback } from 'react'
import { BoundingBox, WearAppState } from '../store/types'
import cv from 'opencv-ts'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'

export const usePolygonFabric = () => {
  const polygon = useSelector((state: WearAppState) => state.analysis.bbox)
  const makeCircleVis = useCallback(
    (left: number, top: number, color: string) => {
      const c = new fabric.Circle({
        left: left,
        top: top,
        originX: 'center',
        hasControls: false,
        hasBorders: false,
        selectable: true,
        originY: 'center',
        strokeWidth: 3,
        stroke: 'rgba(51,51,51,0.2)',
        radius: isMobile ? 400 : 150,
        fill: 'rgba(51,51,51,0.2)',
      })
      c.bringToFront()
      c.center()

      return c
    },
    []
  )

  const makeCircle = useCallback((left: number, top: number, color: string) => {
    const c = new fabric.Circle({
      left: left,
      top: top,
      originX: 'center',
      originY: 'center',
      hasControls: false,
      hasBorders: false,
      selectable: false,
      strokeWidth: 4,
      radius: isMobile ? 50 : 24,
      stroke: 'lime',
      fill: 'rgba(0,0,0,0)',
    })
    c.center()

    return c
  }, [])

  const makeLine = useCallback((coords) => {
    const line = new fabric.Line(coords, {
      stroke: 'yellow',
      strokeWidth: 8,
      selectable: false,
      evented: false,
    })
    line.hasControls = line.hasBorders = false
    return line
  }, [])

  const createPolygon = useCallback(
    (
      canvas: fabric.Canvas,
      boundingBox: BoundingBox,
      updatePosition: (index: number, value: number[]) => void,
      updatePolygonAllCoordinates: (coors: number[][]) => void,
      coords?: number[][]
    ) => {
      let p1, p2, p3, p4
      if (coords?.[0]?.length > 0) {
        p1 = coords?.[0]
        p2 = coords?.[1]
        p3 = coords?.[2]
        p4 = coords?.[3]
      } else {
        p1 = [boundingBox.left, boundingBox.top - boundingBox.height]
        p2 = [
          boundingBox.left + boundingBox.width,
          boundingBox.top - boundingBox.height,
        ]
        p3 = [boundingBox.left + boundingBox.width, boundingBox.top]
        p4 = [boundingBox.left, boundingBox.top]

        // Calculate the center of the bounding box
        const centerX = (p1[0] + p2[0] + p3[0] + p4[0]) / 4
        const centerY = (p1[1] + p2[1] + p3[1] + p4[1]) / 4
        const center = [centerX, centerY]

        const scalingFactor = 1.5

        // Translate, scale and translate the bounding box
        // TODO: not sure why the boundingBox.height needs to be added to the y values
        p1[0] = (p1[0] - center[0]) * scalingFactor + center[0]
        p1[1] =
          (p1[1] - center[1]) * scalingFactor + center[1] + boundingBox.height
        p2[0] = (p2[0] - center[0]) * scalingFactor + center[0]
        p2[1] =
          (p2[1] - center[1]) * scalingFactor + center[1] + boundingBox.height
        p3[0] = (p3[0] - center[0]) * scalingFactor + center[0]
        p3[1] =
          (p3[1] - center[1]) * scalingFactor + center[1] + boundingBox.height
        p4[0] = (p4[0] - center[0]) * scalingFactor + center[0]
        p4[1] =
          (p4[1] - center[1]) * scalingFactor + center[1] + boundingBox.height
      }

      const line1 = makeLine([p1[0], p1[1], p2[0], p2[1]])
      canvas.add(line1)
      const line2 = makeLine([p2[0], p2[1], p3[0], p3[1]])
      canvas.add(line2)
      const line3 = makeLine([p3[0], p3[1], p4[0], p4[1]])
      canvas.add(line3)
      const line4 = makeLine([p4[0], p4[1], p1[0], p1[1]])
      canvas.add(line4)

      const node1 = makeCircle(p1[0], p1[1])
      const node1b = makeCircleVis(p1[0], p1[1])
      node1b.line_a = line4
      node1b.line_b = line1
      node1b.node = node1
      node1b.id = 0
      node1b.hasControls = node1b.hasBorders = false
      node1.hasControls = node1.hasBorders = false
      canvas.add(node1)
      canvas.add(node1b)

      const node2 = makeCircle(p2[0], p2[1])
      const node2b = makeCircleVis(p2[0], p2[1])
      node2b.line_a = line1
      node2b.line_b = line2
      node2b.node = node2
      node2b.id = 1
      node2b.hasControls = node2b.hasBorders = false
      node2.hasControls = node2.hasBorders = false
      canvas.add(node2)
      canvas.add(node2b)

      const node3 = makeCircle(p3[0], p3[1])
      const node3b = makeCircleVis(p3[0], p3[1])
      node3b.line_a = line2
      node3b.line_b = line3
      node3b.node = node3
      node3b.id = 2
      node3b.hasControls = node3b.hasBorders = false
      node3.hasControls = node3.hasBorders = false
      canvas.add(node3)
      canvas.add(node3b)

      const node4 = makeCircle(p4[0], p4[1])
      const node4b = makeCircleVis(p4[0], p4[1])
      node4b.line_a = line3
      node4b.line_b = line4
      node4b.node = node4
      node4b.id = 3
      node4b.hasControls = node4b.hasBorders = false
      node4.hasControls = node4.hasBorders = false
      canvas.add(node4)
      canvas.add(node4b)

      updatePolygonAllCoordinates([
        [node1.left, node1.top], // top left
        [node2.left, node2.top], // top right
        [node3.left, node3.top], // bottom right
        [node4.left, node4.top], // bottom left
      ])

      canvas.on('object:moving', function(e) {
        const p = e.target
        if (!p.node) {
          return
        }
        p.line_a.set({ x2: p.left, y2: p.top })
        p.line_b.set({ x1: p.left, y1: p.top })
        p.node.set({ left: p.left, top: p.top })
        console.log('id', p.id)
        updatePosition(p.id, [p.left, p.top])
        canvas.renderAll()
      })
    },
    [makeLine, makeCircleVis, makeCircle]
  )

  const getHomographyCanvas = useCallback((img_src, position) => {
    const { height, width } = document.getElementById('panel_image')
    const dst = new cv.Mat()
    const mindim = Math.min(height, width)
    const dsize = new cv.Size(mindim, mindim)
    // console.log('pos:', position.flat(), imageHeight, imageWidth)
    const base = [
      [0, 0],
      [mindim - 1, 0],
      [mindim - 1, mindim - 1],
      [0, mindim - 1],
    ]
    const srcTri = cv.matFromArray(4, 1, cv.CV_32FC2, position.flat())
    const dstTri = cv.matFromArray(4, 1, cv.CV_32FC2, base.flat())

    const M = cv.getPerspectiveTransform(srcTri, dstTri) //Believe, this is the main method for perspective transformation
    cv.warpPerspective(img_src, dst, M, dsize)

    cv.imshow('canvas_cv', dst)
    return dst
  }, [])

  const startAnalysis = useCallback(() => {
    const image_src = cv.imread('panel_image')
    getHomographyCanvas(image_src, polygon)
  }, [getHomographyCanvas, polygon])

  return {
    createPolygon,
    startAnalysis,
  }
}
