import { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { WarningDialog } from './WarningDialog'

export const SiteDetailWarning: FunctionComponent<{
  open: boolean
  onClose: () => void
  onNavigated?: () => void
}> = ({ open, onClose, onNavigated }) => {
  const navigate = useNavigate()
  const onPositiveButtonClicked = () => {
    onClose()
    navigate('/dashboard/audit')
    onNavigated && onNavigated()
  }

  const onNegativeButtonClicked = () => {
    onClose()
    navigate('/dashboard/camera')
    onNavigated && onNavigated()
  }

  return (
    <WarningDialog
      open={open}
      onClose={onClose}
      title={'Site Details confirmation'}
      description={'Please enter/update site details before taking picture.'}
      positiveButtonText='Proceed to Site Details'
      onPositiveButtonClicked={onPositiveButtonClicked}
      negativeButtonText={'Ignore'}
      onNegativeButtonClicked={onNegativeButtonClicked}
    />
  )
}
