import {
  Library,
  LibraryType,
  Results,
  Review,
  ReviewLibraryState,
} from '../types'
import { Action, handleActions } from 'redux-actions'
import {
  FETCH_REVIEW_LIBRARY_IMAGE_SUCCESS,
  FETCH_REVIEW_LIBRARY_SUCCESS,
  PUSH_REVIEWED_LIBRARY_LOCAL,
  PUSH_REVIEWED_LIBRARY_SUCCESS,
  TOGGLE_IS_FETCHING,
} from './reviewLibraryActions'

const initialState: ReviewLibraryState = {
  annotated: [],
  notAnnotated: [],
  reviewed: [],
  yourImages: [],
}

export type ReviewLibraryPayload = {
  data: {
    annotated: Array<Review>
    notAnnotated: Array<Review>
    yourImages: Array<Review>
    admin: Array<Review>
    results: Results
  }
  update: {
    library: Library & { imageS3Key: string }
    reviewType: LibraryType
    results: Results
    readyForReview?: boolean
    s3ImageKey?: string
    labelS3Key?: string
  }
  imageUpdate: {
    images: Record<string, string>
    reviewType: LibraryType
    imageKeys: string[]
  }
}

export const reviewLibraryReducer = handleActions<
  ReviewLibraryState,
  ReviewLibraryPayload
>(
  {
    [String(FETCH_REVIEW_LIBRARY_SUCCESS)]: (
      reviewLibraryState: ReviewLibraryState,
      { payload }: Action<ReviewLibraryPayload>
    ) => ({
      ...reviewLibraryState,
      ...payload.data,
      lastSynced: new Date().getTime(),
    }),
    [String(PUSH_REVIEWED_LIBRARY_SUCCESS)]: (
      reviewLibraryState: ReviewLibraryState,
      { payload }: Action<ReviewLibraryPayload>
    ) => {
      const review = reviewLibraryState[payload.update.reviewType]
      const libraryImage = payload.update.library.imageS3Key

      console.log('review', review)
      console.log('payload', payload.update)

      return {
        ...reviewLibraryState,
        [payload.update.reviewType]: review.filter(
          ({ imageS3Key }) => imageS3Key !== libraryImage
        ),
      }
    },
    [String(PUSH_REVIEWED_LIBRARY_LOCAL)]: (
      reviewLibraryState: ReviewLibraryState,
      { payload }: Action<ReviewLibraryPayload>
    ) => {
      const library = payload.update.library
      const image = library.image
      // @ts-ignore
      delete library.image
      const reviewTypeData =
        reviewLibraryState[payload.update.reviewType] ??
        reviewLibraryState.reviewed

      let review: Review = {
        image,
        label: library,
        imageS3Key: '',
        imageUrl: '',
        labelS3Key: '',
        thumbnailS3Key: '',
        thumbnailUrl: '',
        readyForReview: payload.update.readyForReview,
      }
      if (reviewTypeData) {
        console.log('reviewTypeData', reviewTypeData)
        console.log('payload.update', payload.update)
        const imageIndex = reviewTypeData?.findIndex(
          ({ imageS3Key }) => imageS3Key === payload.update.s3ImageKey
        )
        review = {
          ...review,
          labelS3Key: reviewTypeData[imageIndex]?.labelS3Key,
          imageS3Key: reviewTypeData[imageIndex]?.imageS3Key,
          imageUrl: reviewTypeData[imageIndex]?.imageUrl,
          thumbnailUrl: reviewTypeData[imageIndex]?.thumbnailUrl,
          thumbnailS3Key: reviewTypeData[imageIndex]?.thumbnailS3Key,
        }
        reviewTypeData.splice(imageIndex, 1)
      }
      review.hasBeenReviewed =
        !!library.failureModes?.length ||
        ['New', 'Kept'].includes(library.siteDetail?.status ?? '')
      if (
        payload.update.reviewType === 'notAnnotated' ||
        payload.update.reviewType === 'yourImages'
      ) {
        review.hasBeenReviewed =
          review.hasBeenReviewed && payload.update.readyForReview
      }

      return {
        ...reviewLibraryState,
        [payload.update.reviewType]: [review, ...reviewTypeData],
      }
    },
    [String(TOGGLE_IS_FETCHING)]: (reviewLibraryState: ReviewLibraryState) => ({
      ...reviewLibraryState,
      isFetching: !reviewLibraryState.isFetching,
    }),
    [String(FETCH_REVIEW_LIBRARY_IMAGE_SUCCESS)]: (
      state: ReviewLibraryState,
      { payload }: Action<ReviewLibraryPayload>
    ) => {
      const newState = { ...state }
      const imageUpdate = payload.imageUpdate
      const reviews = newState[imageUpdate.reviewType]

      for (const imageKey in imageUpdate.images) {
        const index = reviews.findIndex((rev) => rev.imageS3Key === imageKey)
        console.log('index', index)
        if (index !== -1) {
          reviews[index] = {
            ...reviews[index],
            image: imageUpdate.images[imageKey],
            isFetching: false,
          }
        }
      }

      return {
        ...state,
        [imageUpdate.reviewType]: [...reviews],
      }
    },
  },
  initialState
)
