import { makeStyles } from '@material-ui/core/styles'
import { useNavigate } from 'react-router-dom'
import { Avatar, Button, CircularProgress, Typography } from '@material-ui/core'
import { version } from '../../../package.json'
import { useDispatch, useSelector } from 'react-redux'
import { LibraryType, WearAppState } from '../../store/types'
import { FunctionComponent, useEffect } from 'react'
import moment from 'moment'
import { FETCH_REVIEW_LIBRARY } from '../../store/reviewLibrary/reviewLibraryActions'
import { useSetShowBackButton } from '../../hooks/useSetShowBackButton'
import { red } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  welcome: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  button: {
    fontFamily: 'arial',
    fontSize: 14,
    color: '#F2F2F2',
    width: '180px',
    height: '40px',
    backgroundColor: '#006068',
    borderRadius: '.5em',
  },
  cancel: {
    width: 'auto',
    borderColor: '#006068',
    marginTop: '2em',
    borderRadius: '.5em',
  },
  beta_string: {
    fontFamily: 'arial',
    fontSize: 14,
    color: '#F2F2F2',
  },
  nonAnnotatedContainer: {
    width: '180px',
    marginTop: '2em',
  },
  red: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '1em',
    marginLeft: '-.8em',
    marginBottom: '.8em',
    display: 'inline-flex',
    position: 'fixed',
  },
}))

const LibraryButton: FunctionComponent<{
  type: LibraryType
  length: number
}> = ({ type, length }) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const getTitle = () => {
    if (type === 'annotated') {
      return 'Ready For Review'
    } else if (type === 'notAnnotated') {
      return 'All Images'
    } else if (type === 'reviewed') {
      return 'Reviewed'
    }
    return 'Your Images'
  }
  return (
    <div className={classes.nonAnnotatedContainer}>
      <Button
        size='medium'
        className={`${classes.button}`}
        variant='outlined'
        onClick={() => {
          if (length === 0) {
            return
          }
          navigate('/dashboard/review/images', {
            state: { type },
          })
        }}
      >
        {getTitle()}
      </Button>
      <Avatar className={classes.red}>{length}</Avatar>
    </div>
  )
}

export const Review: FunctionComponent = () => {
  const classes = useStyles()
  const isFetchingImages = useSelector(
    (state: WearAppState) => state.reviewLibrary.isFetching
  )
  const lastSyncDate = useSelector(
    (state: WearAppState) => state.reviewLibrary.lastSynced
  )
  const annotated = useSelector(
    (state: WearAppState) => state.reviewLibrary.annotated
  )
  const notAnnotated = useSelector(
    (state: WearAppState) => state.reviewLibrary.notAnnotated
  )
  const yourImages = useSelector(
    (state: WearAppState) => state.reviewLibrary.yourImages
  )
  const reviewed = useSelector(
    (state: WearAppState) => state.reviewLibrary.reviewed
  )
  const shouldForceRefresh = lastSyncDate
    ? moment(moment()).diff(moment(lastSyncDate), 'hours') >= 6
    : false
  const isFetchingReviewLibrary = useSelector(
    (state: WearAppState) => state.reviewLibrary.isFetching
  )
  const dispatch = useDispatch()
  useSetShowBackButton(true)

  useEffect(() => {
    if (shouldForceRefresh && !isFetchingReviewLibrary) {
      dispatch({
        type: String(FETCH_REVIEW_LIBRARY),
      })
    }
  }, [dispatch, shouldForceRefresh, isFetchingReviewLibrary])

  useEffect(() => {
    if (!notAnnotated.length || !annotated.length || shouldForceRefresh) {
      dispatch({
        type: String(FETCH_REVIEW_LIBRARY),
      })
    }
  }, [dispatch, notAnnotated.length, annotated.length, shouldForceRefresh])

  const isReviewer = useSelector(
    (state: WearAppState) => state.auth?.user?.isReviewer
  )

  const isAdmin = useSelector(
    (state: WearAppState) => state.auth?.user?.isAdmin
  )

  return (
    <div className={classes.container}>
      {isFetchingImages && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
          }}
        >
          <CircularProgress color='inherit' size={20} />
        </div>
      )}
      {!isFetchingImages && (
        <>
          <div className={classes.buttonContainer}>
            <Typography variant='h6' className={classes.welcome}>
              {`Choose images you want to review`}
            </Typography>
            <LibraryButton type='yourImages' length={yourImages.length} />
            {(isReviewer || isAdmin) && (
              <>
                <LibraryButton
                  type='notAnnotated'
                  length={notAnnotated.length}
                />
                <LibraryButton type='annotated' length={annotated.length} />
              </>
            )}
            {isAdmin && (
              <LibraryButton type='reviewed' length={reviewed.length} />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <p className={classes.beta_string}>v{version}</p>
          </div>
        </>
      )}
    </div>
  )
}
