import { fabric } from 'fabric'
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { WearAppTransformProps } from './WearAppTransform'
import { BoundingBox, WearAppState } from '../../store/types'
import { useOrientation, useWindowSize } from 'react-use'
import { WearAppContext } from '../../context/WearAppContext'
import { usePolygonFabric } from '../../hooks/usePolygonFabric'
import { useInitFabric } from '../../hooks/useInitFabric'
import { RectangleFabricWrapper } from './rectangle/RectangleFabricWrapper'
import { PolygonFabricWrapper } from './polygon/PolygonFabricWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { SET_POLYGON_BBOX } from '../../store/analysis/analysisActions'
import { debounce } from 'lodash'

export const FabricWrapper: FunctionComponent<WearAppTransformProps> = ({
  useBoundingBox,
  boundingBoxes,
  createBoundingBox,
  onDeleteBoundingBox,
  onValuesSelected,
  onBoundingBoxChanged,
  canEdit,
  activeRect,
  setActiveRect,
  openFailureModes,
  setOpenFailureModes,
  openCvCanvasRef,
  templateRef,
  polygonPts,
}) => {
  const useBoundingBoxRef = useRef<'rectangle' | 'polygon' | undefined>(
    useBoundingBox
  )
  const canEditRef = useRef<boolean>(canEdit)
  const boundingBoxesRef = useRef<Array<BoundingBox>>(boundingBoxes)

  const [canvasId, setCanvasId] = useState<'polygon_canvas' | 'bb_canvas'>(
    useBoundingBoxRef.current === 'polygon' ? 'polygon_canvas' : 'bb_canvas'
  )
  const polygon = useSelector(
    (state: WearAppState) => state.analysis.bbox ?? []
  )
  const polygonRef = useRef<number[][]>([])

  useEffect(() => {
    polygonRef.current = polygon
  }, [polygon])
  const dispatch = useDispatch()
  const {
    canvasRef,
    valueSelected,
    containerRef,
    setValuesSelected,
  } = useInitFabric({
    canvasId,
    boundingBoxesRef,
    useBoundingBoxRef,
    createBoundingBox,
    canEdit: canEditRef,
    setActiveRect,
  })

  useEffect(() => {
    useBoundingBoxRef.current = useBoundingBox
    if (!useBoundingBox) {
      setActiveRect(undefined)
      canvasRef.current?.discardActiveObject()
      canvasRef.current?.renderAll()
    }
    setCanvasId(useBoundingBox === 'polygon' ? 'polygon_canvas' : 'bb_canvas')
  }, [canvasRef, setActiveRect, useBoundingBox])

  useEffect(() => {
    canEditRef.current = canEdit
  }, [canEdit])

  useEffect(() => {
    boundingBoxesRef.current = boundingBoxes
  }, [boundingBoxes])

  const { height } = useWindowSize()
  // const { type } = useOrientation()
  //const isLandscape = type === 'landscape-primary'

  const createFabricRect = useCallback(
    (boundingBox: BoundingBox, isActive: boolean) => {
      const color =
        boundingBox.failureModes?.length === 0 ? 'red' : 'rgb(0, 176, 80)'
      const rect = new fabric.Rect({
        // @ts-ignore
        id: boundingBox.id,
        top: boundingBox.top,
        left: boundingBox.left,
        width: boundingBox.width,
        height: boundingBox.height,
        stroke: color,
        strokeUniform: true,
        lockRotation: true,
        cornerStrokeColor: color,
        transparentCorners: false,
        cornerColor: color,
        cornerStyle: 'circle',
        strokeWidth: 4,
        fill: 'rgba(0,0,0,0)',
        selectable: true,
        evented: true,
        type: useBoundingBoxRef.current,
      })

      rect.hasControls = rect.hasBorders = true
      rect.on('scaling', (e) => {
        if (!useBoundingBoxRef.current || !canEditRef.current) {
          e.e.stopPropagation()
          e.e.preventDefault()
        }
      })

      rect.on('moving', (e) => {
        if (!useBoundingBoxRef.current || !canEditRef.current) {
          e.e.stopPropagation()
          e.e.preventDefault()
        }
      })
      // @ts-ignore
      rect.on('modified', (e) => {
        // @ts-ignore
        const action = e.action as string
        if (action.includes('scale')) {
          onBoundingBoxChanged({
            ...boundingBox,
            width: boundingBox.width * (e.target?.scaleX ?? 1),
            height: boundingBox.height * (e.target?.scaleY ?? 1),
            top: e.target?.top ?? e.target?.oCoords?.tl?.y ?? boundingBox.top,
            left:
              e.target?.left ?? e.target?.oCoords?.tl?.x ?? boundingBox.left,
          })
        } else if (action === 'drag') {
          onBoundingBoxChanged({
            ...boundingBox,
            top: e.target?.top ?? e.target?.oCoords?.tl?.y ?? boundingBox.top,
            left:
              e.target?.left ?? e.target?.oCoords?.tl?.x ?? boundingBox.left,
          })
        }
        // @ts-ignore
        rect.set('active', true)
        canvasRef.current?.renderAll()
      })
      canvasRef.current?.add(rect)
      if (isActive) {
        const objects = canvasRef?.current?.getObjects() ?? []
        canvasRef?.current?.setActiveObject(objects[objects?.length - 1])
      }
    },
    []
  )

  const { createPolygon } = usePolygonFabric()

  const hasCreatedPolygon = useRef<boolean>(false)

  const debounceUpdatePolygon = debounce((index, value) => {
    const polygon = boundingBoxesRef.current.find(
      ({ type }) => type === 'polygon'
    )
    console.log('polygon', polygon)
    setTimeout(() => {
      const updatedPolygon = [...polygonRef.current]
      updatedPolygon[index] = value
      dispatch({
        type: String(SET_POLYGON_BBOX),
        payload: {
          bbox: updatedPolygon,
        },
      })
    })
  }, 500)

  const updatePolygonAllCoordinates = useCallback((coords: number[][]) => {
    dispatch({
      type: String(SET_POLYGON_BBOX),
      payload: {
        bbox: coords,
      },
    })
  }, [])

  const createFabricPolygon = useCallback(
    (boundingBox: BoundingBox, isActive: boolean) => {
      if (canvasRef.current) {
        createPolygon(
          canvasRef.current,
          boundingBox,
          debounceUpdatePolygon,
          updatePolygonAllCoordinates,
          polygonPts
        )
        hasCreatedPolygon.current = true
        if (isActive) {
          const objects = canvasRef?.current?.getObjects() ?? []
          canvasRef?.current?.setActiveObject(objects[objects?.length - 1])
        }
      }
    },
    [
      canvasRef,
      createPolygon,
      debounceUpdatePolygon,
      updatePolygonAllCoordinates,
      polygonPts,
    ]
  )

  const { type } = useOrientation()
  const isLandscape = type === 'landscape-primary'
  const { footerDimension, appBarDimension } = useContext(WearAppContext)

  useEffect(() => {
    for (const object of canvasRef.current?.getObjects() ?? []) {
      if (
        object.type !== useBoundingBoxRef.current ||
        // @ts-ignore
        boundingBoxes.some(({ id }) => id === object.id)
      ) {
        continue
      }
      canvasRef.current?.remove(object)
    }
    const actualBoundingBoxes = boundingBoxes.filter(({ type }) => {
      return (
        type === useBoundingBox ||
        (!useBoundingBox && type === 'rectangle') ||
        (useBoundingBox === 'rectangle' && !type)
      )
    })
    for (const boundingBox of actualBoundingBoxes) {
      const object = canvasRef?.current
        ?.getObjects()
        // @ts-ignore
        .find((obj) => obj.id === boundingBox.id)
      let isActive = false
      if (object) {
        // @ts-ignore
        isActive = canvasRef?.current?.getActiveObject()?.id === boundingBox.id
        canvasRef?.current?.remove(object)
      } else {
        isActive = true
      }
      if (boundingBox.type === 'polygon') {
        createFabricPolygon(boundingBox, isActive || boundingBoxes.length === 1)
      } else {
        createFabricRect(boundingBox, isActive || boundingBoxes.length === 1)
      }
    }
  }, [boundingBoxes, canvasRef, canvasId, useBoundingBox])

  // @ts-ignore
  return (
    <div
      id='canvas-wrapper'
      ref={containerRef}
      style={{
        display: 'flex',
        height:
          height -
          (footerDimension?.height ?? 0) -
          (!isLandscape ? 80 : 0) -
          (appBarDimension?.height ?? 0),
        width: '100%',
        flexDirection: 'column-reverse',
        alignItems: 'center',
      }}
    >
      {useBoundingBox === 'polygon' && (
        <PolygonFabricWrapper
          templateRef={templateRef}
          openCvCanvasRef={openCvCanvasRef}
        />
      )}
      {(!useBoundingBox || useBoundingBox === 'rectangle') && (
        <RectangleFabricWrapper
          open={openFailureModes}
          setValuesSelected={setValuesSelected}
          onValuesSelected={onValuesSelected}
          setOpen={setOpenFailureModes}
          valueSelected={valueSelected}
          canvasRef={canvasRef}
          onDeleteBoundingBox={onDeleteBoundingBox}
          activeRect={activeRect}
        />
      )}
    </div>
  )
}
