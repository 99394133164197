import React, { FunctionComponent } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { WearAppState } from '../store/types'

const RequireAuth: FunctionComponent = () => {
  const isUserLoggedIn = useSelector(
    (store: WearAppState) => store.auth?.isLoggedIn
  )
  const { pathname } = useLocation()
  //TODO fix uncaught navigation error
  return isUserLoggedIn ? (
    pathname === '/' ? (
      <Navigate to='/dashboard' />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to='/auth' state={{ path: pathname }} replace />
  )
}

export default RequireAuth
