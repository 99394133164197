import React, { FunctionComponent } from 'react'
import { Drawer } from '@material-ui/core'
import { ActionMenuItems } from './ActionMenuItems'

export const DrawerComponent: FunctionComponent<{
  open: boolean
  setOpen: (open: boolean) => void
}> = ({ open, setOpen }) => {
  return (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <ActionMenuItems onClose={() => setOpen(false)} />
    </Drawer>
  )
}
