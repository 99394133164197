import React, { ChangeEvent, FunctionComponent, useRef, useState } from 'react'
import {
  Button,
  Divider,
  makeStyles,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import {
  AttachFile,
  CameraAlt,
  ExitToApp,
  Home,
  Assignment,
  Refresh,
} from '@material-ui/icons'
import { useDispatch } from 'react-redux'
import { LOG_OUT } from '../../store/auth/authActions'
import { WarningDialog } from '../WarningDialog'
import { SET_PICTURE_DATA } from '../../store/misc/miscActions'
import { SET_PHOTOS } from '../../store/upload/uploadActions'
import { File as SchenckFile } from '../../store/types'
import { useLocation, useNavigate } from 'react-router-dom'
import { SiteDetailWarning } from '../SiteDetailWarning'
import { useFetchMiscData } from '../../hooks/useFetchMiscData'

const useStyles = makeStyles(() => ({
  drawer: {
    width: '20vw',
    flexDirection: 'column',
    display: 'flex',
    paddingTop: '1em',
    justifyContent: 'space-between',
    height: '100%',
  },
  drawerHorizontal: {
    width: '40%',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
  firstActions: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '1em',
    alignItems: 'center',
    height: '100%',
  },
  firstActionsHorizontal: {
    display: 'flex',
    height: '100%',
    '& > button': {
      marginRight: '1em',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'white',
  },
  divider: {
    width: '40%',
    marginTop: '1em',
    marginBottom: '1em',
  },
}))

type ActionMenuItemsProps = {
  onClose?: () => void
}
export const ActionMenuItems: FunctionComponent<ActionMenuItemsProps> = ({
  onClose,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const fetchData = useFetchMiscData(false)
  const [openSiteDetailsWarning, setOpenSiteDetailsWarning] = useState(false)
  const toggleOpenSiteDetailsWarning = () => {
    setOpenSiteDetailsWarning((open) => {
      return !open
    })
  }

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [errorDialogMessage, setErrorDialogMessage] = useState<
    string | undefined
  >(undefined)

  const readFile = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onabort = () =>
        setErrorDialogMessage('The file reading process has been aborted')
      reader.onerror = () => {
        setErrorDialogMessage('The file reading has failed')
        reject()
      }
      reader.onload = () => {
        const dataUrl = reader.result
        resolve(String(dataUrl))
      }
      reader.readAsDataURL(file)
    })
  }

  const setPicture = (picture: string) => {
    dispatch({
      type: String(SET_PICTURE_DATA),
      payload: {
        currentPhoto: {
          picture,
        },
      },
    })
    navigate('/dashboard/preview')
  }

  const onUploadSubmit = async (files: FileList | null) => {
    if (!files) {
      setErrorDialogMessage('The files you chose are corrupted')
      return
    }
    if (files.length === 0) {
      return
    }
    const photos: Array<string> = []
    for (let i = 0; i < files.length; i++) {
      photos.push(await readFile(files[i]))
    }
    if (photos.length === 1) {
      setPicture(photos[0])
    } else {
      dispatch({
        type: String(SET_PHOTOS),
        payload: {
          files: photos.map<SchenckFile>((file) => ({ file })),
        },
      })
      if (pathname === '/dashboard/files') {
        return
      }
      navigate('/dashboard/files')
    }
    onClose?.()
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onUploadSubmit(e.target?.files)
  }

  const uploadFile = () => {
    fileInputRef.current?.click()
  }

  const navigateTo = (to: string) => {
    navigate(`/dashboard/${to}`)
    onClose?.()
  }
  const dispatch = useDispatch()
  const logOut = () => {
    dispatch({
      type: String(LOG_OUT),
    })
  }
  const refreshData = () => {
    fetchData()
    onClose?.()
  }
  return (
    <div className={isMobile ? classes.drawer : classes.drawerHorizontal}>
      <div
        className={
          isMobile ? classes.firstActions : classes.firstActionsHorizontal
        }
      >
        <Button onClick={() => navigateTo('')}>
          <Home color='inherit' />
        </Button>
        {isMobile && <Divider color='white' className={classes.divider} />}

        <Tooltip title='Enter and save Site Detail'>
          <Button onClick={() => navigateTo('audit')}>
            <Assignment color='inherit' />
          </Button>
        </Tooltip>
        {isMobile && <Divider color='white' className={classes.divider} />}

        <Tooltip title='Take and upload images'>
          <Button
            onClick={() => {
              toggleOpenSiteDetailsWarning()
            }}
          >
            <CameraAlt color='inherit' />
          </Button>
        </Tooltip>

        {isMobile && <Divider color='white' className={classes.divider} />}

        <Tooltip title='Attach and upload images'>
          <Button onClick={uploadFile}>
            <AttachFile color='inherit' />
          </Button>
        </Tooltip>

        {isMobile && <Divider color='white' className={classes.divider} />}

        <Tooltip title='Re-fetch data'>
          <Button onClick={refreshData}>
            <Refresh color='inherit' />
          </Button>
        </Tooltip>

        <input
          multiple
          ref={fileInputRef}
          type='file'
          style={{ display: 'none' }}
          className='hidden'
          accept='.jpg, .jpeg, .png'
          onChange={handleChange}
        />
      </div>
      <div
        style={{
          marginBottom: isMobile ? 20 : 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Tooltip title='Logout of CaptureApp'>
          <Button style={{ transform: 'rotate(180deg)' }} onClick={logOut}>
            <ExitToApp color='inherit' />
          </Button>
        </Tooltip>
      </div>
      <WarningDialog
        open={!!errorDialogMessage}
        onClose={() => setErrorDialogMessage(undefined)}
        title={'Error'}
        description={errorDialogMessage as string}
        positiveButtonText='Retry'
        onPositiveButtonClicked={uploadFile}
        negativeButtonText={'Cancel'}
        onNegativeButtonClicked={() => setErrorDialogMessage(undefined)}
      />
      <SiteDetailWarning
        onNavigated={onClose}
        open={openSiteDetailsWarning}
        onClose={toggleOpenSiteDetailsWarning}
      />
    </div>
  )
}
