import { useState, FunctionComponent, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { WearAppState } from '../../../store/types'
import { WearAppDialog } from '../../WearAppDialog'
import { Button, Chip, Input, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useFetchMiscData } from '../../../hooks/useFetchMiscData'
import { difference } from 'lodash'

type FailureModesProps = {
  onValuesSelected: (values: Array<string>) => void
  selectedValues: Array<string>
  onClose: (values?: Array<string>) => void
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const FailureModes: FunctionComponent<FailureModesProps> = ({
  onValuesSelected,
  selectedValues,
  onClose,
}) => {
  const [values, setValues] = useState([...selectedValues])
  const [open, setOpen] = useState(true)
  const failureModes = useSelector(
    (store: WearAppState) => store.failureModes.failureModes
  )
  const options = useMemo(() => {
    const modes = failureModes?.map((mode) => mode) ?? []

    const diff = difference(values, modes)
    return modes.concat(diff)
  }, [failureModes, values])
  useFetchMiscData()
  const classes = useStyles()
  const handleClose = () => {
    setOpen(false)
    onValuesSelected(values)
    onClose(values)
  }

  const onCancel = () => {
    setOpen(false)
    onClose(undefined)
  }

  return (
    <WearAppDialog
      isFullHeight={false}
      open={open}
      title='Select Failure Mode Type'
      onClose={handleClose}
      actions={
        <>
          <Button onClick={onCancel}>
            <span style={{ color: 'purple', marginTop: 'auto' }}>Cancel</span>
          </Button>
          <Button onClick={handleClose} disabled={values.length === 0}>
            <span style={{ marginTop: 'auto' }}>OK</span>
          </Button>
        </>
      }
    >
      <Select
        multiple
        value={values}
        maxRows={1}
        onChange={(event) => {
          const selectedValues = event.target.value as Array<string>
          if (selectedValues.length > 2) {
            return
          }
          setValues(selectedValues)
        }}
        input={<Input />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {(selected as Array<string>).map((value) => (
              <Chip key={value} label={value} className={classes.chip} />
            ))}
          </div>
        )}
        style={{ width: '100%' }}
        MenuProps={{
          ...MenuProps,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </WearAppDialog>
  )
}
