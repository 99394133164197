import { createAction } from 'redux-actions'

const ns = 'miscs'

export const SET_PICTURE_DATA = createAction(`${ns}/setPictureData`)
export const SET_REVIEW_DATA = createAction(`${ns}/setReviewData`)
export const SET_LAST_SYNCED_TIME = createAction(`${ns}/setLastSyncedTime`)
export const TOGGLE_NEW_VERSION_AVAILABLE = createAction(
  `${ns}/toggleNewVersionAvailable`
)
export const SET_WENT_OFFLINE = createAction(`${ns}/setWentOffline`)

export const UPDATE_SELECTED_PHOTOS = createAction(`${ns}/updateSelectedPhotos`)
export const CLEAR_SELECTION = createAction(`${ns}/clearSelectedPhotos`)
