import { useSelector } from 'react-redux'
import { WearAppState } from '../store/types'
import moment from 'moment'

export const MAX_SYNC_HOURS = 12

export const useShouldFetchData = () => {
  const lastSyncDate = useSelector(
    (store: WearAppState) => store.misc?.lastSync
  )
  const failureModes = useSelector(
    (store: WearAppState) => store.failureModes.failureModes
  )
  const panelTypes = useSelector(
    (store: WearAppState) => store.panelTypes.panelTypes
  )
  const shouldForceRefresh =
    moment(moment()).diff(moment(lastSyncDate), 'hours') >= MAX_SYNC_HOURS
  return (
    shouldForceRefresh ||
    !failureModes.length ||
    !Object.values(panelTypes).length
  )
}
