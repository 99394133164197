import { useContext, useEffect } from 'react'
import { WearAppContext } from '../context/WearAppContext'

export const useSetShowBackButton = (show: boolean) => {
  const { setShowBackButton } = useContext(WearAppContext)

  useEffect(() => {
    setShowBackButton(show)
  }, [show, setShowBackButton])
}
