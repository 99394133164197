import { PanelType } from '../store/types'

type ReplacePanelDecisionProps = {
  panel: PanelType
  saftyDepth: number
  panelInstallDate: number
  nextShutDownDate: number
  wear95Percent: number
  inTopVertSection: boolean
  inBotVertSection: boolean
}

type Results = 'replace' | 'do_not_repalce' | 'can_not_determine'

export type ReplacePanelDecisionResults = {
  remainingLife: number
  daysToNextShutdown: number
  replacePanel: Results
  wearRate: number
}

export const replacePanelDecision = ({
  nextShutDownDate,
  panel,
  panelInstallDate,
  saftyDepth,
  wear95Percent,
  inTopVertSection,
  inBotVertSection,
}: ReplacePanelDecisionProps): ReplacePanelDecisionResults => {
  const Working_Depth_Lig = parseFloat(panel.Working_Depth_Lig)
  const date_today = new Date(Date.now())

  const d1 = Math.abs(nextShutDownDate - date_today.getTime())
  const d2 = Math.abs(date_today.getTime() - panelInstallDate)
  const days_to_next_shutdown = Math.ceil(d1 / (1000 * 60 * 60 * 24))
  const days_installed = Math.ceil(d2 / (1000 * 60 * 60 * 24))

  const wear_rate = wear95Percent / days_installed
  const remaining_life = Math.ceil(
    (Working_Depth_Lig - Number(saftyDepth) - wear95Percent) / wear_rate
  )

  let replace_panel = 'can_not_determine' as Results

  if (remaining_life < days_to_next_shutdown && !inTopVertSection) {
    replace_panel = 'replace'
  } else if (remaining_life > days_to_next_shutdown && !inBotVertSection) {
    replace_panel = 'do_not_repalce'
  }

  return {
    remainingLife: remaining_life,
    daysToNextShutdown: days_to_next_shutdown,
    replacePanel: replace_panel,
    wearRate: wear_rate,
  }
}
