import { all, call, put, takeLeading } from 'redux-saga/effects'
import {
  LOGIN_USER,
  AUTH_FAILURE,
  TOGGLE_IS_LOADING,
  LOG_OUT,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
} from './authActions'
import {
  login as loginAws,
  logout,
  forgotPassword as forgotPasswordAWS,
  changePassword as changePasswordAWS,
} from '../../services/cognito'

function* login({ payload: { username, password } }: any) {
  try {
    yield all([
      put({ type: TOGGLE_IS_LOADING }),
      put({ type: AUTH_FAILURE, payload: { error: undefined } }),
    ])
    yield call(loginAws, username, password)
    // // @ts-ignore
    // const attributes = user.attributes ?? user.challengeParam.userAttributes
    // yield put({
    //     type: LOGIN_USER_SUCCESS, payload: {
    //         user: {
    //             firstName: attributes.given_name,
    //             lastName: attributes.family_name,
    //             name: attributes.name,
    //             isReviewer: REVIEWERS.includes(username)
    //         }
    //     }
    // })
  } catch (e) {
    yield put({ type: AUTH_FAILURE, payload: { error: e } })
  } finally {
    yield put({ type: TOGGLE_IS_LOADING })
  }
}

function* resetPassword({ payload: { username } }: any) {
  try {
    yield all([
      put({ type: TOGGLE_IS_LOADING }),
      put({ type: AUTH_FAILURE, payload: { error: undefined } }),
    ])
    const response: unknown = yield call(forgotPasswordAWS, username)
    yield put({ type: RESET_PASSWORD_SUCCESS, payload: { data: response } })
  } catch (e) {
    yield put({ type: AUTH_FAILURE, payload: { error: e } })
  } finally {
    yield put({ type: TOGGLE_IS_LOADING })
  }
}

function* changePassword({ payload: { username, code, password } }: any) {
  try {
    yield all([
      put({ type: TOGGLE_IS_LOADING }),
      put({ type: AUTH_FAILURE, payload: { error: undefined } }),
    ])
    const response: unknown = yield call(
      changePasswordAWS,
      username,
      code,
      password
    )
    yield put({ type: CHANGE_PASSWORD_SUCCESS, payload: { data: response } })
  } catch (e) {
    yield put({ type: AUTH_FAILURE, payload: { error: e } })
  } finally {
    yield put({ type: TOGGLE_IS_LOADING })
  }
}

function* logOut() {
  yield call(logout)
}

export function* authSagas() {
  yield all([
    takeLeading(String(LOGIN_USER), login),
    takeLeading(String(LOG_OUT), logOut),
    takeLeading(String(RESET_PASSWORD), resetPassword),
    takeLeading(String(CHANGE_PASSWORD), changePassword),
  ])
}
