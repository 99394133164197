import { createContext } from 'react'
import { Library } from '../store/types'
export type SetState<S> = S | ((prevState: S) => S)
export type Dimension = {
  width: number
  height: number
}

export type WearAppContextState = {
  footerDimension?: Dimension
  appBarDimension?: Dimension
  currentDialog?: Dimension
  showBackButton?: boolean
  loginWithEmail?: boolean
  showDisclaimerModal?: boolean

  setFooterDimension: (setState: SetState<Dimension | undefined>) => void
  setAppBarDimension: (setState: SetState<Dimension | undefined>) => void
  setCurrentDialogDimension: (setState: SetState<Dimension | undefined>) => void
  setShowBackButton: (setState: SetState<boolean>) => void
  setLoginWithEmail: (setState: SetState<boolean>) => void
  uploadPhotos: (photos: Array<{ file: string; library: Library }>) => void
  setShowDisclaimerModal: (show: boolean) => void
}

const defaultContext: WearAppContextState = {
  footerDimension: {
    height: 0,
    width: 0,
  },
  appBarDimension: {
    height: 0,
    width: 0,
  },
  currentDialog: {
    height: 0,
    width: 0,
  },
  showBackButton: false,
  showDisclaimerModal: false,
  /* eslint-disable @typescript-eslint/no-empty-function */
  setFooterDimension: (_) => {},
  setAppBarDimension: (_) => {},
  setCurrentDialogDimension: (_) => {},
  setShowBackButton: (_) => {},
  setLoginWithEmail: (_) => {},
  uploadPhotos: (_) => {},
  setShowDisclaimerModal: (_) => {},
}

export const WearAppContext = createContext<WearAppContextState>(defaultContext)
