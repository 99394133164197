import { FunctionComponent } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

type WarningDialogProps = {
  open: boolean
  onClose: () => void
  title: string
  description: string
  positiveButtonText: string
  onPositiveButtonClicked?: () => void
  negativeButtonText?: string
  onNegativeButtonClicked?: () => void
}

export const WarningDialog: FunctionComponent<WarningDialogProps> = ({
  onClose,
  open,
  title,
  description,
  positiveButtonText,
  negativeButtonText,
  onPositiveButtonClicked,
  onNegativeButtonClicked,
}) => {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          variant='caption'
          id='alert-dialog-slide-description'
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: 'red' }}
          onClick={onPositiveButtonClicked ?? onClose}
        >
          {positiveButtonText}
        </Button>
        {negativeButtonText && (
          <Button style={{ color: 'grey' }} onClick={onNegativeButtonClicked}>
            {negativeButtonText ?? 'Ignore'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
