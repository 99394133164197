import {
  AuditData,
  Library,
  LibraryState,
  Results,
  SiteDetail,
  SiteDetailWithId,
} from '../types'
import { Action, handleActions } from 'redux-actions'
import {
  CLEAR_LIBRARY,
  SAVE_FETCHED_AUDIT_DATA,
  SAVE_SITE_DETAIL,
  SAVE_SITE_DETAIL_WITH_ID,
  SAVE_TO_LIBRARY,
  TOGGLE_IS_WORKING,
  UPDATE_LIBRARY,
} from './libraryActions'

const initialState: LibraryState = {
  isWorking: false,
  libraries: [],
  results: [],
}

type ToggleIsWorkingPayload = {
  isWorking: boolean
}

type Payload =
  | { library: Library; results?: Results }
  | ToggleIsWorkingPayload
  | SiteDetail
  | SiteDetailWithId
  | AuditData

export const libraryReducer = handleActions<LibraryState, Payload>(
  {
    [String(SAVE_TO_LIBRARY)]: (
      state: LibraryState,
      { payload }: Action<Payload>
    ) => ({
      ...state,
      libraries: [
        (payload as { library: Library; results?: Results }).library,
        ...state.libraries,
      ],
      results: [
        (payload as { library: Library; results: Results }).results,
        ...state.results,
      ],
    }),
    [String(UPDATE_LIBRARY)]: (
      state: LibraryState,
      { payload }: Action<Payload>
    ) => {
      const libraryPayload = (payload as {
        library: Library
        results?: Results
      }).library
      const libraries = [...state.libraries]
      const libraryIndex = libraries.findIndex(
        ({ id }) => id === libraryPayload.id
      )
      libraries.splice(libraryIndex, 1)
      libraries.push(libraryPayload)
      return {
        ...state,
        libraries: [libraryPayload, ...libraries],
      }
    },
    [String(TOGGLE_IS_WORKING)]: (state: LibraryState) => ({
      ...state,
      isWorking: !state.isWorking,
    }),
    [String(SAVE_SITE_DETAIL)]: (
      state: LibraryState,
      { payload }: Action<Payload>
    ) => ({
      ...state,
      siteDetail: payload as SiteDetail,
    }),
    [String(CLEAR_LIBRARY)]: (state: LibraryState) => ({
      ...state,
      libraries: [],
    }),
    [String(SAVE_SITE_DETAIL_WITH_ID)]: (
      state: LibraryState,
      { payload }: Action<Payload>
    ) => ({
      ...state,
      siteDetailWithId: payload as SiteDetailWithId,
    }),
    [String(SAVE_FETCHED_AUDIT_DATA)]: (
      state: LibraryState,
      { payload }: Action<Payload>
    ) => ({
      ...state,
      auditData: payload as AuditData,
    }),
  },
  initialState
)
