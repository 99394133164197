import { Button, Dialog, DialogContent, Grid } from '@material-ui/core'
import React, { FunctionComponent, useCallback, useState } from 'react'
import { PanelRemainingLife } from './PanelRemainingLife'
import { ProcessEfficiency } from './ProcessEfficiency'

type WearAnalysisInfoDialogProps = {
  onCancelPressed: () => void
  onSkipPressed: () => void
  onSubmitPressed: (
    value: Record<
      'replace' | 'process',
      Record<string, undefined | string | number>
    >
  ) => void
}

export const WearAnalysisInfoDialog: FunctionComponent<WearAnalysisInfoDialogProps> = ({
  onCancelPressed,
  onSkipPressed,
  onSubmitPressed,
}) => {
  const [value, setValue] = useState<
    Record<'replace' | 'process', Record<string, undefined | string | number>>
  >({ replace: {}, process: {} })

  const onDataChanged = useCallback((type: 'replace' | 'process') => {
    return (key: string, value?: string | number) => {
      setValue((current) => {
        return {
          ...current,
          [type]: {
            ...current[type],
            [key]: value,
          },
        }
      })
    }
  }, [])

  return (
    <Dialog open>
      <DialogContent>
        <Grid container direction='column' spacing={2}>
          <PanelRemainingLife onDataChanged={onDataChanged('replace')} />
          <ProcessEfficiency onDataChanged={onDataChanged('process')} />
        </Grid>
      </DialogContent>
      <Grid container justifyContent='center' alignItems='center'>
        <Button
          size='medium'
          style={{ color: '#F2F2F2' }}
          type='submit'
          onClick={onCancelPressed}
        >
          Cancel
        </Button>
        <Button onClick={onSkipPressed}>Skip</Button>
        <Button
          style={{ color: '#007073' }}
          onClick={() => onSubmitPressed(value)}
        >
          Submit
        </Button>
      </Grid>
    </Dialog>
  )
}
