import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { CircularProgress, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RESET_PASSWORD } from '../../store/auth/authActions'
import { WearAppState } from '../../store/types'

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const useStyles = makeStyles({
  textField: {
    width: '100%',
    marginTop: '1em',
  },
  resetPassword: {
    width: '100%',
    fontFamily: 'arial',
    marginTop: '1em',
    fontSize: 14,
    color: '#F2F2F2',
    backgroundColor: '#006068',
  },
  login: {
    textDecoration: 'none',
    fontSize: 12,
    marginTop: '1em',
  },
})

export const ResetPassword: FunctionComponent = () => {
  const styles = useStyles()

  const [email, setEmail] = useState<string>('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isResettingPassword = useSelector(
    (state: WearAppState) => state.auth?.isResettingPassword
  )
  const isLoading = useSelector((state: WearAppState) => state.auth?.isLoading)

  const resetPassword = () => {
    dispatch({
      type: String(RESET_PASSWORD),
      payload: {
        username: email,
      },
    })
  }

  useEffect(() => {
    if (isResettingPassword) {
      navigate('/auth/change-password', { state: { email } })
    }
  }, [isResettingPassword, navigate, email])

  const isValid = useMemo(() => {
    return EMAIL_REGEX.test(email)
  }, [email])

  return (
    <>
      <TextField
        onChange={(event) => setEmail(event.target.value)}
        className={styles.textField}
        label='Email'
        type='email'
      />
      {!isLoading && (
        <>
          <Button
            onClick={resetPassword}
            disabled={!isValid}
            className={styles.resetPassword}
            size='medium'
          >
            Rest password
          </Button>
          <Button
            variant='outlined'
            className={styles.login}
            onClick={() => navigate('/auth/login')}
          >
            Remember password? Login!
          </Button>
        </>
      )}
      {isLoading && (
        <CircularProgress
          style={{ marginTop: '1em' }}
          color='inherit'
          size={20}
        />
      )}
    </>
  )
}
