import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { CircularProgress, Snackbar, TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {
  CLEAR_RESET_PASSWORD,
  CLEAR_RESET_PASSWORD_SUCCESS,
  LOGIN_USER,
} from '../../store/auth/authActions'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { WearAppState } from '../../store/types'
import { makeStyles } from '@material-ui/core/styles'
import { WearAppContext } from '../../context/WearAppContext'

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const useStyles = makeStyles({
  textField: {
    width: '100%',
    marginTop: '1em',
  },
  resetPassword: {
    width: '100%',
    fontFamily: 'arial',
    marginTop: '1em',
    fontSize: 14,
    color: '#F2F2F2',
    backgroundColor: '#006068',
  },
  login: {
    textDecoration: 'none',
    fontSize: 12,
    marginTop: '1em',
    width: '100%',
  },
})

type LoginWithEmailAndPasswordProps = {
  toggleLoginWithEmailPassword: () => void
}

export const LoginWithEmailAndPassword: FunctionComponent<LoginWithEmailAndPasswordProps> = ({
  toggleLoginWithEmailPassword,
}) => {
  const styles = useStyles()

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isLoggingIn = useSelector(
    (state: WearAppState) => state.auth?.isLoading
  )
  const authFailure = useSelector((state: WearAppState) => state.auth?.error)
  const isValid = useMemo(() => {
    return EMAIL_REGEX.test(email) && password.trim().length > 6
  }, [email, password])
  const { setShowDisclaimerModal } = useContext(WearAppContext)
  const resetPasswordSuccess = useSelector(
    (state: WearAppState) => state.auth?.resetPasswordSuccess
  )

  useEffect(() => {
    dispatch({
      type: String(CLEAR_RESET_PASSWORD),
    })
  }, [])

  const login = () => {
    setShowDisclaimerModal(true)
    dispatch({
      type: String(LOGIN_USER),
      payload: {
        username: email,
        password,
      },
    })
  }

  return (
    <form>
      <div>
        <TextField
          error={!!authFailure}
          onChange={(event) => setEmail(event.target.value)}
          className={styles.textField}
          label='Email'
          type='email'
        />
        <TextField
          error={!!authFailure}
          onChange={(event) => setPassword(event.target.value)}
          className={styles.textField}
          label='Password'
          type='password'
        />
        {!isLoggingIn && (
          <Button
            onClick={login}
            disabled={!isValid}
            className={styles.resetPassword}
            size='medium'
          >
            Login
          </Button>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {isLoggingIn && (
            <CircularProgress
              style={{ marginTop: '1em' }}
              color='inherit'
              size={20}
            />
          )}
          {!isLoggingIn && (
            <Button
              variant='outlined'
              className={styles.login}
              onClick={() => navigate('/auth/reset-password')}
            >
              Forgot Password?
            </Button>
          )}
          <Button
            onClick={toggleLoginWithEmailPassword}
            variant='outlined'
            className={styles.login}
          >
            Cancel
          </Button>
        </div>
        <Snackbar
          open={resetPasswordSuccess}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          style={{ bottom: 25 }}
          action={
            <>
              <Button
                size='small'
                color='secondary'
                onClick={() => {
                  dispatch({
                    type: String(CLEAR_RESET_PASSWORD_SUCCESS),
                  })
                }}
              >
                Ok
              </Button>
            </>
          }
          message='You have successfully change your password!'
        />
      </div>
    </form>
  )
}
