import { PanelType, PanelTypeState } from '../types'
import { handleActions, Action } from 'redux-actions'
import {
  FETCH_ALL_PANEL_TYPES_SUCCESS,
  TOGGLE_IS_FETCHING,
} from './panelTypeActions'
import { LOG_OUT_SUCCESS } from '../auth/authActions'

const initialState: PanelTypeState = {
  panelTypes: {},
  isFetching: false,
}

export type PanelTypePayload = {
  panelTypes: PanelType
  error?: Error
}

export const panelTypeReducer = handleActions<PanelTypeState, PanelTypePayload>(
  {
    [String(FETCH_ALL_PANEL_TYPES_SUCCESS)]: (
      panelTypeState: PanelTypeState,
      { payload }: Action<PanelTypePayload>
    ) => ({
      ...panelTypeState,
      isSorted: true,
      panelTypes: { ...payload.panelTypes },
    }),
    [String(TOGGLE_IS_FETCHING)]: (panelTypeState: PanelTypeState) => ({
      ...panelTypeState,
      isFetching: !panelTypeState.isFetching,
    }),
    [String(LOG_OUT_SUCCESS)]: () => {
      return {
        panelTypes: {},
        isFetching: false,
      }
    },
  },
  initialState
)
